import * as yup from 'yup'

import type { RoomInputType } from 'types/rooms'

import { RoomFields } from 'components/Rooms/utils'
import i18next from 'i18n'

export const RoomInputSchema: yup.Schema<RoomInputType> = yup.object().shape({
  [RoomFields.NAME]: yup.string().required(i18next.t('Room name is required')),
  [RoomFields.ORGANIZATION_ID]: yup
    .string()
    .required(i18next.t('Organization id is required')),
  [RoomFields.CAPACITY]: yup
    .number()
    .min(1, i18next.t('Total capacity of the room should be at least one'))
    .typeError(i18next.t('Total capacity of the room is required'))
    .required(i18next.t('Total capacity of the room is required')),
  [RoomFields.PROCEDURES]: yup
    .array()
    .of(
      yup.object().shape({
        [RoomFields.PROCEDURE_ID]: yup
          .string()
          .required(i18next.t('Procedure is required')),
        [RoomFields.CAPACITY]: yup
          .number()
          .typeError(i18next.t('Procedure capacity is required'))
          .required(i18next.t('Procedure capacity is required'))
          .min(1, i18next.t('Procedure capacity should be at least one')),
      }),
    )
    .required(),
})
