export const parseParams = <TParams extends object>(params: TParams) => {
  const keys = Object.keys(params)
  let options = ''

  keys.forEach(key => {
    const paramValue = params[key as keyof TParams]

    if (Array.isArray(paramValue)) {
      paramValue.forEach((element: string) => {
        options += `${key}=${element}&`
      })
    } else if (paramValue !== undefined && paramValue !== null) {
      options += `${key}=${paramValue}&`
    }
  })

  return options ? options.slice(0, -1) : options
}
