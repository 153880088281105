import type {
  ProviderScheduleInputType,
  ProviderScheduleType,
} from 'types/providers'

import { DATE_FORMAT, TIME_FORMAT, format } from 'utils/time'

export enum ShiftsFields {
  DATE = 'date',
  PROVIDER_ID = 'providerId',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export enum ShiftScheduleActions {
  EDIT = 'edit',
  DELETE = 'delete',
  NEW = 'new',
}

export const mapForm2Api = ({
  providerId,
  date,
  startTime,
  endTime,
}: ProviderScheduleInputType) => {
  const newDate = format(date || '', DATE_FORMAT)
  return {
    [ShiftsFields.PROVIDER_ID]: providerId,
    [ShiftsFields.START_TIME]: format(`${newDate} ${startTime}`),
    [ShiftsFields.END_TIME]: format(`${newDate} ${endTime}`),
  }
}

export const mapApi2Form = (input: ProviderScheduleType) => ({
  [ShiftsFields.PROVIDER_ID]: input.providerId,
  [ShiftsFields.DATE]: format(input.startTime, DATE_FORMAT),
  [ShiftsFields.START_TIME]: format(input.startTime, TIME_FORMAT),
  [ShiftsFields.END_TIME]: format(input.endTime, TIME_FORMAT),
})
