import { alert, Modal } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type {
  ProviderScheduleInputType,
  ProviderScheduleType,
} from 'types/providers'

import ProviderShiftsForm from './ProviderShiftsForm'

import api from 'api/api'
import Card from 'components/Card'
import { DialogFormWrapper } from 'components/Form'
import { mapApi2Form, mapForm2Api } from 'components/Providers/utils'
import { useFormManager } from 'hooks/useFormManager'
import { ProviderShiftInputSchema } from 'schemas/provider'

type EditProviderShiftProps = {
  isOpen: boolean
  onClose: (shift?: ProviderScheduleType) => void
  shift?: ProviderScheduleType
  isLoading: boolean
  refreshData: () => void
}

const EditProviderShift = ({
  isOpen,
  onClose,
  shift,
  isLoading,
  refreshData,
}: EditProviderShiftProps) => {
  const { t } = useTranslation()

  const form = useFormManager<ProviderScheduleInputType>({
    defaultValues: mapApi2Form(shift || ({} as ProviderScheduleType)),
    onCancel: () => {
      form.form.reset()
      onClose()
    },
    enableReinitialize: true,
    schema: ProviderShiftInputSchema,
    onSubmit: async (input: ProviderScheduleInputType) => {
      if (!shift?.id) return
      await api.updateProviderShift({ id: shift.id, input: mapForm2Api(input) })
      refreshData()
      alert.success(`${t('Success')}!`, t('Shift edited successfully.'), {
        position: 'bottom-right',
      })
      onClose()
      form.form.reset()
    },
  })

  return (
    <Modal
      open={isOpen && !!shift?.id}
      title={t('Edit Shift')}
      extraClasses={DialogFormWrapper()}
      closeFn={onClose}
    >
      <Card extraClasses="wrapper" isLoading={isLoading}>
        <ProviderShiftsForm {...form} />
      </Card>
    </Modal>
  )
}

export default EditProviderShift
