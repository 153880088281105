import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { RoomType } from 'types/rooms'

import { RoomsPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'

export const roomsDefaultSortees: ColumnSort[] = [{ id: 'name', desc: false }]

const columnHelper = createColumnHelper<RoomType>()
export const roomsColumns = [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<RoomType>(),
    header: 'Name',
    meta: { width: '100%' },
    cell: ({ row: { original } }) => (
      <Link
        to={`${RoomsPath.ROOMS}/${original.id}`}
        style={{ color: 'var(--colors-primary)' }}
      >
        {original.name}
      </Link>
    ),
  }),
]
