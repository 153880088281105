import type { ProviderType } from 'types/providers'

import { getName, splitCamelCase } from 'utils/text'

export enum ApptFields {
  TREATMENT_PROCEDURE_ID = 'treatmentProcedureId',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  ROOM_ID = 'roomId',
  PROVIDER_IDS = 'providerIds',
  DURATION_BEFORE = 'durationBefore',
}

export const mapProvider = (provider: ProviderType) => ({
  value: provider.id,
  label: getName(provider),
})

export const formatHeader = (label: string, count?: number) => {
  return `${splitCamelCase(label)} ${count ? `(${count} required)` : ''}`
}
