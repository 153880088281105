import { Pencil1Icon } from '@radix-ui/react-icons'
import { Button, Flex, Text, PopOver, Pill } from '@weareredlight/design-system'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import type { AppointmentType } from 'types/appointments'
import type { ProviderType } from 'types/providers'

import { AppointmentDetailsWrapper } from '../Treatments/treatments.styles'

import EntityDetail from 'components/EntityDetail'
import { ProceduresFields } from 'components/Procedures/utils'
import { GenericPath, RootPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { getName } from 'utils/text'
import { addDuration, APPOINTMENT_FORMAT, format } from 'utils/time'

type AppointmentDetailsType = {
  appointment: AppointmentType
  position?: 'top' | 'bottom' | 'left' | 'right'
  isCancelledTreatment?: boolean
}

const AppointmentDetails = ({
  appointment,
  position,
  isCancelledTreatment = false,
}: AppointmentDetailsType) => {
  const navigate = useNavigate()
  const { treatmentProcedureId } = useParams<{ treatmentProcedureId: string }>()
  const { id, procedure, treatment } = appointment?.treatmentProcedure || {}
  const { isAdmin } = useCurrentUser()

  const hasEdit = useMemo(() => {
    return (
      !isCancelledTreatment &&
      isAdmin &&
      (!treatmentProcedureId || treatmentProcedureId !== id)
    )
  }, [id, isAdmin, isCancelledTreatment, treatmentProcedureId])

  const therapyAt = useMemo(() => {
    if (!appointment.startTime || !procedure?.prePreparation) return null
    return addDuration(
      dayjs(appointment.startTime).toDate(),
      procedure.prePreparation,
    )
  }, [appointment.startTime, procedure.prePreparation])

  return (
    <PopOver side={position} align="start">
      <AppointmentDetailsWrapper>
        {hasEdit && (
          <Button
            variant="textOnly"
            onClick={() =>
              navigate(
                `${RootPath.SCHEDULE}/${treatment.id}/${id}/${GenericPath.EDIT}`,
              )
            }
            iconComponent={() => <Pencil1Icon />}
          />
        )}
        <Flex
          direction="column"
          align="start"
          gap="lg"
          className="details-inner-container"
        >
          <Flex direction="column" align="start" className="details-title">
            <Text variant="h5" color="neutral800">
              {getName(treatment, 'patient')}
            </Text>
            <Text variant="textBlock" color="neutral">
              {String(procedure.name)}
            </Text>
          </Flex>
          <EntityDetail label={t('Room')}>
            <Text variant="textBlock" color="neutral800">
              {appointment.room.name}
            </Text>
          </EntityDetail>
          <EntityDetail label={t('Providers')}>
            <ul>
              {appointment.providers.map((provider: ProviderType) => (
                <li key={provider.id} style={{ listStyle: 'none' }}>
                  <Text variant="textBlock" color="neutral800">
                    - {getName(provider)}
                  </Text>
                </li>
              ))}
            </ul>
          </EntityDetail>
          <EntityDetail label={t('Cell Bank')}>
            <Pill
              variant={
                procedure[ProceduresFields.NUMBER_OF_CELLS]
                  ? 'success'
                  : 'error'
              }
            >
              {procedure[ProceduresFields.NUMBER_OF_CELLS] ? t('Yes') : t('No')}
            </Pill>
          </EntityDetail>
          {procedure[ProceduresFields.NUMBER_OF_CELLS] != null &&
            procedure[ProceduresFields.NUMBER_OF_CELLS] > 0 && (
              <EntityDetail label={t('Number of Stem Cells')}>
                <Text variant="textBlock" color="neutral800">
                  {procedure[ProceduresFields.NUMBER_OF_CELLS]}
                </Text>
              </EntityDetail>
            )}
          {therapyAt && (
            <EntityDetail label={t('Therapy At')}>
              <Text variant="textBlock" color="neutral800">
                {format(therapyAt, APPOINTMENT_FORMAT)}
              </Text>
            </EntityDetail>
          )}
        </Flex>
      </AppointmentDetailsWrapper>
    </PopOver>
  )
}

export default AppointmentDetails
