import { alert } from '@weareredlight/design-system'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { TreatmentType, TreatmentInputType } from 'types/treatments'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import TreatmentForm from 'components/Treatments/TreatmentForm'
import { mapApi2Form } from 'components/Treatments/utils'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RootPath, TreatmentsPath } from 'router/enums'
import { PatientTreatmentInputSchema } from 'schemas/patient'

const TreatmentEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id, treatmentId } = useParams<{ id: string; treatmentId: string }>()

  const {
    data: treatment,
    doRequest: getTreatment,
    isLoading,
  } = useRequest<TreatmentType, { id: string }>(api.getTreatment)
  const goBackToUrl = React.useMemo(
    () =>
      `${RootPath.PATIENTS}/${id}/${TreatmentsPath.TREATMENTS}/${treatmentId}`,
    [id, treatmentId],
  )

  const form = useFormManager<TreatmentInputType>({
    defaultValues: treatment ? mapApi2Form(treatment) : {},
    enableReinitialize: true,
    schema: PatientTreatmentInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async input => {
      if (treatmentId) {
        await api.updateTreatment({ id: treatmentId, input })
        navigate(goBackToUrl)
        alert.success(
          `${t('Success')}!`,
          t('Treatment details edited successfully.'),
          {
            position: 'bottom-right',
          },
        )
      }
    },
  })

  useEffect(() => {
    if (treatmentId) getTreatment({ id: treatmentId })
  }, [getTreatment, treatmentId])

  return (
    <PageTemplate
      title={t('Edit Treatment')}
      goBackTo={goBackToUrl}
      numberOfElements={1}
      fullWidth={false}
    >
      <TreatmentForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default TreatmentEdit
