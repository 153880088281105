import { Route, Routes } from 'react-router-dom'

import NewPatient from './NewPatient'
import PatientEdit from './PatientEdit'
import PatientsList from './PatientsList'
import PatientView from './PatientView'

import Treatments from 'pages/Treatments'
import { GenericPath, TreatmentsPath } from 'router/enums'

const Patients = () => (
  <Routes>
    <Route index element={<PatientsList />} />
    <Route path={GenericPath.NEW} element={<NewPatient />} />
    <Route path={GenericPath.ENTITY} element={<PatientView />} />
    <Route
      path={`${GenericPath.ENTITY}/${GenericPath.EDIT}`}
      element={<PatientEdit />}
    />
    <Route
      path={`${GenericPath.ENTITY}/${TreatmentsPath.TREATMENTS}/*`}
      element={<Treatments />}
    />
  </Routes>
)

export default Patients
