import { alert, Dialog } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProviderScheduleType } from 'types/providers'

import api from 'api/api'

type DeleteProviderShiftProps = {
  providerScheduleId: string
  isOpen: boolean
  onClose: (shift?: ProviderScheduleType) => void
  refreshData: () => void
}

const DeleteProviderShift = ({
  providerScheduleId,
  isOpen,
  onClose,
  refreshData,
}: DeleteProviderShiftProps) => {
  const { t } = useTranslation()

  const handleDeleteShift = async () => {
    if (!providerScheduleId) return
    await api.deleteProviderShift(providerScheduleId)
    refreshData()
    alert.success(`${t('Success')}!`, t('Shift has been removed.'), {
      position: 'bottom-right',
    })
  }

  return (
    <Dialog
      open={isOpen && !!providerScheduleId}
      variant="danger"
      onConfirm={handleDeleteShift}
      closeFn={onClose}
      title={t('Confirm the shift deletion')}
      description={t(
        'This will delete the shift permanently from the provider.',
      )}
      confirmButtonText={t('Delete')}
      cancelButtonText={t('Cancel')}
    />
  )
}

export default DeleteProviderShift
