import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useMemo } from 'react'

dayjs.extend(weekOfYear)
dayjs.extend(dayOfYear)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)
dayjs.extend(utc)

const useDateParams = (date: string) => {
  const dateParams = useMemo(() => {
    const currentDate = new Date(date)
    const currentDay = dayjs(currentDate).dayOfYear()
    const currentWeek = dayjs(currentDate).week()
    const currentYear = dayjs(currentDate).year()
    const daysInYear = dayjs(currentDate).isLeapYear() ? 366 : 365
    const weeksInYear = dayjs(currentDate).isoWeeksInYear()
    const isCurrentWeek = dayjs(currentDate).isSame(new Date(), 'week')
    const firstDayOfWeek = dayjs(currentDate).startOf('w').format('MM/DD')
    const lastDayOfWeek = dayjs(currentDate).endOf('w').format('MM/DD')

    return {
      currentDay,
      currentWeek,
      currentYear,
      daysInYear,
      weeksInYear,
      isCurrentWeek,
      firstDayOfWeek,
      lastDayOfWeek,
    }
  }, [date])

  return dateParams
}

export default useDateParams
