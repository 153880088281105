import { Flex, Label, Radio, Text } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'

import type { OptionType } from 'types/types'

export type ControlledRadioButtonsType = {
  name: string
  label?: string
  options: OptionType[]
  noOptionsMessage?: string
  error?: string
}

export const ControlledRadioButtons = ({
  name,
  label,
  options,
  noOptionsMessage,
  error,
}: ControlledRadioButtonsType) => {
  const { control } = useFormContext()

  if (!options?.length && noOptionsMessage) {
    return (
      <Text variant="microCopy" color="neutral">
        {noOptionsMessage}
      </Text>
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Flex direction="column" align="start" gap="xxsm">
          {label && <Label id={name} description={label} />}
          <Flex justify="start" gap="xsm" wrap>
            {options?.map(item => (
              <Radio
                name={name}
                key={item.value}
                id={item.value}
                value={item.value}
                label={item.label}
                checked={value === item.value}
                onChange={e => onChange(e.target.value)}
              />
            ))}
          </Flex>
          {error && (
            <Text color="danger" variant="microCopy">
              {String(error)}
            </Text>
          )}
        </Flex>
      )}
    />
  )
}
