import { colors } from './colors'
import { media } from './media'
import { shadows } from './shadows'
import { sizes } from './sizes'

export const bioXcelleratorTheme = {
  colors,
  sizes,
  shadows,
  media,
  font: 'ProximaNova'
}
