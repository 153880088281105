import { Input, Flex, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProviderInputType } from 'types/providers'
import type { FormType } from 'types/types'

import Card from 'components/Card'
import { ControlledMultiSelect, ControlledSelect, Form } from 'components/Form'
import { Certification, Specialities } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { certificationLabels, specialitiesLabels } from 'utils/labels'

export enum ProviderFields {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  CERTIFICATION = 'certification',
  SPECIALITIES = 'specialities',
  ORGANIZATION_ID = 'organizationId',
}

const ProvidersForm = ({
  form,
  isLoading,
  ...props
}: FormType<ProviderInputType>) => {
  const { t } = useTranslation()

  const certificationValue = form.watch(ProviderFields.CERTIFICATION)

  return (
    <Card isLoading={isLoading}>
      <Form form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Text variant="h2">{t('Provider Details')}</Text>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(ProviderFields.FIRSTNAME)}
              label={t('First Name')}
              placeholder={t('Enter first name')}
              state={
                form.formState.errors[ProviderFields.FIRSTNAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[ProviderFields.FIRSTNAME]?.message
              }
            />
            <Input
              {...form.register(ProviderFields.LASTNAME)}
              label={t('Last Name')}
              placeholder={t('Enter last name')}
              state={
                form.formState.errors[ProviderFields.LASTNAME]
                  ? 'error'
                  : 'null'
              }
              errorMsg={form.formState.errors[ProviderFields.LASTNAME]?.message}
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <ControlledSelect
              name={ProviderFields.CERTIFICATION}
              options={enum2SelectOptions(Certification, certificationLabels)}
              label={t('Certification')}
              placeholder={t('Select certification')}
              state={
                form.formState.errors[ProviderFields.CERTIFICATION]
                  ? 'error'
                  : 'null'
              }
              fullWidth
            />
            {certificationValue === Certification.DOCTOR && (
              <ControlledMultiSelect
                name={ProviderFields.SPECIALITIES}
                options={enum2SelectOptions(Specialities, specialitiesLabels)}
                label={t('Specialities')}
                placeholder={t('Select specialities')}
              />
            )}
          </Flex>
        </Flex>
      </Form>
    </Card>
  )
}

export default ProvidersForm
