import { styled } from '@weareredlight/design-system'
import { StyledWrapper } from '@weareredlight/design-system/dist/esm/components/Input/styles'

export const StyledTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '$sm',
  borderRadius: '$sm',
  overflow: 'hidden',
})

export const StyledTableHead = styled('thead', {
  '& tr': {
    '& th': {
      background: '$neutral100',
      padding: '$xxxsm',
      textAlign: 'left',
      verticalAlign: 'top',
      include: 'subHeading',
      color: '$neutral800',
      cursor: 'default',

      '&:first-child': { borderTopLeftRadius: '$sm' },
      '&:last-child': { borderTopRightRadius: '$sm' },

      '&:hover': {
        background: '$primary100',
      },
      '&:active, &:focus': {
        background: '$primary100',
        outline: '1px solid $primary300',
        outlineOffset: '-1px',
      },

      '&.disabled': {
        pointerEvents: 'none',
        '& > div': {
          pointerEvents: 'all',
        },
      },

      '& > button': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '$xxsm',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
      },

      [`& ${StyledWrapper}`]: {
        width: '100%',
        minWidth: 'unset !important',
        input: {
          minWidth: 'unset !important',
        },
      },
    },
  },
})

export const StyledTableBody = styled('tbody', {
  '& tr': {
    borderBottom: '1px solid $neutral200',

    '&.clickable-row': {
      cursor: 'pointer',
    },

    '& td': {
      padding: '$xxsm',
      textAlign: 'left',
      verticalAlign: 'center',
      include: 'textBlock',
      color: '$neutral700',

      li: {
        listStylePosition: 'inside',
      },
    },

    '&:hover': {
      backgroundColor: '$primary100',
    },
  },
})

export const StyledEmptyRow = styled('th', {
  width: '100%',
  textAlign: 'center',
  background: '$white',
  padding: '$xxxlg 0',
})
