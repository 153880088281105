import { alert } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { OrganizationInputType } from 'types/organizations'

import api from 'api/api'
import OrganizationForm, {
  OrgFields,
} from 'components/Organizations/OrganizationForm'
import PageTemplate from 'components/PageTemplate'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { OrganizationInputSchema } from 'schemas/organization'
import { FrequencyDigest } from 'utils/enums'

const NewOrganization = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const form = useFormManager<OrganizationInputType>({
    defaultValues: {
      [OrgFields.NAME]: '',
      [OrgFields.PREFIX]: {
        [OrgFields.FREQUENCY]: FrequencyDigest.NEVER,
        [OrgFields.SENDING_TIME]: undefined,
        [OrgFields.WEEK_DAYS]: [],
        [OrgFields.EMAILS]: [],
      },
    },
    enableReinitialize: true,
    schema: OrganizationInputSchema,
    onCancel: () => navigate(RootPath.ORGANIZATIONS),
    onSubmit: async data => {
      await api.addOrganization(data)
      navigate(RootPath.ORGANIZATIONS)
      alert.success(
        `${t('Success')}!`,
        `${data.name} ${t('was created successfully')}.`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Organization')}
      goBackTo={RootPath.ORGANIZATIONS}
      numberOfElements={1}
      fullWidth={false}
    >
      <OrganizationForm {...form} />
    </PageTemplate>
  )
}

export default NewOrganization
