import { Route, Routes } from 'react-router-dom'

import NewProcedure from './NewProcedure'
import ProcedureEdit from './ProcedureEdit'
import ProceduresList from './ProceduresList'
import ProcedureView from './ProcedureView'

import { GenericPath } from 'router/enums'

const Patients = () => (
  <Routes>
    <Route index element={<ProceduresList />} />
    <Route path={GenericPath.NEW} element={<NewProcedure />} />
    <Route path={GenericPath.ENTITY} element={<ProcedureView />} />
    <Route path={`${GenericPath.ENTITY}/${GenericPath.EDIT}`} element={<ProcedureEdit />} />
  </Routes>
)

export default Patients

