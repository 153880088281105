import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import { Input, Flex, Text, Button } from '@weareredlight/design-system'
import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ProcedureType } from 'types/procedures'
import type { RoomInputType } from 'types/rooms'
import type { FormType, PaginatedRequest } from 'types/types'

import api from 'api/api'
import Card from 'components/Card'
import { ControlledSelectAutocomplete, Form } from 'components/Form'
import { RoomFields } from 'components/Rooms/utils'
import { useRequest } from 'hooks/useRequest'

const RoomForm = ({ form, isLoading, ...props }: FormType<RoomInputType>) => {
  const { t } = useTranslation()

  const {
    fields: procedures,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: RoomFields.PROCEDURES,
  })

  const { data: proceduresData, doRequest: getProcedures } = useRequest<
    PaginatedRequest<ProcedureType>
  >(api.getProcedures)

  const proceduresOptions = useMemo(
    () =>
      proceduresData?.data?.map(procedure => ({
        value: procedure.id,
        label: procedure.name,
      })) || [],
    [proceduresData?.data],
  )

  const removeButtonPlacement = useCallback(
    (index: number) => {
      const hasError =
        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
          RoomFields.PROCEDURE_ID
        ] ||
        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
          RoomFields.CAPACITY
        ]
      return hasError ? 'center' : 'end'
    },
    [form.formState.errors],
  )

  useEffect(() => {
    getProcedures()
  }, [getProcedures])

  return (
    <Card isLoading={isLoading}>
      <Form form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Text variant="h2">{t('Room Details')}</Text>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(RoomFields.NAME)}
              label={t('Name')}
              placeholder={t('Type the room\'s name')}
              state={form.formState.errors[RoomFields.NAME] ? 'error' : 'null'}
              errorMsg={form.formState.errors[RoomFields.NAME]?.message}
              fullWidth
            />
            <Input
              {...form.register(RoomFields.CAPACITY)}
              type="number"
              label={t('Total Capacity')}
              placeholder={t('Type the room\'s total capacity')}
              state={
                form.formState.errors[RoomFields.CAPACITY] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[RoomFields.CAPACITY]?.message}
              fullWidth
            />
          </Flex>
          <Flex
            direction="column"
            align="start"
            gap="sm"
            css={{ width: '100%' }}
          >
            <Text variant="h4">{t('Procedures')}</Text>
            {procedures.map((item, index) => {
              return (
                <Flex
                  key={item.id}
                  align="start"
                  gap="xxsm"
                  css={{ width: '100%' }}
                >
                  <Flex
                    align="start"
                    gap="sm"
                    css={{ width: '100%', '@md': { flexDirection: 'column' } }}
                  >
                    <ControlledSelectAutocomplete
                      name={`${RoomFields.PROCEDURES}.${index}.${RoomFields.PROCEDURE_ID}`}
                      label={t('Procedure')}
                      placeholder={t('Insert procedure')}
                      options={proceduresOptions}
                      state={
                        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
                          RoomFields.PROCEDURE_ID
                        ]
                          ? 'error'
                          : 'null'
                      }
                      errorMsg={
                        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
                          RoomFields.PROCEDURE_ID
                        ]?.message
                      }
                      fullWidth
                    />
                    <Input
                      {...form.register(
                        `${RoomFields.PROCEDURES}.${index}.${RoomFields.CAPACITY}`,
                      )}
                      type="number"
                      label={t('Capacity')}
                      placeholder={t('Type the procedure\'s capacity')}
                      state={
                        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
                          RoomFields.CAPACITY
                        ]
                          ? 'error'
                          : 'null'
                      }
                      errorMsg={
                        form.formState.errors[RoomFields.PROCEDURES]?.[index]?.[
                          RoomFields.CAPACITY
                        ]?.message
                      }
                      fullWidth
                    />
                  </Flex>
                  <Button
                    variant="textOnly"
                    iconPosition="iconOnly"
                    onClick={() => remove(index)}
                    iconComponent={() => <Cross1Icon />}
                    css={{
                      alignSelf: removeButtonPlacement(index),
                      '@md': { alignSelf: 'center' },
                    }}
                  />
                </Flex>
              )
            })}
            <Button
              iconComponent={() => <PlusIcon />}
              onClick={() =>
                append({
                  [RoomFields.PROCEDURE_ID]: '',
                  [RoomFields.CAPACITY]: 0,
                })
              }
            >
              {t('Add Procedure')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Card>
  )
}

export default RoomForm
