import { alert } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { PatientInputType } from 'types/patients'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import PatientForm from 'components/Patients/PatientForm'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { PatientInputSchema } from 'schemas/patient'
import { getName } from 'utils/text'

const NewPatient = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const form = useFormManager<PatientInputType>({
    enableReinitialize: true,
    schema: PatientInputSchema,
    onCancel: () => navigate(RootPath.PATIENTS),
    onSubmit: async input => {
      await api.addPatient(input)
      navigate(RootPath.PATIENTS)
      alert.success(
        `${t('Success')}!`,
        `${getName(input)} ${t('was added successfully')}.`,
        { position: 'bottom-right' },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Patient')}
      goBackTo={RootPath.PATIENTS}
      fullWidth={false}
    >
      <PatientForm {...form} />
    </PageTemplate>
  )
}

export default NewPatient
