import { DatePicker } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'

import 'react-date-picker/dist/DatePicker.css'
import 'react-clock/dist/Clock.css'
import { format } from 'utils/time'

type ControlledSelectProps = {
  name: string
  label?: string
  error?: string | null
  fullWidth?: boolean
}

export const ControlledDatePicker = ({
  name,
  label,
  error,
}: ControlledSelectProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <DatePicker
          label={label}
          value={value}
          onChange={value =>
            onChange(value ? format(value as string | Date) : null)
          }
          error={error}
        />
      )}
    />
  )
}
