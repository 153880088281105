import { Cross1Icon, Pencil1Icon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button, Flex, Pill } from '@weareredlight/design-system'
import i18next from 'i18next'

import type { ColumnSort } from '@tanstack/react-table'
import type {
  ProcedureProviderType,
  ProviderScheduleType,
} from 'types/providers'

import { ShiftScheduleActions } from 'components/Providers/utils'
import { defaultColumnOptions } from 'utils/table'
import { splitCamelCase } from 'utils/text'
import { DATE_FORMAT, format } from 'utils/time'

const columnHelper = createColumnHelper<ProcedureProviderType>()

export const providersColumns = [
  columnHelper.accessor('certification', {
    ...defaultColumnOptions<ProcedureProviderType>(),
    header: 'Certification',
    enableSorting: false,
    enableColumnFilter: false,
    cell: info => {
      return splitCamelCase(i18next.t(String(info.getValue())))
    },
  }),
  columnHelper.accessor('speciality', {
    ...defaultColumnOptions<ProcedureProviderType>(),
    header: 'Speciality',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row: { original } }) => {
      return original.speciality ? (
        <Pill>{splitCamelCase(i18next.t(original.speciality))}</Pill>
      ) : (
        '--'
      )
    },
  }),
  columnHelper.accessor('count', {
    ...defaultColumnOptions<ProcedureProviderType>(),
    header: 'Quantity',
    enableSorting: false,
    enableColumnFilter: false,
  }),
]

const scheduleColumnHelper = createColumnHelper<ProviderScheduleType>()

export const providerScheduleDefaultSortees: ColumnSort[] = [
  { id: 'startTime', desc: true },
]

export const providerScheduleColumns = (
  handleAction: (
    shift: ProviderScheduleType,
    action: ShiftScheduleActions,
  ) => void,
) => [
  scheduleColumnHelper.accessor('assetId', {
    ...defaultColumnOptions<ProviderScheduleType>(),
    header: 'Date of Shift',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row: { original } }) => format(original.startTime, DATE_FORMAT),
  }),
  scheduleColumnHelper.accessor('startTime', {
    ...defaultColumnOptions<ProviderScheduleType>(),
    header: 'Start Time',
    enableSorting: false,
    enableColumnFilter: false,
    cell: info => format(info.getValue(), 'h:mma'),
  }),
  scheduleColumnHelper.accessor('endTime', {
    ...defaultColumnOptions<ProviderScheduleType>(),
    header: 'End Time',
    enableSorting: false,
    enableColumnFilter: false,
    cell: info => format(info.getValue(), 'h:mma'),
  }),
  scheduleColumnHelper.accessor('createdAt', {
    ...defaultColumnOptions<ProviderScheduleType>(),
    header: 'Added At',
    enableSorting: false,
    enableColumnFilter: false,
    cell: info => format(info.getValue(), DATE_FORMAT),
  }),
  scheduleColumnHelper.accessor('id', {
    ...defaultColumnOptions<ProviderScheduleType>(),
    header: 'Actions',
    enableColumnFilter: false,
    enableSorting: false,
    meta: { width: '10%' },
    cell: ({ row: { original } }) => {
      return (
        <Flex gap="xxsm" css={{ width: '100%' }}>
          <Button
            variant={'secondary'}
            onClick={() => handleAction(original, ShiftScheduleActions.EDIT)}
            iconComponent={() => <Pencil1Icon />}
            iconPosition="iconOnly"
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
          <Button
            variant="neutral"
            onClick={() => handleAction(original, ShiftScheduleActions.DELETE)}
            iconComponent={() => <Cross1Icon />}
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
        </Flex>
      )
    },
  }),
]
