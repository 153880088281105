/* eslint-disable @typescript-eslint/no-explicit-any */
const $config = (window as any).config ?? {}

const config = {
  API_URL: process.env.REACT_APP_API_URL!,
  AMPLIFY: {
    region: process.env.REACT_APP_COGNITO_REGION!,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID!,
  },
}

type Config = typeof config & {
  ENVIRONMENT: 'local'
}

export default {
  ...$config,
  ...config,
} as Config
