import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LANGUAGE_KEY = 'appLanguage'

const useLanguage = () => {
  const { i18n } = useTranslation()

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem(LANGUAGE_KEY, language)
  }

  const getStoredLanguage = (): string | null =>
    localStorage.getItem(LANGUAGE_KEY)

  useEffect(() => {
    const storedLanguage = getStoredLanguage()
    if (storedLanguage) {
      setLanguage(storedLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { setLanguage, getStoredLanguage }
}

export default useLanguage
