import { Route, Routes } from 'react-router-dom'

import TreatmentEdit from './TreatmentEdit'
import TreatmentView from './TreatmentView'

import NewTreatment from 'pages/Treatments/NewTreatment'
import { GenericPath, TreatmentsPath } from 'router/enums'

const Treatments = () => (
  <Routes>
    <Route path={GenericPath.NEW} element={<NewTreatment />} />
    <Route path={TreatmentsPath.TREATMENT} element={<TreatmentView />} />
    {process.env.REACT_APP_ENV !== 'production' && (
      <Route
        path={`${TreatmentsPath.TREATMENT}/${GenericPath.EDIT}`}
        element={<TreatmentEdit />}
      />
    )}
  </Routes>
)
export default Treatments
