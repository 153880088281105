import { Flex, Text } from '@weareredlight/design-system'
import React from 'react'

export interface EntityDetailType {
  label: string
  children: React.ReactNode
  style?: React.CSSProperties
}

const EntityDetail = ({ label, children, style }: EntityDetailType) => {
  return (
    <Flex direction='column' align='start' style={style}>
      <Text variant='subHeading' color='accent'>
        {label}
      </Text>
      <Text variant='paragraph' color='neutral800'>
        {children}
      </Text>
    </Flex>
  )
}

export default EntityDetail
