import { createColumnHelper } from '@tanstack/react-table'
import { Pill } from '@weareredlight/design-system'
import i18next from 'i18next'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { UserType } from 'types/users'

import { RootPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'
import { splitCamelCase } from 'utils/text'

type UserColumnsType = UserType & { name?: string }

export const usersDefaultSortees: ColumnSort[] = [{ id: 'name', desc: false }]

const columnHelper = createColumnHelper<UserColumnsType>()
export const usersColumns = [
  columnHelper.accessor('firstName', {
    ...defaultColumnOptions<UserColumnsType>(),
    header: 'Name',
    id: 'name',
    meta: { width: '25%' },
    cell: info => {
      const { firstName, lastName, id } = info.row.original
      return (
        <Link
          to={`${RootPath.USERS}/${String(id)}`}
          style={{ color: 'var(--colors-primary)' }}
        >
          {`${firstName} ${lastName}`}
        </Link>
      )
    },
  }),
  columnHelper.accessor('email', {
    ...defaultColumnOptions<UserColumnsType>(),
    header: 'Email',
    meta: { width: '40%' },
    enableSorting: false,
  }),
  columnHelper.accessor('role', {
    ...defaultColumnOptions<UserColumnsType>(),
    header: 'User role',
    meta: { width: '20%' },
    cell: info => {
      const role = info.getValue()
      return i18next.t(splitCamelCase(role))
    },
  }),
  columnHelper.accessor('active', {
    ...defaultColumnOptions<UserColumnsType>(),
    header: 'Active status',
    enableColumnFilter: false,
    meta: { width: '15%' },
    cell: info => {
      const active = info.getValue()
      return active ? (
        <Pill variant="success">{i18next.t('Active')}</Pill>
      ) : (
        <Pill variant="error">{i18next.t('Inactive')}</Pill>
      )
    },
  }),
]
