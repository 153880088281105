import { Route, Routes } from 'react-router-dom'

import UsersAdd from 'pages/Users/NewUser'
import UserEdit from 'pages/Users/UserEdit'
import UsersList from 'pages/Users/UsersList'
import UserView from 'pages/Users/UserView'
import { GenericPath } from 'router/enums'

const Users = () => (
  <Routes>
    <Route index element={<UsersList />} />
    <Route path={GenericPath.NEW} element={<UsersAdd />} />
    <Route path={GenericPath.ENTITY} element={<UserView />} />
    <Route path={`${GenericPath.ENTITY}/${GenericPath.EDIT}`} element={<UserEdit />} />
  </Routes>
)

export default Users
