import { Flex, Label } from '@weareredlight/design-system'
import axios from 'axios'
import { type ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import api from 'api/api'
import { LabelAsButton } from 'components/Form/form.styles'
import { type AssetTypes } from 'utils/enums'

type FileUploadType = {
  name: string
  assetType: AssetTypes
  disabled?: boolean
  label?: string
  onChange: (assetId: string) => void
  accept?: string
  placeholder?: string
}

const FileUpload = ({
  disabled,
  label,
  name,
  onChange,
  assetType,
  accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  placeholder,
}: FileUploadType) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]

      if (!file) return
      setIsLoading(true)
      const {
        data: { uploadUrl, id },
      } = await api.createAsset({
        assetType,
        fileName: file.name,
        contentType: file.type,
      })

      await axios.put(uploadUrl, file, {
        headers: { 'Content-Type': file.type },
      })

      onChange(id)
      setIsLoading(false)
    },
    [assetType, onChange],
  )

  return (
    <Flex direction="column" align="start" gap="xxxsm">
      {label && <Label id={name} label={label} />}
      <input
        id={name}
        name={name}
        accept={accept}
        disabled={isLoading || disabled}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <LabelAsButton htmlFor={name} isLoading={isLoading}>
        {isLoading ? t('Loading') : placeholder || t('Upload')}
      </LabelAsButton>
    </Flex>
  )
}

export default FileUpload
