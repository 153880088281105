import {
  Pencil1Icon,
  PlusIcon,
  Cross1Icon,
  ResetIcon,
} from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Text, Flex } from '@weareredlight/design-system'
import { Button } from '@weareredlight/design-system'
import i18next from 'i18next'
import { Link } from 'react-router-dom'

import type { ProcedureWithAppointmentType } from 'types/procedures'

import { GenericPath } from 'router/enums'
import { ProcedureStatuses } from 'utils/enums'
import { defaultColumnOptions } from 'utils/table'
import { APPOINTMENT_FORMAT, format } from 'utils/time'

const columnHelper = createColumnHelper<ProcedureWithAppointmentType>()

export const treatmentProceduresColumns = (
  cancelProcedure: (id: string) => void,
  restoreProcedure: (id: string) => void,
) => [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<ProcedureWithAppointmentType>(),
    header: 'Name',
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('appointment.startTime', {
    ...defaultColumnOptions<ProcedureWithAppointmentType>(),
    header: 'Appointment',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row: { original } }) => {
      const hasProcedure = original.appointment.startTime
      if (original.status === ProcedureStatuses.CANCELLED)
        return (
          <Text variant="textBlock" color="neutral">
            {i18next.t('Cancelled')}
          </Text>
        )
      else if (hasProcedure) return format(hasProcedure, APPOINTMENT_FORMAT)
      return (
        <Text variant="textBlock" color="neutral">
          {i18next.t('Not scheduled')}
        </Text>
      )
    },
  }),

  columnHelper.accessor('id', {
    ...defaultColumnOptions<ProcedureWithAppointmentType>(),
    header: 'Actions',
    enableColumnFilter: false,
    enableSorting: false,
    meta: { width: '10%' },
    cell: ({ row: { original } }) => {
      const hasProcedure = original.appointment?.startTime
      return original.status === ProcedureStatuses.CANCELLED ? (
        <Flex gap="xxxsm" css={{ width: '100%' }}>
          <Button
            variant="neutral"
            onClick={() => restoreProcedure(original.treatmentProcedureId)}
            iconComponent={() => <ResetIcon />}
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
        </Flex>
      ) : (
        <Flex gap="xxxsm" css={{ width: '100%' }}>
          <Link
            to={`${original.treatmentProcedureId}/${
              hasProcedure ? GenericPath.EDIT : GenericPath.NEW
            }`}
          >
            <Button
              variant={hasProcedure ? 'secondary' : 'tertiary'}
              iconComponent={() => {
                return hasProcedure ? <Pencil1Icon /> : <PlusIcon />
              }}
              iconPosition="iconOnly"
              css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
            />
          </Link>
          <Button
            variant="neutral"
            onClick={() => cancelProcedure(original.treatmentProcedureId)}
            iconComponent={() => <Cross1Icon />}
            css={{ padding: '$xxxsm', width: '20px', height: '20px' }}
          />
        </Flex>
      )
    },
  }),
]
