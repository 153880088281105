import { PlusIcon } from '@radix-ui/react-icons'
import { Button, Flex, Text } from '@weareredlight/design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProviderType, ProviderParamsType } from 'types/providers'

import api from 'api/api'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { ProvidersPath, GenericPath } from 'router/enums'
import {
  providerDefaultSortees,
  providersColumns,
} from 'utils/tables/providersPage'

const OrganizationProvidersList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const params = useMemo(
    () => ({
      organizationId: id || '',
    }),
    [id],
  )

  const { tableData, ...rest } = useTableRequestState<
    ProviderType,
    ProviderParamsType
  >({
    requestFunc: api.getProviders,
    pageSize: 10,
    sortees: providerDefaultSortees,
    params,
  })

  return (
    <Flex direction="column" gap="xxlg" css={{ padding: '1.5rem' }}>
      <Flex justify="spaceBetween" css={{ width: '100%' }}>
        <Text variant="h3" color="accent">
          {t('Providers')}
        </Text>
        <Button
          variant="tertiary"
          iconComponent={() => <PlusIcon />}
          onClick={() =>
            navigate(`${ProvidersPath.PROVIDERS}/${GenericPath.NEW}`)
          }
        >
          {t('Add Provider')}
        </Button>
      </Flex>
      <Table<ProviderType>
        data={tableData?.data || []}
        columns={providersColumns}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </Flex>
  )
}

export default OrganizationProvidersList
