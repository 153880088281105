import { Route, Routes } from 'react-router-dom'

import Dashboard from 'pages/Reports/Dashboard'

const Reports = () => (
  <Routes>
    <Route index element={<Dashboard />} />
  </Routes>
)

export default Reports
