import { createColumnHelper } from '@tanstack/react-table'
import i18next from 'i18next'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { ProviderType } from 'types/providers'

import { ProvidersPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'
import { getName, splitCamelCase } from 'utils/text'

type ProviderColumnsType = ProviderType & { name?: string }

export const providerDefaultSortees: ColumnSort[] = [
  { id: 'name', desc: false },
]

const columnHelper = createColumnHelper<ProviderColumnsType>()
export const providersColumns = [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<ProviderColumnsType>(),
    header: 'Name',
    enableSorting: true,
    enableColumnFilter: false,
    cell: ({ row: { original } }) => (
      <Link
        to={`${ProvidersPath.PROVIDERS}/${String(original.id)}`}
        style={{ color: 'var(--colors-primary)' }}
      >
        {getName(original)}
      </Link>
    ),
  }),
  columnHelper.accessor('certification', {
    ...defaultColumnOptions<ProviderColumnsType>(),
    header: 'Certification',
    enableColumnFilter: false,
    enableSorting: false,
    cell: info => {
      return splitCamelCase(i18next.t(info.getValue()))
    },
  }),
  columnHelper.accessor('specialities', {
    ...defaultColumnOptions<ProviderColumnsType>(),
    header: 'Specialies',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row: { original } }) => {
      return original.specialities?.length ? (
        <ul>
          {original.specialities?.map(speciality => (
            <li key={speciality}>{splitCamelCase(i18next.t(speciality))}</li>
          ))}
        </ul>
      ) : (
        '-'
      )
    },
  }),
]
