import { Route, Routes } from 'react-router-dom'

import NewProvider from 'pages/Providers/NewProvider'
import ProviderEdit from 'pages/Providers/ProviderEdit'
import ProviderView from 'pages/Providers/ProviderView'
import { ProvidersPath, GenericPath } from 'router/enums'

const Providers = () => (
    <Routes>
        <Route path={GenericPath.NEW} element={<NewProvider />} />
        <Route path={ProvidersPath.PROVIDER} element={<ProviderView />} />
        <Route path={`${ProvidersPath.PROVIDER}/${GenericPath.EDIT}`} element={<ProviderEdit />} />
    </Routes>
)

export default Providers

