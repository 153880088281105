import { alert } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { RoomInputType } from 'types/rooms'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import RoomForm from 'components/Rooms/RoomForm'
import { RoomFields } from 'components/Rooms/utils'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { RoomInputSchema } from 'schemas/Room'

const NewRoom = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const goBackToUrl = React.useMemo(
    () => `${RootPath.ORGANIZATIONS}/${id}`,
    [id],
  )

  const form = useFormManager<RoomInputType>({
    defaultValues: {
      [RoomFields.NAME]: '',
      [RoomFields.CAPACITY]: 0,
      [RoomFields.PROCEDURES]: [
        {
          [RoomFields.PROCEDURE_ID]: '',
          [RoomFields.CAPACITY]: 0,
        },
      ],
      [RoomFields.ORGANIZATION_ID]: id,
    },
    enableReinitialize: true,
    schema: RoomInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: RoomInputType) => {
      await api.addRoom(input)
      navigate(goBackToUrl)
      alert.success(
        `${t('Success')}!`,
        `${input.name} ${t('was created successfully')}.`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Room')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <RoomForm {...form} />
    </PageTemplate>
  )
}

export default NewRoom
