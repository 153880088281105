import { alert, Text, Flex, Button } from '@weareredlight/design-system'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { ProviderScheduleType } from 'types/providers'

import DeleteProviderShift from './DeleteProviderShift'
import EditProviderShift from './EditProviderShift'
import NewProviderShift from './NewProviderShift'
import { ShiftScheduleActions } from './utils'

import api from 'api/api'
import FileUpload from 'components/Form/FileUpload'
import Table from 'components/Table'
import { useRequest } from 'hooks/useRequest'
import useTableRequestState from 'hooks/useTableRequestState'
import { AssetTypes } from 'utils/enums'
import {
  providerScheduleColumns,
  providerScheduleDefaultSortees,
} from 'utils/tables/providers'

type AddScheduleReturnType = {
  failedTotal: number
  processedTotal: number
  validationErrors: string[]
}

type AddScheduleParamsType = {
  assetId: string
  providerId: string
}

type ProviderShiftsProps = {
  providerId: string
}

const ProviderShifts = ({ providerId }: ProviderShiftsProps) => {
  const { t } = useTranslation()
  const [activeShift, setActiveShift] = useState<ProviderScheduleType>()
  const [action, setAction] = useState<ShiftScheduleActions>()

  const providerShiftsParams = useMemo(() => ({ providerId }), [providerId])

  const { doRequest: downloadTemplate, isLoading: isDownloading } = useRequest(
    api.getShiftTemplate,
    {
      onSuccess: data => {
        const csv = new Blob([data], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(csv)
        link.setAttribute('download', t('ScheduleTemplate.csv'))
        link.click()
        alert.success(t('Template downloaded successfully'))
      },
      onError: () => {
        alert.error(t('Error downloading template'))
      },
    },
  )
  const { tableData, refreshTable, ...rest } = useTableRequestState<
    ProviderScheduleType,
    { providerId: string }
  >({
    requestFunc: api.getProviderSchedule,
    pageSize: 10,
    sortees: providerScheduleDefaultSortees,
    params: providerShiftsParams,
    requiredParams: ['providerId'],
  })

  const getErrorMessage = (count: number) =>
    `${t('Error uploading')} ${count} ${t('shifts')}.`

  const { doRequest: addProviderSchedule, isLoading } = useRequest<
    AddScheduleReturnType,
    AddScheduleParamsType
  >(api.addProviderSchedule, {
    onSuccess: data => {
      if (!data.processedTotal) {
        alert.error(`${t('Error')}!`, getErrorMessage(data.failedTotal), {
          position: 'bottom-right',
        })
      } else {
        refreshTable()
        const message = !data.failedTotal
          ? ''
          : getErrorMessage(data.failedTotal)
        alert.success(
          `${t('Success')}!`,
          `${data.processedTotal} ${t(
            'shifts uploaded successfully',
          )}. ${message}`,
          { position: 'bottom-right' },
        )
      }
    },
    onError: () =>
      alert.error(`${t('Error')}!`, `${t('Error uploading shifts')}.`, {
        position: 'bottom-right',
      }),
  })

  const onAddSchedule = useCallback(
    (assetId: string) => {
      if (!providerId || !assetId) return
      addProviderSchedule({ assetId, providerId })
    },
    [addProviderSchedule, providerId],
  )

  const handleAction = (
    shift: ProviderScheduleType,
    action: ShiftScheduleActions,
  ) => {
    setActiveShift(shift)
    setAction(action)
  }

  return (
    <Flex gap="xxlg" direction="column" align="start">
      <Flex justify="spaceBetween" css={{ width: '100%' }}>
        <Text variant="h3" color="accent">
          {t('Shifts')}
        </Text>
        <Flex gap="xxsm">
          <Button
            variant="secondary"
            onClick={() => setAction(ShiftScheduleActions.NEW)}
          >
            {t('New Shift')}
          </Button>
          <Button
            variant="secondary"
            isLoading={isDownloading}
            onClick={() => downloadTemplate()}
          >
            {t('Template')}
          </Button>
          <FileUpload
            name="providerSchedule"
            assetType={AssetTypes.PROVIDER_SCHEDULES}
            onChange={onAddSchedule}
            placeholder={t('Upload Schedule')}
          />
        </Flex>
      </Flex>
      <Table<ProviderScheduleType>
        data={tableData?.data || []}
        columns={providerScheduleColumns(handleAction)}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
      <DeleteProviderShift
        isOpen={action === ShiftScheduleActions.DELETE}
        onClose={setActiveShift}
        refreshData={refreshTable}
        providerScheduleId={activeShift?.id || ''}
      />
      <EditProviderShift
        isOpen={action === ShiftScheduleActions.EDIT}
        onClose={setActiveShift}
        refreshData={refreshTable}
        shift={activeShift}
        isLoading={isLoading}
      />
      <NewProviderShift
        isOpen={action === ShiftScheduleActions.NEW}
        onClose={setAction}
        refreshData={refreshTable}
        providerId={providerId}
      />
    </Flex>
  )
}

export default ProviderShifts
