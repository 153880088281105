import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

//Import all translation files
import enUS from './locales/en-US.json'
import esES from './locales/es-ES.json'

const resources = {
  'en-US': {
    translation: enUS,
  },
  'es-ES': {
    translation: esES,
  },
}

const lngStored = localStorage.getItem('appLanguage')

i18next.use(initReactI18next).init({
  resources,
  lng: lngStored ?? 'en-US', //default language
})

export default i18next
