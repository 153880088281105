import * as yup from 'yup'

import type { PatientInputType } from 'types/patients'
import type { TreatmentInputType } from 'types/treatments'

import { PatientFields } from 'components/Patients/PatientForm'
import { TreatmentFields } from 'components/Treatments/utils'
import i18next from 'i18n'
import {
  DocumentType,
  Gender,
  PatientStage,
  PatientTypeEnum,
  TreatmentStatuses,
} from 'utils/enums'

export const PatientInputSchema: yup.Schema<PatientInputType> = yup
  .object()
  .shape({
    [PatientFields.FIRST_NAME]: yup
      .string()
      .required(i18next.t('First name is required')),
    [PatientFields.MIDDLE_NAME]: yup.string().nullable(),
    [PatientFields.LAST_NAME]: yup
      .string()
      .required(i18next.t('Last name is required')),
    [PatientFields.SECOND_LAST_NAME]: yup.string().nullable(),
    [PatientFields.EMAIL]: yup
      .string()
      .email(i18next.t('Email format is incorrect'))
      .required(i18next.t('Email is required')),
    [PatientFields.DATE_OF_BIRTH]: yup
      .string()
      .required(i18next.t('Date of birth is required')),
    [PatientFields.EXTERNAL_PATIENT_ID]: yup.string().nullable(),
    [PatientFields.PHONE_NUMBER]: yup.string().nullable(),
    [PatientFields.DOCUMENT_NUMBER]: yup
      .string()
      .required(i18next.t('Document number is required')),
    [PatientFields.DOCUMENT_TYPE]: yup
      .mixed<DocumentType>()
      .oneOf(Object.values(DocumentType))
      .required(i18next.t('Document type is required')),
    [PatientFields.CONSENT_TO_SMS]: yup
      .boolean()
      .required(i18next.t('Consent to SMS is required')),
    [PatientFields.HOME_PHONE]: yup.string().nullable(),
    [PatientFields.COUNTRY]: yup.string().nullable(),
    [PatientFields.STREET]: yup.string().nullable(),
    [PatientFields.STATE]: yup.string().nullable(),
    [PatientFields.CITY]: yup.string().nullable(),
    [PatientFields.POSTAL_CODE]: yup.string().nullable(),
    [PatientFields.GENDER]: yup
      .mixed<Gender>()
      .oneOf(Object.values(Gender))
      .required(i18next.t('Gender is required')),
    [PatientFields.CONCIERGE_NAME]: yup.string().nullable(),
    [PatientFields.CONCIERGE_PHONE_NUMBER]: yup.string().nullable(),
    [PatientFields.ADVOCATE_NAME]: yup.string().nullable(),
    [PatientFields.ADVOCATE_PHONE_NUMBER]: yup.string().nullable(),
    [PatientFields.STAGE]: yup
      .mixed<PatientStage>()
      .oneOf(Object.values(PatientStage))
      .required(i18next.t('Patient stage is required')),
    [PatientFields.PATIENT_TYPE]: yup
      .mixed<PatientTypeEnum>()
      .oneOf(Object.values(PatientTypeEnum))
      .required(i18next.t('Patient type is required')),
    [PatientFields.AGE]: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        (originalValue === '' ? null : value),
      )
      .integer(i18next.t('Age format is incorrect'))
      .min(0, i18next.t('Age format is incorrect'))
      .typeError(i18next.t('Age format is incorrect')),
  })

export const PatientTreatmentInputSchema: yup.Schema<TreatmentInputType> = yup
  .object()
  .shape({
    [TreatmentFields.YEAR]: yup
      .number()
      .required()
      .typeError(i18next.t('Year is required'))
      .min(2023, i18next.t('Year is required')),
    [TreatmentFields.WEEK]: yup
      .number()
      .required()
      .typeError(i18next.t('Week is required'))
      .min(1, i18next.t('Week is required')),
    [TreatmentFields.COMMENTS]: yup.string().nullable(),
    [TreatmentFields.STATUS]: yup
      .mixed<TreatmentStatuses>()
      .oneOf(Object.values(TreatmentStatuses))
      .required(i18next.t('Status is required')),
    [TreatmentFields.ORGANIZATION_ID]: yup
      .string()
      .required(i18next.t('Organization is required')),
    [TreatmentFields.PATIENT_ID]: yup
      .string()
      .required(i18next.t('Patient id is required')),
    [TreatmentFields.NAME]: yup.string().nullable(),
    [TreatmentFields.IS_INTRATHICAL_APPLICATION]: yup
      .boolean()
      .oneOf([true, false], i18next.t('Is Intrathical Application is required'))
      .required(i18next.t('Is Intrathical Application is required')),
    [TreatmentFields.INVOICE_NUMBER]: yup.string().nullable(),
    [TreatmentFields.SALESFORCE_ID]: yup.string().nullable(),
    [TreatmentFields.PLAN_IN_DAYS]: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        (originalValue === '' ? null : value),
      )
      .integer(i18next.t('Plan in days format is incorrect'))
      .min(0, i18next.t('Plan in days format is incorrect'))
      .typeError(i18next.t('Plan in days format is incorrect')),
    [TreatmentFields.SYSTEM_MOD_STAMP]: yup.string().nullable(),
    [TreatmentFields.VISIT]: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        (originalValue === '' ? null : value),
      )
      .integer(i18next.t('Visit format is incorrect'))
      .min(0, i18next.t('Visit format is incorrect'))
      .typeError(i18next.t('Visit format is incorrect')),
  })
