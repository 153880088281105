export const splitCamelCase = (identifier: string) => {
  return identifier?.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const getName = (
  name?: Record<string, unknown> | null,
  prefix?: string,
) => {
  if (!name) return '--'

  const firstName = name[prefix ? prefix + 'FirstName' : 'firstName'] || ''
  const middleName = name[prefix ? prefix + 'MiddleName' : 'middleName'] || ''
  const lastName = name[prefix ? prefix + 'LastName' : 'lastName'] || ''
  const secondLastName =
    name[prefix ? prefix + 'SecondLastName' : 'secondLastName'] || ''

  return `${firstName} ${middleName} ${lastName} ${secondLastName}`
    .trim()
    .replace(/\s+/g, ' ')
}
