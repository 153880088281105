import { Button, Text, Flex } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { RoomType } from 'types/rooms'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { RoomFields } from 'components/Rooms/utils'
import Table from 'components/Table'
import { useRequest } from 'hooks/useRequest'
import { GenericPath, RootPath } from 'router/enums'
import { roomProceduresColumns } from 'utils/tables/procedures'

const RoomView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id, roomId } = useParams<{ id: string; roomId: string }>()

  const {
    data: room,
    doRequest: getRoom,
    isLoading,
  } = useRequest<RoomType, { id: string }>(api.getRoom)

  useEffect(() => {
    if (roomId) getRoom({ id: roomId })
  }, [getRoom, roomId])

  return (
    <PageTemplate
      title={t('Room Details')}
      goBackTo={`${RootPath.ORGANIZATIONS}/${id}`}
      numberOfElements={1}
      fullWidth={false}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card width="464px" isLoading={isLoading}>
        <Flex gap="xxxlg" direction="column" align="start">
          <Text variant="h2" color="primary800">
            {room?.[RoomFields.NAME]}
          </Text>
          <Flex
            direction="column"
            align="start"
            gap="xsm"
            css={{ width: '100%' }}
          >
            <EntityDetail label={t('Total Capacity')}>
              {room?.[RoomFields.CAPACITY]}
            </EntityDetail>
            <Table
              data={room?.roomProcedures ?? []}
              columns={roomProceduresColumns}
              totalCount={room?.roomProcedures?.length || 0}
              totalPages={0}
            />
          </Flex>
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default RoomView
