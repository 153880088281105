import * as yup from 'yup'

import type { TreatmentCancellationType } from 'types/treatments'

import { TreatmentCancellationFields } from 'components/Treatments/utils'
import i18next from 'i18n'
import {
  CancellationOrders,
  CancellationReasons,
  CancellationTimes,
} from 'utils/enums'

export const TreatmentCancellationSchema: yup.Schema<TreatmentCancellationType> =
  yup.object().shape({
    [TreatmentCancellationFields.ORDER]: yup
      .mixed<CancellationOrders>()
      .oneOf(Object.values(CancellationOrders))
      .required(i18next.t('Cancellation order is required')),
    [TreatmentCancellationFields.TIME]: yup
      .mixed<CancellationTimes>()
      .oneOf(Object.values(CancellationTimes))
      .required(i18next.t('Cancellation time is required')),
    [TreatmentCancellationFields.REASON]: yup
      .mixed<CancellationReasons>()
      .oneOf(Object.values(CancellationReasons))
      .required(i18next.t('Cancellation reason is required')),
    [TreatmentCancellationFields.HAS_FEE]: yup.boolean().required(),
    [TreatmentCancellationFields.IS_RESCHEDULED]: yup.boolean().required(),
  })
