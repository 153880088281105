import includes from 'lodash/includes'
import { useMemo } from 'react'

import { RootPath } from './enums'

import Organizations from 'pages/Organizations'
import Patients from 'pages/Patients'
import Procedures from 'pages/Procedures'
import ProfileEdit from 'pages/Profile/ProfileEdit'
import ProfileView from 'pages/Profile/ProfileView'
import Reports from 'pages/Reports'
import Schedule from 'pages/Schedule'
import Users from 'pages/Users'
import { useCurrentUser } from 'userContext'
import { UserRoles } from 'utils/enums'

export const routes: Record<RootPath, React.ReactNode> = {
  [RootPath.ORGANIZATIONS]: <Organizations />,
  [RootPath.PATIENTS]: <Patients />,
  [RootPath.PROCEDURES]: <Procedures />,
  [RootPath.PROFILE]: <ProfileView />,
  [RootPath.PROFILE_EDIT]: <ProfileEdit />,
  [RootPath.REPORTS]: <Reports />,
  [RootPath.SCHEDULE]: <Schedule />,
  [RootPath.USERS]: <Users />,
}

export const RootPathPerms: Record<RootPath, UserRoles[]> = {
  [RootPath.ORGANIZATIONS]: [
    UserRoles.SYSTEM_ADMINISTRATOR,
    UserRoles.SCHEDULING_ADMINISTRATOR,
  ],
  [RootPath.PATIENTS]: Object.values(UserRoles),
  [RootPath.PROCEDURES]: [
    UserRoles.SYSTEM_ADMINISTRATOR,
    UserRoles.SCHEDULING_ADMINISTRATOR,
  ],
  [RootPath.PROFILE]: Object.values(UserRoles),
  [RootPath.PROFILE_EDIT]: Object.values(UserRoles),
  [RootPath.REPORTS]: [
    UserRoles.SYSTEM_ADMINISTRATOR,
    UserRoles.SCHEDULING_ADMINISTRATOR,
    UserRoles.EXECUTIVE_USER,
  ],
  [RootPath.SCHEDULE]: Object.values(UserRoles),
  [RootPath.USERS]: [UserRoles.SYSTEM_ADMINISTRATOR],
}

export const PermissionRequiredBoundary = ({
  allowed,
  fallback,
  children,
}: {
  allowed: string[]
  fallback?: React.ReactNode
  children: React.ReactNode
}) => {
  const { currentUserData } = useCurrentUser()

  const isAuthorized = useMemo(() => {
    if (!currentUserData?.role) return false
    return !!includes(allowed, currentUserData.role)
  }, [currentUserData, allowed])

  if (!isAuthorized) return <>{fallback}</>
  return <>{children}</>
}
