export enum RootPath {
  PROFILE = '/profile',
  PROFILE_EDIT = '/profile/edit',
  PROCEDURES = '/procedures',
  REPORTS = '/reports',
  USERS = '/users',
  ORGANIZATIONS = '/organizations',
  PATIENTS = '/patients',
  SCHEDULE = '/schedule',
}

export enum GenericPath {
  NEW = 'new',
  ENTITY = ':id',
  EDIT = 'edit',
}

export enum ProvidersPath {
  PROVIDERS = 'providers',
  PROVIDER = ':providerId',
}

export enum RoomsPath {
  ROOMS = 'rooms',
  ROOM = ':roomId',
}

export enum TreatmentsPath {
  TREATMENTS = 'treatments',
  TREATMENT = ':treatmentId',
}
