import { Flex } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProviderScheduleInputType } from 'types/providers'
import type { FormType } from 'types/types'

import { ShiftsFields } from './utils'

import {
  ControlledDatePicker,
  ControlledTimePicker,
  Form,
  PickerTypes,
} from 'components/Form'

const ProviderShiftsForm = ({
  form,
  ...props
}: FormType<ProviderScheduleInputType>) => {
  const { t } = useTranslation()

  return (
    <Form form={form} {...props}>
      <Flex gap="xxlg" align="start" justify="start" css={{ width: '100%' }}>
        <ControlledDatePicker
          name={ShiftsFields.DATE}
          label={t('Date of Shift')}
          error={form.formState.errors['date']?.message}
        />
        <ControlledTimePicker
          type={PickerTypes.TIME}
          name={ShiftsFields.START_TIME}
          label={t('Start Time')}
          error={form.formState.errors[ShiftsFields.START_TIME]?.message}
        />
        <ControlledTimePicker
          type={PickerTypes.TIME}
          name={ShiftsFields.END_TIME}
          label={t('End Time')}
          error={form.formState.errors[ShiftsFields.END_TIME]?.message}
        />
      </Flex>
    </Form>
  )
}

export default ProviderShiftsForm
