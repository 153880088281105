import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { ProcedureType } from 'types/procedures'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath } from 'router/enums'
import {
  proceduresColumns,
  proceduresDefaultSortees,
} from 'utils/tables/procedures'
const ProceduresList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tableData, ...rest } = useTableRequestState<ProcedureType>({
    requestFunc: api.getProcedures,
    sortees: proceduresDefaultSortees,
  })

  return (
    <PageTemplate
      title={t('Procedures List')}
      rightActions={
        <Button
          onClick={() => navigate(GenericPath.NEW)}
          iconComponent={() => <PlusIcon />}
        >
          {t('Add Procedure')}
        </Button>
      }
    >
      <Table<ProcedureType>
        columns={proceduresColumns}
        data={tableData?.data || []}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </PageTemplate>
  )
}
export default ProceduresList
