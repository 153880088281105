import { Flex, Input, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import type { FormType } from 'types/types'
import type { UserInputType } from 'types/users'

import Card from 'components/Card'
import { ControlledSelect, ControlledToggle, Form } from 'components/Form'
import { RootPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { UserRoles, Languages, LanguageDetails } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'

export enum UserFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  STATUS = 'status',
  ROLE = 'role',
  LANGUAGE_CODE = 'languageCode',
  ACTIVE = 'active',
}

const UserForm = ({ form, isLoading, ...props }: FormType<UserInputType>) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useCurrentUser()
  const { pathname } = useLocation()
  const isProfile = pathname === RootPath.PROFILE_EDIT

  return (
    <Card isLoading={isLoading}>
      <Form<UserInputType> form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Flex justify="spaceBetween" style={{ width: '100%' }}>
            <Text variant="h2">{t('User Details')}</Text>
            {!isProfile && (
              <ControlledToggle
                name={UserFields.ACTIVE}
                label={t('Active status')}
              />
            )}
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              {...form.register(UserFields.FIRST_NAME)}
              label={t('First Name')}
              placeholder={t('First Name')}
              state={
                form.formState.errors[UserFields.FIRST_NAME] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[UserFields.FIRST_NAME]?.message}
              fullWidth
            />
            <Input
              {...form.register(UserFields.LAST_NAME)}
              label={t('Last Name')}
              placeholder={t('Enter last name')}
              state={
                form.formState.errors[UserFields.LAST_NAME] ? 'error' : 'null'
              }
              errorMsg={form.formState.errors[UserFields.LAST_NAME]?.message}
              fullWidth
            />
          </Flex>
          <Flex align="start" gap="xlg" className="fields-wrapper">
            <Input
              type="email"
              {...form.register(UserFields.EMAIL)}
              label={t('Email')}
              placeholder={t('Enter email')}
              state={form.formState.errors[UserFields.EMAIL] ? 'error' : 'null'}
              errorMsg={form.formState.errors[UserFields.EMAIL]?.message}
              fullWidth
            />
            {isSystemAdmin && !isProfile && (
              <ControlledSelect
                name={UserFields.ROLE}
                label={t('Role')}
                placeholder={t('Select user role')}
                options={enum2SelectOptions(UserRoles)}
                state={
                  form.formState.errors[UserFields.ROLE] ? 'error' : 'null'
                }
                fullWidth
              />
            )}
            <ControlledSelect
              name={UserFields.LANGUAGE_CODE}
              label={t('Language')}
              placeholder={t('Select language')}
              options={enum2SelectOptions(Languages, LanguageDetails)}
              state={
                form.formState.errors[UserFields.LANGUAGE_CODE]
                  ? 'error'
                  : 'null'
              }
              fullWidth
            />
          </Flex>
        </Flex>
      </Form>
    </Card>
  )
}

export default UserForm
