import { Select } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { OptionType } from 'types/types'

type ControlledSelectProps = {
  name: string
  options: OptionType[]
  label?: string
  description?: string
  placeholder?: string
  state?: 'disabled' | 'error' | 'null'
  errorMsg?: string
  fullWidth?: boolean
  customStyles?: React.CSSProperties
}

export const ControlledSelect = ({
  name,
  options,
  label,
  description,
  placeholder,
  state,
  errorMsg,
  fullWidth = false,
  customStyles,
}: ControlledSelectProps) => {
  const { control, formState } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select
          id={name}
          label={label}
          description={description}
          placeholder={placeholder || ''}
          value={value}
          state={state}
          onChange={newValue => {
            onChange(newValue.target.value)
          }}
          options={options}
          getLabel={(option: OptionType) => t(option.label)}
          getValue={(option: OptionType) => option.value}
          errorMsg={errorMsg ?? String(formState.errors[name]?.message)}
          fullWidth={fullWidth}
          css={{
            button: {
              ...customStyles,
            },
          }}
        />
      )}
    />
  )
}
