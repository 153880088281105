import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { OrganizationType } from 'types/organizations'

import { RootPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'

const columnHelper = createColumnHelper<OrganizationType>()

export const organizationsDefaultSortees: ColumnSort[] = [
  { id: 'name', desc: false },
]

export const organizationsColumns = [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<OrganizationType>(),
    header: 'Name',

    meta: { width: '100%' },
    cell: ({ row: { original } }) => (
      <Link
        to={`${RootPath.ORGANIZATIONS}/${original.id}`}
        style={{ color: 'var(--colors-primary)' }}
      >
        {original.name}
      </Link>
    ),
  }),
]
