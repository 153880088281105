import { alert } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type {
  OrganizationInputType,
  OrganizationType,
} from 'types/organizations'

import api from 'api/api'
import OrganizationForm from 'components/Organizations/OrganizationForm'
import PageTemplate from 'components/PageTemplate'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { OrganizationInputSchema } from 'schemas/organization'

const OrganizationEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const {
    data: organization,
    doRequest: getOrganization,
    isLoading,
  } = useRequest<OrganizationType, { id: string }>(api.getOrganization)

  const form = useFormManager<OrganizationInputType>({
    defaultValues: organization || {},
    enableReinitialize: true,
    schema: OrganizationInputSchema,
    onCancel: () => navigate(`${RootPath.ORGANIZATIONS}/${id}`),
    onSubmit: async input => {
      if (id) {
        await api.updateOrganization({ id, input })
        navigate(`${RootPath.ORGANIZATIONS}/${id}`)
        alert.success(
          `${t('Success')}!`,
          `${input.name} ${t('was edited successfully')}.`,
          {
            position: 'bottom-right',
          },
        )
      }
    },
  })

  useEffect(() => {
    if (id) getOrganization({ id })
  }, [getOrganization, id])

  return (
    <PageTemplate
      title={t('Edit Organization')}
      goBackTo={`${RootPath.ORGANIZATIONS}/${id}`}
      numberOfElements={1}
      fullWidth={false}
    >
      <OrganizationForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default OrganizationEdit
