import { alert } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { ProcedureInputType } from 'types/procedures'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import ProcedureForm from 'components/Procedures/ProcedureForm'
import { ProceduresFields } from 'components/Procedures/utils'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { ProcedureInputSchema } from 'schemas/procedure'

const NewProcedure = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const form = useFormManager<ProcedureInputType>({
    defaultValues: {
      [ProceduresFields.IS_ACTIVE]: true,
      [ProceduresFields.POST_PREPARATION]: null,
      [ProceduresFields.PRE_PREPARATION]: null,
      [ProceduresFields.CRM_CODE]: null,
    },
    schema: ProcedureInputSchema,
    onCancel: () => navigate(RootPath.PROCEDURES),
    onSubmit: async data => {
      await api.addProcedure(data)
      navigate(RootPath.PROCEDURES)
      alert.success(
        `${t('Success')}!`,
        `${data.name} ${t('was added successfully')}.`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate title={t('Add New Procedure')} goBackTo={RootPath.PROCEDURES}>
      <ProcedureForm {...form} />
    </PageTemplate>
  )
}

export default NewProcedure
