import { styled } from '@weareredlight/design-system'

export const StyledPageTemplate = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '73px',

  main: {
    padding: '$xxlg',
    '@md': {
      padding: '$lg',
    },
    '@sm': {
      padding: '$sm',
      marginTop: '65px'
    },
  }
})

export const StyledPageHeader = styled('section', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$xxlg $xxlg $lg $xxlg',

  '@md': {
    padding: '$lg',
  },
  '@sm': {
    padding: '$sm',
    top: '65px',
  },
  '@xlg': {
    maxWidth: '1600px',
    margin: '0 auto'
  },

  'a': {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '$xxxsm',
    color: '$accent',
  }
})

export const StyledPageBody = styled('section', {
  display: 'grid',
  alignItems: 'start',
  gap: '$xxlg',
  padding: '$xsm $xxlg $xxlg',

  '@md': {
    display: 'flex',
    flexDirection: 'column',
    padding: '$lg',
    '>div': {
      width: '100%',
    },
  },
  '@sm': {
    minWidth: '100%',
    padding: '$sm',
  },
  '@xlg': {
    maxWidth: '1600px',
    margin: '0 auto'
  },

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
      false: {
        width: 'fit-content',
        margin: '0 auto'
      }
    },
  },

  defaultVariants: {
    fullWidth: true,
  },
})
