export enum UserRoles {
  SYSTEM_ADMINISTRATOR = 'SystemAdministrator',
  SCHEDULING_ADMINISTRATOR = 'SchedulingAdministrator',
  EXECUTIVE_USER = 'ExecutiveUser',
  STAFF_USER = 'StaffUser',
}

export enum Languages {
  ENGLISH = 'en-US',
  SPANISH = 'es-ES',
}

export enum LanguageDetails {
  'en-US' = 'English (US)',
  'es-ES' = 'Spanish (Spain)',
}

export enum FrequencyDigest {
  NEVER = 'Never',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export enum ScheduleViews {
  CALENDAR = 'Calendar',
  LIST = 'List',
}

export enum CalendarViews {
  WORK_WEEK = 'Work_Week',
  DAY = 'Day',
}

export enum WeekDays {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
}

export enum Certification {
  DOCTOR = 'Doctor',
  NURSE = 'Nurse',
  RADIOLOGY_SPECIALIST = 'RadiologySpecialist',
  PHYSICAL_THERAPY = 'PhysicalTherapy',
  PATIENT_CONCIERGE = 'PatientConcierge',
  MASOTHERAPEUTIST = 'Masotherapeutist',
}

export enum Specialities {
  CARDIOLOGY = 'Cardiology',
  GASTROENTEROLOGY = 'Gastroenterology',
  NUTRITION = 'Nutrition',
  NEUROLOGIST = 'Neurologist',
  NEUROSURGERY = 'Neurosurgery',
  INTERNAL_MEDICINE = 'InternalMedicine',
  ANESTHESIOLOGIST = 'Anesthesiologist',
  UROLOGIST = 'Urologist',
  NEUROPSYCHOLOGY = 'Neuropsychology',
  PSYCHOLOGY = 'Psychology',
  SPORTS_MEDICINE = 'SportsMedicine',
  ALTERNATIVE_MEDICINE = 'AlternativeMedicine',
  GENERAL_PHYSICIAN = 'GeneralPhysician',
  CCP_PHYSICIAN = 'CCPPhysician',
  EPIDEMIOLOGIST = 'Epidemiologist',
  DERMATOLOGIST = 'Dermatologist',
  NEUROPEDIATRICIAN = 'Neuropediatrician',
}

export enum TreatmentStatuses {
  NEW = 'New',
  TO_BE_SCHEDULED = 'ToBeScheduled',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'InProgress',
  DISCHARGED = 'Discharged',
  CANCELLED = 'Cancelled',
}

export enum CancellationOrders {
  MEDICAL_TEAM = 'MedicalTeam',
  PATIENT = 'Patient',
}

export enum CancellationTimes {
  LESS_45 = 'LessThen45Days',
  MORE_45 = 'MoreOrEqualTo45Days',
}

export enum CancellationReasons {
  COVID_19 = 'Covid19',
  UTI = 'UTI',
  PATIENT_DECISION = 'PatientDecision',
  QX_PROCEDURE = 'QXProcedure',
  MEDICAL_REASON = 'MedicalReason',
  CANCER_DIAGNOSIS = 'CancerDiagnosis',
  DEATH = 'Death',
  BALANCE_PAYMENT_DUE = 'BalancePaymentDue',
}

export enum AssetTypes {
  PROVIDER_SCHEDULES = 'ProviderSchedules',
}

export enum DocumentType {
  NONE = 'None',
  BIRTH_CERTIFICATE = 'BirthCertificate',
  CERTIFICATE_OF_LIVE_BIRTH = 'CertificateOfLiveBirth',
  DIPLOMATIC_ID = 'DiplomaticID',
  FOREIGN_DOCUMENT = 'ForeignDocument',
  FOREIGNER_ID = 'ForeignerID',
  IDENTITY_CARD = 'IdentityCard',
  NATIONAL_ID = 'NationalID',
  PASSPORT = 'Passport',
  SAFE_CONDUCT = 'SafeConduct',
  SPECIAL_RESIDENT_FOR_PEACE = 'SpecialResidentForPeace',
  SPECIAL_STAY_PERMIT = 'SpecialStayPermit',
  TAX_ID_NUMBER = 'TaxIDNumber',
  TEMPORARY_PROTECTION = 'TemporaryProtection',
  UN_PASSPORT = 'UNPassport',
  UNIDENTIFIED_ADULT = 'UnidentifiedAdult',
  UNIDENTIFIED_MINOR = 'UnidentifiedMinor',
  UNIQUE_PERSONAL_IDENTIFICATION_NUMBER = 'UniquePersonalIdentificationNumber',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  DECLINE_TO_SPECIFY = 'DeclineToSpecify',
  OTHER = 'Other',
}

export enum PatientStage {
  DEPOSIT_OR_PAID_IN_FULL = 'DepositOrPaidInFull',
  AWAITING_PATIENT_ITINERARY = 'AwaitingPatientItinerary',
  COORDINATING_PATIENT_LOGISTICS = 'CoordinatingPatientLogistics',
  AWAITING_TREATMENT = 'AwaitingTreatment',
  TREATMENT_COMPLETE = 'TreatmentComplete',
}

export enum PatientTypeEnum {
  REGULAR = 'Regular',
  EMPLOYEE = 'Employee',
  INFLUENCER = 'Influencer',
  RELATIVE = 'Relative',
  COMARKETER = 'CoMarketer',
  HCP = 'HCP',
  COMPANION = 'Companion',
  REGULAR_COMPANION = 'RegularCompanion',
}

export enum ProcedureProductSpecialityEnum {
  ALTERNATIVE_MEDICINE = 'AlternativeMedicine',
  ALTERNATIVE_SPECIALIST = 'AlternativeSpecialist',
  ANESTESIOLOGY = 'Anestesiology',
  BLOOD_TEST = 'BloodTest',
  CARDIOLOGY = 'Cardiology',
  CCP = 'CCP',
  CONCIERGE = 'Concierge',
  COMPUTED_TOMOGRAPHY = 'ComputedTomography',
  DERMA_URO_GINE = 'DermaUroGine',
  DERMATOLOGY = 'Dermatology',
  GASTROENTEROLOGY = 'Gastroenterology',
  GENERAL_MEDICINE = 'GeneralMedicine',
  GENERAL_PRACTICE = 'GeneralPractice',
  GYNECOLOGY = 'Gynecology',
  INTERNAL_MEDICINE = 'InternalMedicine',
  INTRAVENOUS = 'Intravenous',
  LABORATORY = 'Laboratory',
  NEUROLOGICAL = 'Neurological',
  NEUROLOGY = 'Neurology',
  NEUROPSYCHOLOGY = 'Neuropsychology',
  NEUROSURGERY = 'Neurosurgery',
  NUTRITIONIST = 'Nutritionist',
  OSTEOPATHIC = 'Osteopathic',
  OSTEOPATHY = 'Osteopathy',
  PEDIATRIC_NEUROLOGY = 'PediatricNeurology',
  PHYSICAL_THERAPY = 'PhysicalTherapy',
  RADIOLOGY = 'Radiology',
  REGENERATIVE_MEDICINE = 'RegenerativeMedicine',
  SPIROMETRY = 'Spirometry',
  SPORTS_ORTHO = 'SportsOrtho',
  ULTRASOUND = 'Ultrasound',
  URODINAMIA = 'Urodinamia',
  UROLOGY = 'Urology',
  X_RAY = 'XRay',
}

export enum ProcedureMedicalServiceTypeEnum {
  COMPLEMENTARY_THERAPY = 'ComplementaryTherapy',
  CONSULTS = 'Consults',
  DIAGNOSTIC_AIDS = 'DiagnosticAids',
  DISCOUNTS = 'Discounts',
  EXOSOMES = 'Exosomes',
  EXO_HX_CELLS = 'ExoHXCells',
  HYPOXIC_CELLS = 'HypoxicCells',
  LOGISTICS = 'Logistics',
  NORMOXIC_CELLS = 'NormoxicCells',
  NUMBER_OF_CELLS = 'NumberOfCells',
  NUMBER_OF_HYPOXIC = 'NumberOfHypoxic',
  PROCEDURES = 'Procedures',
  PROFESSIONAL_SERVICES = 'ProfessionalServices',
}

export enum ProcedureTypeEnum {
  BIO_X_GEL = 'BioXgel',
  CONSULT = 'Consult',
  CRYOTHERAPY = 'Cryotherapy',
  DIAGNOSTIC_TEST = 'DiagnosticTest',
  EPIGENETIC = 'Epigenetic',
  FACETS = 'Facets',
  HYPERBARIC_OXYGEN_THERAPY = 'HyperbaricOxygenTherapy',
  IMAGES = 'Images',
  INTRA_ARTICULAR = 'IntraArticular',
  INTRA_CAVERNOUS = 'IntraCavernous',
  INTRA_DERMAL = 'IntraDermal',
  INTRA_DISCAL = 'IntraDiscal',
  INTRA_FACET = 'IntraFacet',
  INTRA_MUSCULAR = 'IntraMuscular',
  INTRA_MUSCULAR_PELVIC_FLOOR = 'IntraMuscularPelvicFloor',
  INTRA_PERINEURAL = 'IntraPerineural',
  INTRA_PROSTATIC = 'IntraProstatic',
  INTRA_TESTICULAR = 'IntraTesticular',
  INTRA_THECAL = 'IntraThecal',
  INTRA_TENDINOUS_INTRALIGAMENT = 'IntratendinosusIntraligament',
  INTRA_VAGINAL = 'IntraVaginal',
  INTRA_VESICAL_INFILTRATION = 'IntraVesicalInfiltration',
  INTRA_VESICAL_IRRIGATION = 'IntraVesicalIrrigation',
  INTRAVENOUS = 'Intravenous',
  IV_CELLS = 'IVCells',
  IV_DRIPS = 'IVDrips',
  LAB_TEST = 'LabTest',
  MASSAGES = 'Massages',
  NEBULIZED = 'Nebulized',
  NOT_APPLICABLE = 'NotApplicable',
  OPEN_MRI = 'OpenMRI',
  OZONE_THERAPY = 'OzoneTherapy',
  PERINEURAL = 'Perineural',
  PERINEURAL_APPLICATION = 'PerineuralApplication',
  PERINEURAL_PUDENDAL_NERVE = 'PerineuralPudendalNerve',
  PERINEURAL_PUDENDAL_NERVE_BILATERAL = 'PerineuralPudendalNerveBilateral',
  PERI_OSEO = 'PeriOseo',
  TMJ_APPLICATION = 'TmjApplication',
}

export enum ProcedureProviderTypeEnum {
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
}

export enum ProcedurePreparationTypeEnum {
  ADVANCE = 'Advance',
  BASIC = 'Basic',
  BASIC_MASSAGE = 'BasicMassage',
  NONE = 'None',
  PART_OF_FACETS = 'PartOfFacets',
}

export enum ProcedureStatuses {
  NOT_SCHEDULED = 'NotScheduled',
  SCHEDULED = 'Scheduled',
  CANCELLED = 'Cancelled',
}
