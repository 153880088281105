import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import { Button, Flex, Input, Text } from '@weareredlight/design-system'
import { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NewProviderFields, ProceduresFields, newProvider } from './utils'

import { ControlledSelect } from 'components/Form'
import { Certification, Specialities } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'

const ProcedureProviderForm = () => {
  const { t } = useTranslation()
  const form = useFormContext()

  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: ProceduresFields.REQUIRED_PROVIDERS,
  })

  const currentProviders = form.watch(ProceduresFields.REQUIRED_PROVIDERS)

  const isDoctor = useCallback(
    (index: number) =>
      currentProviders[index]?.certification === Certification.DOCTOR,
    [currentProviders],
  )

  return (
    <Flex direction="column" align="start" gap="xlg" className="form-content">
      <Text variant="h3" color="accent">
        {t('Required Providers')}
      </Text>
      {fields.map((provider, index) => (
        <Flex key={provider.id} align="start" gap="sm" css={{ width: '100%' }}>
          <Flex
            align="start"
            gap="sm"
            css={{ width: '100%', '@md': { flexDirection: 'column' } }}
          >
            <ControlledSelect
              name={`${ProceduresFields.REQUIRED_PROVIDERS}.${index}.${NewProviderFields.CERTIFICATION}`}
              options={enum2SelectOptions(Certification)}
              placeholder={t('Select certification')}
              fullWidth
            />
            {isDoctor(index) && (
              <ControlledSelect
                name={`${ProceduresFields.REQUIRED_PROVIDERS}.${index}.${NewProviderFields.SPECIALITY}`}
                options={enum2SelectOptions(Specialities)}
                placeholder={t('Select specialities')}
                fullWidth
              />
            )}
          </Flex>
          <Flex
            align="start"
            gap="sm"
            css={{ '@md': { flexDirection: 'column' } }}
          >
            <Input
              {...form.register(
                `${ProceduresFields.REQUIRED_PROVIDERS}.${index}.${NewProviderFields.COUNT}`,
              )}
              type="number"
              placeholder={t('Enter providers count')}
              iconPosition="left"
              containerProps={{ style: { minWidth: 50, width: 50 } }}
              fullWidth
            />
            <Button
              variant="textOnly"
              iconPosition="iconOnly"
              onClick={() => remove(index)}
              iconComponent={() => <Cross1Icon />}
            />
          </Flex>
        </Flex>
      ))}
      <Button
        type="button"
        onClick={() => append(newProvider)}
        iconComponent={() => <PlusIcon />}
      >
        {t('Add New Provider')}
      </Button>
    </Flex>
  )
}

export default ProcedureProviderForm
