import * as yup from 'yup'

import type { UserInputType } from 'types/users'

import { UserFields } from 'components/Users/UserForm'
import i18next from 'i18n'
import { Languages } from 'utils/enums'

export const UserInputSchema: yup.Schema<UserInputType> = yup.object().shape({
  [UserFields.FIRST_NAME]: yup
    .string()
    .required(i18next.t('First name is required')),
  [UserFields.LAST_NAME]: yup
    .string()
    .required(i18next.t('Last name is required')),
  [UserFields.EMAIL]: yup
    .string()
    .email(i18next.t('Email format is incorrect'))
    .required(i18next.t('Email is required')),
  [UserFields.ACTIVE]: yup.boolean().required(i18next.t('Active is required')),
  [UserFields.ROLE]: yup.string().required(i18next.t('Role is required')),
  [UserFields.LANGUAGE_CODE]: yup
    .mixed<Languages>()
    .oneOf(Object.values(Languages))
    .required(i18next.t('Language is required')),
})
