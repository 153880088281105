import { Checkbox, Flex, Label, Text } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { OptionType } from 'types/types'

export type ControlledCheckboxesType = {
  name: string
  label?: string
  options: OptionType[]
  noOptionsMessage?: string
  error?: string
}

export const ControlledCheckboxes = ({
  name,
  label,
  options,
  noOptionsMessage,
  error,
}: ControlledCheckboxesType) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const handleChange = (
    value: string[],
    newValue: string,
    onChange: (input: string[]) => void,
  ) => {
    if (value?.includes(newValue)) {
      onChange(value.filter(day => day !== newValue))
    } else {
      onChange([...(value || []), newValue])
    }
  }

  if (!options?.length && noOptionsMessage) {
    return (
      <Text variant="microCopy" color="neutral">
        {noOptionsMessage}
      </Text>
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Flex direction="column" align="start" gap="xxsm">
          {label && <Label id={name} description={label} />}
          <Flex justify="start" gap="xsm" wrap>
            {options?.map(item => (
              <Checkbox
                key={item.value}
                id={item.value}
                value={item.value}
                label={t(item.label)}
                checked={value?.includes(item.value)}
                onChange={e => handleChange(value, e.target.value, onChange)}
              />
            ))}
          </Flex>
          {error && (
            <Text color="danger" variant="microCopy">
              {String(error)}
            </Text>
          )}
        </Flex>
      )}
    />
  )
}
