import { alert } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { PatientInputType, PatientType } from 'types/patients'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import PatientForm from 'components/Patients/PatientForm'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { PatientInputSchema } from 'schemas/patient'
import { getName } from 'utils/text'

const PatientEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const {
    data: patient,
    doRequest: getPatient,
    isLoading,
  } = useRequest<PatientType, { id: string }>(api.getPatient)

  const form = useFormManager<PatientInputType>({
    defaultValues: patient || {},
    enableReinitialize: true,
    schema: PatientInputSchema,
    onCancel: () => navigate(`${RootPath.PATIENTS}/${id}`),
    onSubmit: async input => {
      if (id) {
        await api.updatePatient({ id, input })
        navigate(`${RootPath.PATIENTS}/${id}`)
        alert.success(
          `${t('Success')}!`,
          `${getName(input)} ${t('details edited successfully')}.`,
          { position: 'bottom-right' },
        )
      }
    },
  })

  useEffect(() => {
    if (id) getPatient({ id })
  }, [getPatient, id])

  return (
    <PageTemplate
      title={t('Edit Patient')}
      goBackTo={`${RootPath.PATIENTS}/${id}`}
      numberOfElements={1}
      fullWidth={false}
    >
      <PatientForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default PatientEdit
