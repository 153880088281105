import { PlusIcon } from '@radix-ui/react-icons'
import { Button, Flex, Text } from '@weareredlight/design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { RoomType, RoomParamsType } from 'types/rooms'

import api from 'api/api'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath, RoomsPath } from 'router/enums'
import { roomsColumns, roomsDefaultSortees } from 'utils/tables/rooms'

const RoomsList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const params = useMemo(
    () => ({
      organizationId: id || '',
    }),
    [id],
  )

  const { tableData, ...rest } = useTableRequestState<RoomType, RoomParamsType>(
    {
      requestFunc: api.getRooms,
      pageSize: 10,
      params,
      sortees: roomsDefaultSortees,
    },
  )

  return (
    <Flex direction="column" gap="xxlg" css={{ padding: '1.5rem' }}>
      <Flex justify="spaceBetween" css={{ width: '100%' }}>
        <Text variant="h3" color="accent">
          {t('Rooms')}
        </Text>
        <Button
          variant="tertiary"
          iconComponent={() => <PlusIcon />}
          onClick={() => navigate(`${RoomsPath.ROOMS}/${GenericPath.NEW}`)}
        >
          {t('Add Room')}
        </Button>
      </Flex>
      <Table<RoomType>
        data={tableData?.data || []}
        columns={roomsColumns}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </Flex>
  )
}

export default RoomsList
