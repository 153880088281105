import type { ColumnDef } from '@tanstack/react-table'
import type {
  OrganizationTreatmentParamsType,
  PatientTreatmentParamsType,
  TreatmentsType,
} from 'types/treatments'

import api from 'api/api'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'

type TreatmentsListProps = {
  columns: ColumnDef<TreatmentsType>[]
  onRowClick?: (data: TreatmentsType) => void
  params: PatientTreatmentParamsType | OrganizationTreatmentParamsType
}

const TreatmentsList = ({
  params,
  columns,
  onRowClick,
}: TreatmentsListProps) => {
  const { tableData, ...rest } = useTableRequestState<
    TreatmentsType,
    PatientTreatmentParamsType | OrganizationTreatmentParamsType
  >({
    requestFunc: api.getTreatments,
    params,
  })

  return (
    <Table<TreatmentsType>
      data={tableData?.data || []}
      columns={columns}
      totalCount={tableData?.count || 0}
      totalPages={tableData?.pageCount || 0}
      onRowClick={onRowClick}
      {...rest}
    />
  )
}

export default TreatmentsList
