import { alert } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { UserInputType, UserType } from 'types/users'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import UserForm from 'components/Users/UserForm'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RootPath } from 'router/enums'
import { UserInputSchema } from 'schemas/user'
import { getName } from 'utils/text'

const UserEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const {
    data: user,
    doRequest: getUser,
    isLoading,
  } = useRequest<UserType, { id: string }>(api.getUser)

  const form = useFormManager<UserInputType>({
    defaultValues: user || {},
    enableReinitialize: true,
    schema: UserInputSchema,
    onCancel: () => navigate(`${RootPath.USERS}/${id}`),
    onSubmit: async input => {
      if (id) {
        await api.updateUser({ id, input })
        alert.success(
          `${t('Success')}!`,
          `${getName(input)} ${t('details edited successfully')}.`,
          { position: 'bottom-right' },
        )
        navigate(`${RootPath.USERS}/${id}`)
      }
    },
  })

  useEffect(() => {
    if (id) getUser({ id })
  }, [getUser, id])

  return (
    <PageTemplate
      title={t('Edit User')}
      goBackTo={`${RootPath.USERS}/${id}`}
      numberOfElements={1}
      fullWidth={false}
    >
      <UserForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default UserEdit
