/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify'
import axios from 'axios'

import type {
  AppointmentInputType,
  AppointmentsParams,
  OrganizationAppointmentsParams,
} from 'types/appointments'
import type { OrganizationInputType } from 'types/organizations'
import type { PatientInputType } from 'types/patients'
import type { ProcedureInputType } from 'types/procedures'
import type {
  ProviderInputType,
  ProviderParamsType,
  ProviderScheduleInputType,
} from 'types/providers'
import type { RoomInputType, RoomParamsType } from 'types/rooms'
import type {
  PatientTreatmentParamsType,
  ResourcesParamsType,
  TreatmentCancellationType,
  TreatmentInputType,
  TreatmentProcedureInputType,
} from 'types/treatments'
import type { AssetType, RequestParams } from 'types/types'
import type { UserInputType } from 'types/users'

import { parseParams } from 'utils/api'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 150000,
})

api.interceptors.request.use(async (config: any) => {
  try {
    const session: any = await Auth.currentSession()
    const {
      accessToken: { jwtToken },
    } = session

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  } catch (_e) {
    // If the session is invalid, do not add an Authorization header
    return config
  }
})

const apiConfig = {
  //USERS
  getCurrentUser() {
    return api.get('users/me')
  },
  updateCurrentUser({ input }: { input: UserInputType }) {
    return api.put('users/me', input)
  },
  getUsers(params: RequestParams) {
    return api.get('users', { params })
  },
  getUser({ id }: { id: string }) {
    return api.get(`users/${id}`)
  },
  addUser(input: UserInputType) {
    return api.post('users', input)
  },
  updateUser({ id, input }: { id: string; input: UserInputType }) {
    return api.put(`users/${id}`, input)
  },

  //PROCEDURES
  getProcedures(params: RequestParams | void) {
    return api.get('procedures', { params })
  },
  getProcedure({ id }: { id: string }) {
    return api.get(`procedures/${id}`)
  },
  addProcedure(input: ProcedureInputType) {
    return api.post('procedures', input)
  },
  updateProcedure({ id, input }: { id: string; input: ProcedureInputType }) {
    return api.put(`procedures/${id}`, input)
  },

  //ORGANIZATIONS
  getOrganizations(params: RequestParams | void) {
    return api.get('organizations', { params })
  },
  getOrganization({ id }: { id: string }) {
    return api.get(`organizations/${id}`)
  },
  addOrganization(input: OrganizationInputType) {
    return api.post('organizations', input)
  },
  updateOrganization({
    id,
    input,
  }: {
    id: string
    input: OrganizationInputType
  }) {
    return api.put(`organizations/${id}`, input)
  },

  //PROVIDERS
  getProviders(
    params: (RequestParams & ProviderParamsType) | ProviderParamsType,
  ) {
    return api.get('providers', { params })
  },
  getProvider({ id }: { id: string }) {
    return api.get(`providers/${id}`)
  },
  addProvider(input: ProviderInputType) {
    return api.post('providers', input)
  },
  updateProvider({ id, input }: { id: string; input: ProviderInputType }) {
    return api.put(`providers/${id}`, input)
  },
  getProviderSchedule(params: { providerId: string }) {
    return api.get('provider-schedules', { params })
  },
  addProviderSchedule(params: { assetId: string; providerId: string }) {
    return api.post('provider-schedules/schedules', null, { params })
  },
  addProviderShift(input: ProviderScheduleInputType) {
    return api.post('provider-schedules', input)
  },
  deleteProviderShift(id: string) {
    return api.delete('provider-schedules', {
      data: { providerScheduleId: id },
    })
  },
  updateProviderShift({
    id,
    input,
  }: {
    id: string
    input: ProviderScheduleInputType
  }) {
    return api.put(`provider-schedules/${id}`, input)
  },
  getShiftTemplate() {
    return api.get('provider-schedules/template')
  },

  //PATIENTS
  getPatients(params: RequestParams) {
    return api.get('patients', { params })
  },
  getPatient({ id }: { id: string }) {
    return api.get(`patients/${id}`)
  },
  addPatient(input: PatientInputType) {
    return api.post('patients', input)
  },
  updatePatient({ id, input }: { id: string; input: PatientInputType }) {
    return api.put(`patients/${id}`, input)
  },

  //REPORTS
  getDashboard() {
    return api.get('dashboards/dashboard')
  },

  //ROOMS
  getRooms(params: (RequestParams & RoomParamsType) | RoomParamsType) {
    return api.get('rooms', { params })
  },
  getRoom({ id }: { id: string }) {
    return api.get(`rooms/${id}`)
  },
  addRoom(input: RoomInputType) {
    return api.post('rooms', input)
  },
  updateRoom({ id, input }: { id: string; input: RoomInputType }) {
    return api.put(`rooms/${id}`, input)
  },

  //TREATMENTS
  getTreatments(params: RequestParams | PatientTreatmentParamsType) {
    return api.get('treatments', { params })
  },
  getTreatment({ id }: { id: string }) {
    return api.get(`treatments/${id}`)
  },
  addTreatment(input: TreatmentInputType) {
    return api.post('treatments', input)
  },
  updateTreatment({ id, input }: { id: string; input: TreatmentInputType }) {
    return api.put(`treatments/${id}`, input)
  },
  updateTreatmentProcedures({
    id,
    input,
  }: {
    id: string
    input: TreatmentProcedureInputType
  }) {
    return api.put(`treatments/${id}/procedures`, input)
  },
  cancelTreatmentProcedure(id: string, treatmentProcedureId: string) {
    return api.post(`treatments/${id}/procedures/cancel`, {
      treatmentProcedureId,
    })
  },
  restoreTreatmentProcedure(id: string, treatmentProcedureId: string) {
    return api.post(`treatments/${id}/procedures/restore`, {
      treatmentProcedureId,
    })
  },
  scheduleAssistTreatment(id: string) {
    return api.post(`treatments/${id}/schedule`)
  },
  getResources({ id, procedureId, organizationId }: ResourcesParamsType) {
    return api.get(`treatments/${id}/procedures/${procedureId}/resources`, {
      params: { organizationId },
    })
  },
  cancelTreatment({
    id,
    input,
  }: {
    id: string
    input: TreatmentCancellationType
  }) {
    return api.put(`treatments/${id}/cancel`, input)
  },
  downloadTreatment({ id }: { id: string }) {
    return api.get(`treatments/${id}/pdf`, { responseType: 'blob' })
  },

  //APPOINTMENTS
  getAppointments(params: AppointmentsParams | OrganizationAppointmentsParams) {
    const serializedParams = parseParams<
      AppointmentsParams | OrganizationAppointmentsParams
    >(params)
    const requestOptions = {
      params: params,
      paramsSerializer: () => serializedParams,
    }
    return api.get('appointments', requestOptions)
  },
  getAppointment({ id }: { id: string }) {
    return api.get(`appointments/${id}`)
  },
  addAppointment(input: AppointmentInputType) {
    return api.post('appointments', input)
  },
  updateAppointment({
    id,
    input,
  }: {
    id: string
    input: AppointmentInputType
  }) {
    return api.put(`appointments/${id}`, input)
  },
  exportAppointments({ params }: { params?: OrganizationAppointmentsParams }) {
    return api.get('export/appointments', {
      params,
      paramsSerializer: params => parseParams(params),
    })
  },

  //ASSETS
  createAsset(input: AssetType) {
    return api.post('assets', input)
  },
  uploadFile(url: string, file: File) {
    return api.put(url, file)
  },
}

export default apiConfig
