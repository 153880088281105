import { Pill } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'
import { TreatmentStatuses } from 'utils/enums'
import { splitCamelCase } from 'utils/text'

type StatusTagProps = {
  status: TreatmentStatuses
}

const StatusTag = ({ status }: StatusTagProps) => {
  const { t } = useTranslation()

  const statusColor = {
    [TreatmentStatuses.NEW]: colors.statusNew,
    [TreatmentStatuses.TO_BE_SCHEDULED]: colors.statusToBeScheduled,
    [TreatmentStatuses.SCHEDULED]: colors.statusScheduled,
    [TreatmentStatuses.IN_PROGRESS]: colors.statusInProgress,
    [TreatmentStatuses.DISCHARGED]: colors.statusDischarged,
    [TreatmentStatuses.CANCELLED]: colors.statusCancelled,
  }

  return (
    <Pill
      css={{
        backgroundColor: statusColor[status],
        span: {
          color: statusColor[status],
          filter: 'brightness(0.5)',
        },
      }}
    >
      <span>{t(splitCamelCase(status))}</span>
    </Pill>
  )
}

export default StatusTag
