import { Flex, Pill, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { TreatmentCancellationType } from 'types/treatments'
import type { CancellationTimes } from 'utils/enums'

import { TreatmentCancellationInfo } from './treatments.styles'
import { TreatmentCancellationFields } from './utils'

import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import { cancellationTimeLabels } from 'components/Treatments/TreatmentCancellation'
import { splitCamelCase } from 'utils/text'

type CancelledTreatmentProps = {
  cancellationReason: TreatmentCancellationType
}

const CancellationReasons = ({
  cancellationReason,
}: CancelledTreatmentProps) => {
  const { t } = useTranslation()

  const reasonKeys = Object.keys(
    TreatmentCancellationFields,
  ) as (keyof typeof TreatmentCancellationFields)[]
  return (
    <Card extraClasses={TreatmentCancellationInfo()}>
      <Flex direction="column" align="start" gap="lg">
        <Text variant="h4" color="primary700">
          {t('Cancellation Information')}
        </Text>
        <div className="cancellation-details">
          {reasonKeys.map((key, index) => {
            const reasonTitle = TreatmentCancellationFields[key]
            const reasonContent =
              cancellationReason?.[TreatmentCancellationFields[key]]

            return (
              <EntityDetail
                label={splitCamelCase(`${reasonTitle}`)}
                key={index}
              >
                {typeof reasonContent !== 'boolean' ? (
                  reasonTitle === TreatmentCancellationFields.TIME ? (
                    cancellationTimeLabels[reasonContent as CancellationTimes]
                  ) : (
                    t(splitCamelCase(`${reasonContent}`))
                  )
                ) : (
                  <Pill variant={reasonContent ? 'success' : 'error'}>
                    {reasonContent ? t('Yes') : t('No')}
                  </Pill>
                )}
              </EntityDetail>
            )
          })}
        </div>
      </Flex>
    </Card>
  )
}

export default CancellationReasons
