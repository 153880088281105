import { alert, Flex, Modal } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { TreatmentCancellationType, TreatmentType } from 'types/treatments'

import { TreatmentCancellationFields } from './utils'

import api from 'api/api'
import Card from 'components/Card'
import {
  Form,
  ControlledRadioButtons,
  ControlledToggle,
  ControlledSelect,
  DialogFormWrapper,
} from 'components/Form'
import { useFormManager } from 'hooks/useFormManager'
import i18next from 'i18n'
import { TreatmentCancellationSchema } from 'schemas/treatmentCancellation'
import {
  CancellationOrders,
  CancellationReasons,
  CancellationTimes,
} from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { cancellationReasonsLabels } from 'utils/labels'

type TreatmentCancellationProps = {
  isOpen: boolean
  handleOpen: (isOpen: boolean) => void
  treatment: TreatmentType
  refreshData: () => void
}

export const cancellationTimeLabels = {
  [CancellationTimes.LESS_45]: `< 45 ${i18next.t('Days')}`,
  [CancellationTimes.MORE_45]: `> 45 ${i18next.t('Days')}`,
}

const TreatmentCancellation = ({
  isOpen,
  handleOpen,
  treatment,
  refreshData,
}: TreatmentCancellationProps) => {
  const { t } = useTranslation()

  const form = useFormManager<TreatmentCancellationType>({
    enableReinitialize: true,
    defaultValues: {
      [TreatmentCancellationFields.HAS_FEE]: false,
      [TreatmentCancellationFields.IS_RESCHEDULED]: false,
    },
    schema: TreatmentCancellationSchema,
    onCancel: () => {
      form.form.reset()
      handleOpen(false)
    },
    onSubmit: async input => {
      await api.cancelTreatment({ id: treatment.id, input })
      handleOpen(false)
      refreshData()
      alert.success(
        `${t('Success')}!`,
        t('Treatment has been cancelled successfully.'),
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <Modal
      open={isOpen}
      title={t('Cancel Treatment')}
      closeFn={() => {
        handleOpen(false)
      }}
      extraClasses={DialogFormWrapper()}
    >
      <Card extraClasses="wrapper">
        <Form<TreatmentCancellationType> {...form}>
          <Flex gap="xxlg" align="start">
            <ControlledRadioButtons
              name={`${TreatmentCancellationFields.ORDER}`}
              options={enum2SelectOptions(CancellationOrders)}
              label={t('Cancellation order')}
              error={
                form.form.formState.errors[TreatmentCancellationFields.ORDER]
                  ?.message
              }
            />
            <ControlledRadioButtons
              name={`${TreatmentCancellationFields.TIME}`}
              options={enum2SelectOptions(
                CancellationTimes,
                cancellationTimeLabels,
              )}
              label={t('Time cancellation')}
              error={
                form.form.formState.errors[TreatmentCancellationFields.TIME]
                  ?.message
              }
            />
          </Flex>
          <ControlledSelect
            name={`${TreatmentCancellationFields.REASON}`}
            options={enum2SelectOptions(
              CancellationReasons,
              cancellationReasonsLabels,
            )}
            label={t('Cancellation reason')}
            placeholder={t('Enter cancellation reason')}
            fullWidth
            state={
              form.form.formState.errors[TreatmentCancellationFields.REASON]
                ? 'error'
                : 'null'
            }
          />
          <Flex gap="xxlg" css={{ marginTop: '$xsm', zIndex: 2 }} wrap>
            <ControlledToggle
              name={TreatmentCancellationFields.HAS_FEE}
              label={t('Cancellation fee')}
            />
            <ControlledToggle
              name={TreatmentCancellationFields.IS_RESCHEDULED}
              label={t('Rescheduled')}
            />
          </Flex>
        </Form>
      </Card>
    </Modal>
  )
}

export default TreatmentCancellation
