import { Avatar } from '@weareredlight/design-system'
import capitalize from 'lodash/capitalize'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import { StyledNavbar } from './navbar.styles'

import logo from 'assets/img/logo-white.svg'
import { RootPath } from 'router/enums'
import { PermissionRequiredBoundary, RootPathPerms } from 'router/routes'
import { useCurrentUser } from 'userContext'
import { getName } from 'utils/text'

const navbarRoutes = [
  RootPath.REPORTS,
  RootPath.USERS,
  RootPath.ORGANIZATIONS,
  RootPath.PROCEDURES,
  RootPath.PATIENTS,
  RootPath.SCHEDULE,
]

const Navbar = () => {
  const { t } = useTranslation()
  const { currentUserData, isLoading } = useCurrentUser()

  return (
    <StyledNavbar>
      <div>
        <Link to="/">
          <img src={logo} alt="BioXcellerator logo" />
        </Link>
        {!isLoading && (
          <ul>
            {navbarRoutes.map(path => {
              return (
                <PermissionRequiredBoundary
                  allowed={RootPathPerms[path]}
                  key={path}
                >
                  <li>
                    <NavLink
                      to={path}
                      className={({ isActive }) => {
                        return isActive ? 'activeLink' : ''
                      }}
                    >
                      {t(capitalize(path.substring(1)))}
                    </NavLink>
                  </li>
                </PermissionRequiredBoundary>
              )
            })}
            <li>
              <Link to={RootPath.PROFILE} className="profileLink">
                <Avatar name={getName(currentUserData)} size="small" />
              </Link>
            </li>
          </ul>
        )}
      </div>
    </StyledNavbar>
  )
}

export default Navbar
