import { yupResolver } from '@hookform/resolvers/yup'
import { alert } from '@weareredlight/design-system'
import { AxiosError } from 'axios'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useForm } from 'react-hook-form'

import type { DefaultValues, UseFormReturn, FieldValues } from 'react-hook-form'
import type { Schema, AnyObjectSchema } from 'yup'

export type UseFormManagerOptions<T> = {
  defaultIsEditing?: boolean
  defaultValues?: DefaultValues<T>
  schema?: AnyObjectSchema | Schema<T>
  onEdit?: () => void
  onCancel?: () => void
  onSubmit?: (values: T) => void
  enableReinitialize?: boolean
}

export type FormState = {
  isEditing: boolean
  isSubmitting: boolean
}

export type FormActions = {
  edit: () => void
  submit: () => void
  cancel: () => void
}

export type UseFormManagerReturn<T extends FieldValues> = {
  form: UseFormReturn<T>
  formState: FormState
  formActions: FormActions
}

export function useFormManager<T extends FieldValues>({
  schema,
  defaultValues,
  defaultIsEditing = true,
  onEdit,
  onCancel,
  onSubmit,
  enableReinitialize,
}: UseFormManagerOptions<T>): UseFormManagerReturn<T> {
  const [isEditing, setIsEditing] = React.useState(defaultIsEditing)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const form = useForm<T>({
    defaultValues,
    resolver: schema && yupResolver(schema as AnyObjectSchema),
  })

  React.useEffect(() => {
    if (
      !enableReinitialize ||
      !defaultValues ||
      isEqual(form.control._defaultValues, defaultValues)
    )
      return
    form.reset(defaultValues)
  }, [defaultValues, form, enableReinitialize])

  const edit = React.useCallback(() => {
    setIsEditing(true)
    if (onEdit) onEdit()
  }, [onEdit])

  const cancel = React.useCallback(() => {
    setIsEditing(false)
    form.reset()
    if (onCancel) onCancel()
  }, [form, onCancel])

  const submit = React.useCallback(async () => {
    if (!onSubmit) {
      return
    }

    await form.handleSubmit(async data => {
      setIsSubmitting(true)

      try {
        // Pass form data through Yup schema and strip unknown fields
        const result = schema?.cast(data, { stripUnknown: true }) ?? data

        // Submit data
        await onSubmit(result)
        setIsSubmitting(false)
        setIsEditing(false)
      } catch (error) {
        setIsSubmitting(false)
        if (error instanceof AxiosError) {
          const title = error?.response?.data?.title ?? 'Error submitting form'
          const description =
            error.response?.data.status === 500
              ? 'Something went wrong'
              : Object.values(error.response?.data?.errors).join(', ') ?? '-'
          alert.error(title, description)
          return
        } else {
          alert.error(String(error))
        }
      }
    })()
  }, [form, onSubmit, schema])

  return {
    form,
    formState: {
      isEditing,
      isSubmitting,
    },
    formActions: {
      cancel,
      edit,
      submit,
    },
  }
}
