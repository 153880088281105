import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { UserType } from 'types/users'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { usersColumns, usersDefaultSortees } from 'utils/tables/users'

const UsersList = () => {
  const { t } = useTranslation()
  const { isAdmin } = useCurrentUser()
  const navigate = useNavigate()

  const { tableData, ...rest } = useTableRequestState<UserType>({
    requestFunc: api.getUsers,
    sortees: usersDefaultSortees,
  })

  return (
    <PageTemplate
      title={t('Users List')}
      rightActions={
        isAdmin && (
          <Button
            onClick={() => navigate(GenericPath.NEW)}
            iconComponent={() => <PlusIcon />}
          >
            {t('Add User')}
          </Button>
        )
      }
    >
      <Table<UserType>
        data={tableData?.data || []}
        columns={usersColumns}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </PageTemplate>
  )
}

export default UsersList
