import { alert } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { UserInputType } from 'types/users'

import PageTemplate from 'components/PageTemplate'
import UserForm from 'components/Users/UserForm'
import { useFormManager } from 'hooks/useFormManager'
import useLanguage from 'hooks/useLanguage'
import { RootPath } from 'router/enums'
import { UserInputSchema } from 'schemas/user'
import { useCurrentUser } from 'userContext'

const ProfileEdit = () => {
  const { t } = useTranslation()
  const { setLanguage } = useLanguage()
  const { currentUserData, updateCurrentUser, isLoading } = useCurrentUser()

  const navigate = useNavigate()

  const form = useFormManager<UserInputType>({
    defaultValues: currentUserData || {},
    enableReinitialize: true,
    schema: UserInputSchema,
    onCancel: () => navigate(RootPath.PROFILE),
    onSubmit: async (input: UserInputType) => {
      await updateCurrentUser({ input })
      setLanguage(input.languageCode)
      navigate(RootPath.PROFILE)
      alert.success(
        `${t('Success')}!`,
        t('Your profile has been updated successfully.'),
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Edit Profile')}
      goBackTo={RootPath.PROFILE}
      fullWidth={false}
    >
      <UserForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default ProfileEdit
