import { ToastContainer } from '@weareredlight/design-system'

import { AppRouter } from './router'

import Login from 'pages/Login/index'
import { CurrentUserProvider } from 'userContext'

const App = () => {
  return (
    <Login>
      <CurrentUserProvider>
        <AppRouter />
        <ToastContainer />
      </CurrentUserProvider>
    </Login>
  )
}

export default App
