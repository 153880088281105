import { css } from '@weareredlight/design-system'

export const filtersForm = css({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$xlg',

  backgroundColor: '$neutral100',
  padding: '$sm',
  borderRadius: '$sm',
})
