import { alert, Modal } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProviderScheduleInputType } from 'types/providers'

import ProviderShiftsForm from './ProviderShiftsForm'
import { mapForm2Api, type ShiftScheduleActions } from './utils'

import api from 'api/api'
import Card from 'components/Card'
import { DialogFormWrapper } from 'components/Form'
import { useFormManager } from 'hooks/useFormManager'
import { ProviderShiftInputSchema } from 'schemas/provider'

type NewProviderShiftProps = {
  isOpen: boolean
  providerId: string
  onClose: (action?: ShiftScheduleActions) => void
  refreshData: () => void
}

const NewProviderShift = ({
  isOpen,
  onClose,
  providerId,
  refreshData,
}: NewProviderShiftProps) => {
  const { t } = useTranslation()

  const form = useFormManager<ProviderScheduleInputType>({
    defaultValues: { providerId },
    onCancel: () => {
      form.form.reset()
      onClose()
    },
    enableReinitialize: true,
    schema: ProviderShiftInputSchema,
    onSubmit: async (input: ProviderScheduleInputType) => {
      await api.addProviderShift(mapForm2Api(input))
      refreshData()
      onClose()
      form.form.reset()
      alert.success(`${t('Success')}!`, t('New shift added successfully.'), {
        position: 'bottom-right',
      })
    },
  })

  return (
    <Modal
      open={isOpen}
      title={t('New Shift')}
      extraClasses={DialogFormWrapper()}
      closeFn={onClose}
    >
      <Card extraClasses="wrapper">
        <ProviderShiftsForm {...form} />
      </Card>
    </Modal>
  )
}

export default NewProviderShift
