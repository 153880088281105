import * as yup from 'yup'

import type { OrganizationInputType } from 'types/organizations'

import { OrgFields } from 'components/Organizations/OrganizationForm'
import i18next from 'i18n'
import { FrequencyDigest, WeekDays } from 'utils/enums'

const WorkingHoursSchema = yup.object({
  [OrgFields.START_TIME]: yup
    .string()
    .required(i18next.t('Start time is required')),
  [OrgFields.END_TIME]: yup
    .string()
    .required(i18next.t('End time is required')),
})

export const OrganizationInputSchema: yup.Schema<OrganizationInputType> = yup
  .object()
  .shape({
    [OrgFields.NAME]: yup
      .string()
      .required(i18next.t('Organization name is required')),
    [OrgFields.TIME_ZONE]: yup
      .string()
      .required(i18next.t('Timezone is required')),
    [OrgFields.PREFIX]: yup.object({
      [OrgFields.FREQUENCY]: yup
        .mixed<FrequencyDigest>()
        .oneOf(Object.values(FrequencyDigest))
        .required(i18next.t('Frequency is required')),
      [OrgFields.SENDING_TIME]: yup
        .string()
        .when('frequency', ([freq], schema) => {
          return freq === FrequencyDigest.NEVER
            ? schema.nullable()
            : schema.required(i18next.t('Sending time is required'))
        }),
      [OrgFields.WEEK_DAYS]: yup.array(
        yup.mixed<WeekDays>().oneOf(Object.values(WeekDays)).defined(),
      ),
      [OrgFields.EMAILS]: yup
        .array()
        .of(yup.string().defined())
        .when('frequency', ([freq], schema) => {
          return freq === FrequencyDigest.NEVER
            ? schema
            : schema
                .min(1, i18next.t('It is necessary to add at least one email'))
                .required(i18next.t('Emails to send to are required'))
        }),
    }),
    [OrgFields.WORKING_HOURS]: WorkingHoursSchema,
    [OrgFields.CELL_BANK_WORKING_HOURS]: WorkingHoursSchema,
  })
