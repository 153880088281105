import i18next from 'i18next'

import { splitCamelCase } from './text'

export const enum2SelectOptions = (
  enumValue: Record<string, string>,
  labels?: Record<string, string>,
) =>
  Object.entries(enumValue).map(
    ([text, value]: [text: string, value: string]) => ({
      value,
      label: value
        ? labels
          ? labels[value]
          : i18next.t(splitCamelCase(value))
        : i18next.t(text),
    }),
  )
