import { Button, Text, Flex } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { TreatmentType } from 'types/treatments'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { TreatmentFields } from 'components/Treatments/utils'
import { useRequest } from 'hooks/useRequest'
import { RootPath, GenericPath } from 'router/enums'
import { APPOINTMENT_FORMAT, format } from 'utils/time'

const TreatmentView = () => {
  const navigate = useNavigate()
  const { id, treatmentId } = useParams<{ id: string; treatmentId: string }>()
  const { t } = useTranslation()

  const {
    data: treatment,
    doRequest: getTreatment,
    isLoading,
  } = useRequest<TreatmentType, { id: string }>(api.getTreatment)

  const goBackToUrl = React.useMemo(() => `${RootPath.PATIENTS}/${id}`, [id])

  React.useEffect(() => {
    if (treatmentId) getTreatment({ id: treatmentId })
  }, [getTreatment, treatmentId])

  return (
    <PageTemplate
      title={t('Treatment')}
      goBackTo={goBackToUrl}
      numberOfElements={1}
      fullWidth={false}
      rightActions={
        process.env.REACT_APP_ENV !== 'production' && (
          <Button
            variant="secondary"
            onClick={() => navigate(GenericPath.EDIT)}
          >
            {t('Edit')}
          </Button>
        )
      }
    >
      <Card width="720px" isLoading={isLoading}>
        <Flex direction="column" align="start" gap="xxxlg">
          <Text variant="h2" color="primary800">
            {t('Treatment Details')}
          </Text>

          <Flex align="start" gap="xxxlg">
            <Flex direction="column" align="start" gap="xxxlg">
              <Flex align="start" gap="xlg" className="fields-wrapper">
                <EntityDetail label={t('Name')}>
                  {treatment?.[TreatmentFields.NAME] ?? '--'}
                </EntityDetail>
                <EntityDetail label={t('Organization')}>
                  {treatment?.organization?.name ?? '--'}
                </EntityDetail>
              </Flex>
              <Flex align="start" gap="xlg" className="fields-wrapper">
                <EntityDetail label={t('Year')}>
                  {treatment?.[TreatmentFields.YEAR] ?? '--'}
                </EntityDetail>
                <EntityDetail label={t('Week')}>
                  {treatment?.[TreatmentFields.WEEK] ?? '--'}
                </EntityDetail>
                <EntityDetail label={t('Plan in days')}>
                  {treatment?.[TreatmentFields.PLAN_IN_DAYS] ?? '--'}
                </EntityDetail>
              </Flex>
              <Flex align="start" gap="xlg" className="fields-wrapper">
                <EntityDetail label={t('Invoice Number')}>
                  {treatment?.[TreatmentFields.INVOICE_NUMBER] ?? '--'}
                </EntityDetail>
                <EntityDetail label={t('Salesforce ID')}>
                  {treatment?.[TreatmentFields.SALESFORCE_ID] ?? '--'}
                </EntityDetail>
              </Flex>
              <EntityDetail label={t('System Mod Stamp')}>
                {treatment?.[TreatmentFields.SYSTEM_MOD_STAMP]
                  ? format(
                      treatment[TreatmentFields.SYSTEM_MOD_STAMP],
                      APPOINTMENT_FORMAT,
                    )
                  : '--'}
              </EntityDetail>
              <EntityDetail label={t('Is intrathical application')}>
                {treatment?.[TreatmentFields.IS_INTRATHICAL_APPLICATION]
                  ? t('Yes')
                  : t('No')}
              </EntityDetail>
              <EntityDetail label={t('Visit')}>
                {treatment?.[TreatmentFields.VISIT] ?? '--'}
              </EntityDetail>
            </Flex>
            <Flex direction="column" align="start" style={{ flex: 1 }}>
              <EntityDetail label={t('Comments')}>
                {treatment?.[TreatmentFields.COMMENTS] ?? '--'}
              </EntityDetail>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default TreatmentView
