import {
  CalendarViews,
  CancellationReasons,
  Certification,
  DocumentType,
  PatientTypeEnum,
  ProcedureMedicalServiceTypeEnum,
  ProcedurePreparationTypeEnum,
  ProcedureProductSpecialityEnum,
  ProcedureTypeEnum,
  Specialities,
} from './enums'

export const cancellationReasonsLabels: {
  [key in CancellationReasons]: string
} = {
  [CancellationReasons.COVID_19]: 'Covid-19',
  [CancellationReasons.UTI]: 'UTI',
  [CancellationReasons.PATIENT_DECISION]: 'Patient Decision',
  [CancellationReasons.QX_PROCEDURE]: 'QX Procedure',
  [CancellationReasons.MEDICAL_REASON]: 'Medical Reason',
  [CancellationReasons.CANCER_DIAGNOSIS]: 'Cancer Diagnosis',
  [CancellationReasons.DEATH]: 'Death (deceased patient)',
  [CancellationReasons.BALANCE_PAYMENT_DUE]: 'Balance / Payment Due',
}

export const productSpecialtyLabels: {
  [key in ProcedureProductSpecialityEnum]: string
} = {
  [ProcedureProductSpecialityEnum.ALTERNATIVE_MEDICINE]: 'Alternative Medicine',
  [ProcedureProductSpecialityEnum.ALTERNATIVE_SPECIALIST]:
    'Alternative Specialist',
  [ProcedureProductSpecialityEnum.ANESTESIOLOGY]: 'Anestesiology',
  [ProcedureProductSpecialityEnum.BLOOD_TEST]: 'Blood Test',
  [ProcedureProductSpecialityEnum.CARDIOLOGY]: 'Cardiology',
  [ProcedureProductSpecialityEnum.COMPUTED_TOMOGRAPHY]: 'Computed Tomography',
  [ProcedureProductSpecialityEnum.DERMA_URO_GINE]: 'Derma/Uro/Gine',
  [ProcedureProductSpecialityEnum.DERMATOLOGY]: 'Dermatology',
  [ProcedureProductSpecialityEnum.GASTROENTEROLOGY]: 'Gastroenterology',
  [ProcedureProductSpecialityEnum.GENERAL_PRACTICE]: 'General Practice',
  [ProcedureProductSpecialityEnum.GYNECOLOGY]: 'Gynecology',
  [ProcedureProductSpecialityEnum.INTERNAL_MEDICINE]: 'Internal Medicine',
  [ProcedureProductSpecialityEnum.INTRAVENOUS]: 'Intravenous',
  [ProcedureProductSpecialityEnum.LABORATORY]: 'Laboratory',
  [ProcedureProductSpecialityEnum.NEUROLOGICAL]: 'Neurological',
  [ProcedureProductSpecialityEnum.NEUROLOGY]: 'Neurology',
  [ProcedureProductSpecialityEnum.NEUROPSYCHOLOGY]: 'Neuropsychology',
  [ProcedureProductSpecialityEnum.NEUROSURGERY]: 'Neurosurgery',
  [ProcedureProductSpecialityEnum.NUTRITIONIST]: 'Nutritionist',
  [ProcedureProductSpecialityEnum.PEDIATRIC_NEUROLOGY]: 'Pediatric Neurology',
  [ProcedureProductSpecialityEnum.PHYSICAL_THERAPY]: 'Physical Therapy',
  [ProcedureProductSpecialityEnum.SPIROMETRY]: 'Spirometry',
  [ProcedureProductSpecialityEnum.SPORTS_ORTHO]: 'Sports/Ortho',
  [ProcedureProductSpecialityEnum.ULTRASOUND]: 'Ultrasound',
  [ProcedureProductSpecialityEnum.URODINAMIA]: 'Urodinamia',
  [ProcedureProductSpecialityEnum.UROLOGY]: 'Urology',
  [ProcedureProductSpecialityEnum.X_RAY]: 'X-Ray',
  [ProcedureProductSpecialityEnum.RADIOLOGY]: 'Radiology',
  [ProcedureProductSpecialityEnum.OSTEOPATHIC]: 'Osteopathic',
  [ProcedureProductSpecialityEnum.CONCIERGE]: 'Concierge',
  [ProcedureProductSpecialityEnum.GENERAL_MEDICINE]: 'General Medicine',
  [ProcedureProductSpecialityEnum.CCP]: 'CCP',
  [ProcedureProductSpecialityEnum.REGENERATIVE_MEDICINE]:
    'Regenerative Medicine',
  [ProcedureProductSpecialityEnum.OSTEOPATHY]: 'Osteopathy',
}

export const medicalServiceTypeLabels: {
  [key in ProcedureMedicalServiceTypeEnum]: string
} = {
  [ProcedureMedicalServiceTypeEnum.COMPLEMENTARY_THERAPY]:
    'Complementary Therapy',
  [ProcedureMedicalServiceTypeEnum.CONSULTS]: 'Consults',
  [ProcedureMedicalServiceTypeEnum.DIAGNOSTIC_AIDS]: 'Diagnostic aids',
  [ProcedureMedicalServiceTypeEnum.NUMBER_OF_CELLS]: 'Number Of Cells',
  [ProcedureMedicalServiceTypeEnum.NUMBER_OF_HYPOXIC]: 'Number Of Hypoxic',
  [ProcedureMedicalServiceTypeEnum.PROCEDURES]: 'Procedures',
  [ProcedureMedicalServiceTypeEnum.NORMOXIC_CELLS]: 'Normoxic Cells',
  [ProcedureMedicalServiceTypeEnum.HYPOXIC_CELLS]: 'Hypoxic Cells',
  [ProcedureMedicalServiceTypeEnum.EXOSOMES]: 'Exosomes',
  [ProcedureMedicalServiceTypeEnum.PROFESSIONAL_SERVICES]:
    'Professional Services',
  [ProcedureMedicalServiceTypeEnum.EXO_HX_CELLS]: 'Exo HX Cells',
  [ProcedureMedicalServiceTypeEnum.LOGISTICS]: 'Logistics',
  [ProcedureMedicalServiceTypeEnum.DISCOUNTS]: 'Discounts',
}

export const procedureTypeLabels: {
  [key in ProcedureTypeEnum]: string
} = {
  [ProcedureTypeEnum.BIO_X_GEL]: 'BioXgel',
  [ProcedureTypeEnum.CRYOTHERAPY]: 'Cryotherapy',
  [ProcedureTypeEnum.DIAGNOSTIC_TEST]: 'Diagnostic test',
  [ProcedureTypeEnum.EPIGENETIC]: 'Epigenetic',
  [ProcedureTypeEnum.FACETS]: 'Facets',
  [ProcedureTypeEnum.HYPERBARIC_OXYGEN_THERAPY]: 'Hyperbaric Oxygen Therapy',
  [ProcedureTypeEnum.IMAGES]: 'Images',
  [ProcedureTypeEnum.INTRA_ARTICULAR]: 'Intra-Articular',
  [ProcedureTypeEnum.INTRA_CAVERNOUS]: 'Intra-Cavernous',
  [ProcedureTypeEnum.INTRA_DERMAL]: 'Intra-Dermal',
  [ProcedureTypeEnum.INTRA_DISCAL]: 'Intra-Discal',
  [ProcedureTypeEnum.INTRA_FACET]: 'Intra-Facet',
  [ProcedureTypeEnum.INTRA_MUSCULAR]: 'Intra-Muscular',
  [ProcedureTypeEnum.INTRA_PERINEURAL]: 'Intra-Perineural',
  [ProcedureTypeEnum.INTRA_THECAL]: 'Intra-Thecal',
  [ProcedureTypeEnum.INTRA_VAGINAL]: 'Intra-Vaginal',
  [ProcedureTypeEnum.INTRA_TENDINOUS_INTRALIGAMENT]:
    'Intratendinosus/Intraligament',
  [ProcedureTypeEnum.IV_CELLS]: 'IV Cells',
  [ProcedureTypeEnum.IV_DRIPS]: 'IV Drips',
  [ProcedureTypeEnum.LAB_TEST]: 'Lab test',
  [ProcedureTypeEnum.MASSAGES]: 'Massages',
  [ProcedureTypeEnum.TMJ_APPLICATION]: 'TMJ application',
  [ProcedureTypeEnum.OPEN_MRI]: 'Open MRI',
  [ProcedureTypeEnum.OZONE_THERAPY]: 'Ozone therapy',
  [ProcedureTypeEnum.PERINEURAL]: 'Perineural',
  [ProcedureTypeEnum.PERINEURAL_APPLICATION]: 'Perineural Application',
  [ProcedureTypeEnum.CONSULT]: 'Consult',
  [ProcedureTypeEnum.INTRA_MUSCULAR_PELVIC_FLOOR]:
    'Intra Muscular Pelvic Floor',
  [ProcedureTypeEnum.INTRA_PROSTATIC]: 'Intra-Prostatic',
  [ProcedureTypeEnum.INTRA_TESTICULAR]: 'Intra-Testicular',
  [ProcedureTypeEnum.INTRA_VESICAL_IRRIGATION]: 'Intra-Vesical Irrigation',
  [ProcedureTypeEnum.INTRA_VESICAL_INFILTRATION]: 'Intra-Vesical Infiltration',
  [ProcedureTypeEnum.INTRAVENOUS]: 'Intravenous',
  [ProcedureTypeEnum.NOT_APPLICABLE]: 'Not Applicable',
  [ProcedureTypeEnum.NEBULIZED]: 'Nebulized',
  [ProcedureTypeEnum.PERI_OSEO]: 'Peri Oseo',
  [ProcedureTypeEnum.PERINEURAL_PUDENDAL_NERVE]: 'Perineural Pudendal Nerve',
  [ProcedureTypeEnum.PERINEURAL_PUDENDAL_NERVE_BILATERAL]:
    'Perineural Pudendal Nerve Bilateral',
}

export const procedurePreparationTypeLabels: {
  [key in ProcedurePreparationTypeEnum]: string
} = {
  [ProcedurePreparationTypeEnum.ADVANCE]: 'Advance',
  [ProcedurePreparationTypeEnum.BASIC]: 'Basic',
  [ProcedurePreparationTypeEnum.BASIC_MASSAGE]: 'Basic massage',
  [ProcedurePreparationTypeEnum.NONE]: 'None',
  [ProcedurePreparationTypeEnum.PART_OF_FACETS]: 'Part of Facets',
}

export const certificationLabels: {
  [key in Certification]: string
} = {
  [Certification.DOCTOR]: 'Doctor',
  [Certification.NURSE]: 'Nurse',
  [Certification.RADIOLOGY_SPECIALIST]: 'Radiology Specialist',
  [Certification.PHYSICAL_THERAPY]: 'Physical Therapy',
  [Certification.PATIENT_CONCIERGE]: 'Patient Concierge',
  [Certification.MASOTHERAPEUTIST]: 'Masotherapeutist',
}

export const specialitiesLabels: {
  [key in Specialities]: string
} = {
  [Specialities.CARDIOLOGY]: 'Cardiology',
  [Specialities.GASTROENTEROLOGY]: 'Gastroenterology',
  [Specialities.NUTRITION]: 'Nutrition',
  [Specialities.NEUROLOGIST]: 'Neurologist',
  [Specialities.NEUROSURGERY]: 'Neurosurgery',
  [Specialities.INTERNAL_MEDICINE]: 'Internal Medicine',
  [Specialities.ANESTHESIOLOGIST]: 'Anesthesiologist',
  [Specialities.UROLOGIST]: 'Urologist',
  [Specialities.NEUROPSYCHOLOGY]: 'Neuropsychology',
  [Specialities.PSYCHOLOGY]: 'Psychology',
  [Specialities.SPORTS_MEDICINE]: 'Sports Medicine',
  [Specialities.ALTERNATIVE_MEDICINE]: 'Alternative Medicine',
  [Specialities.GENERAL_PHYSICIAN]: 'General Physician',
  [Specialities.CCP_PHYSICIAN]: 'CCP Physician',
  [Specialities.EPIDEMIOLOGIST]: 'Epidemiologist',
  [Specialities.DERMATOLOGIST]: 'Dermatologist',
  [Specialities.NEUROPEDIATRICIAN]: 'Neuropediatrician',
}

export const documentTypeLabels: {
  [key in DocumentType]: string
} = {
  [DocumentType.NONE]: 'None',
  [DocumentType.BIRTH_CERTIFICATE]: 'Birth Certificate',
  [DocumentType.CERTIFICATE_OF_LIVE_BIRTH]: 'Certificate of Live Birth',
  [DocumentType.DIPLOMATIC_ID]: 'Diplomatic ID',
  [DocumentType.FOREIGN_DOCUMENT]: 'Foreign Document (DE)',
  [DocumentType.FOREIGNER_ID]: 'Foreigner ID',
  [DocumentType.IDENTITY_CARD]: 'Identity Card',
  [DocumentType.NATIONAL_ID]: 'National ID',
  [DocumentType.PASSPORT]: 'Passport',
  [DocumentType.SAFE_CONDUCT]: 'Safe conduct',
  [DocumentType.SPECIAL_RESIDENT_FOR_PEACE]: 'Special Resident for Peace',
  [DocumentType.SPECIAL_STAY_PERMIT]: 'Special Stay Permit',
  [DocumentType.TAX_ID_NUMBER]: 'Tax ID Number',
  [DocumentType.TEMPORARY_PROTECTION]: 'Temporary Protection',
  [DocumentType.UN_PASSPORT]: 'UN Passport',
  [DocumentType.UNIDENTIFIED_ADULT]: 'Unidentified Adult',
  [DocumentType.UNIDENTIFIED_MINOR]: 'Unidentified Minor',
  [DocumentType.UNIQUE_PERSONAL_IDENTIFICATION_NUMBER]:
    'Unique Personal Identification Number',
}

export const patientTypeLabels: {
  [key in PatientTypeEnum]: string
} = {
  [PatientTypeEnum.REGULAR]: 'Regular',
  [PatientTypeEnum.EMPLOYEE]: 'Employee',
  [PatientTypeEnum.INFLUENCER]: 'Influencer',
  [PatientTypeEnum.RELATIVE]: 'Relative',
  [PatientTypeEnum.COMARKETER]: 'Co Marketer',
  [PatientTypeEnum.HCP]: 'HCP',
  [PatientTypeEnum.COMPANION]: 'Companion',
  [PatientTypeEnum.REGULAR_COMPANION]: 'Regular - Companion',
}

export const calendarViewLabels: {
  [key in CalendarViews]: string
} = {
  [CalendarViews.DAY]: 'Day',
  [CalendarViews.WORK_WEEK]: 'Week',
}
