import { styled, css } from '@weareredlight/design-system'

export const StyledFormContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$xlg',

  '.form-content': {
    width: '100%',

    '.fields-wrapper': {
      width: '100%',

      '@sm': {
        flexDirection: 'column',
        gap: '$xlg',
      },

      '>div': {
        width: '100%',

        '>div': {
          width: '100%',
        },
      },
    },
  },
})

export const LabelAsButton = styled('label', {
  fontSize: '$xsm',
  fontWeight: '$md',
  lineHeight: '$md',
  padding: '$xxsm $lg',
  backgroundColor: '$primary',
  color: '$white',
  appearance: 'none',
  border: 'none',
  outline: 'none',
  borderRadius: '$xsm',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$xxsm',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$primary600',
  },

  '&:focus': {
    $$shadowColor: '$colors$primary300',
    boxShadow: '0 0 0 2px $$shadowColor',
  },

  variants: {
    isLoading: {
      true: {
        cursor: 'default',

        '&:hover': {
          backgroundColor: '$primary',
        },
      },
    },
  },
})

export const DialogFormWrapper = css({
  width: '100%',
  minHeight: '40vh',

  '.wrapper': {
    flex: 1,

    '>div': {
      height: '100%',

      form: {
        padding: '$xxxsm',
        '>div': {
          width: '100%',
          alignItems: 'flex-start',
          '>div:last-child': {
            position: 'absolute',
            bottom: '$xxxsm',
            right: '$xxxsm',
          },
        },
      },
    },
  },
})
