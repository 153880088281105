import { Flex, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import ScheduleAppointment from './ScheduleAppointment'
import ScheduleView from './ScheduleView'

import AppointmentEdit from 'components/Appointments/AppointmentEdit'
import NewAppointment from 'components/Appointments/NewAppointment'
import TreatmentAppointmentsList from 'components/Appointments/TreatmentAppointmentsList'
import Card from 'components/Card'
import { GenericPath } from 'router/enums'
import { PermissionRequiredBoundary } from 'router/routes'
import { UserRoles } from 'utils/enums'

const Schedule = () => {
  const { t } = useTranslation()

  const EmptyState = () => (
    <Card extraClasses="full-width wrapper">
      <Flex css={{ height: '500px' }}>
        <Text variant="textBlock" color="neutral">
          {t('You are not able to create or edit appointments.')}
        </Text>
      </Flex>
    </Card>
  )

  return (
    <Routes>
      <Route index element={<ScheduleView />} />
      <Route path={GenericPath.ENTITY} element={<ScheduleAppointment />}>
        <Route index element={<TreatmentAppointmentsList />} />
        <Route
          path={`:treatmentProcedureId/${GenericPath.NEW}`}
          element={
            <PermissionRequiredBoundary
              allowed={[
                UserRoles.SYSTEM_ADMINISTRATOR,
                UserRoles.SCHEDULING_ADMINISTRATOR,
              ]}
              fallback={<EmptyState />}
            >
              <NewAppointment />
            </PermissionRequiredBoundary>
          }
        />
        <Route
          path={`:treatmentProcedureId/${GenericPath.EDIT}`}
          element={
            <PermissionRequiredBoundary
              allowed={[
                UserRoles.SYSTEM_ADMINISTRATOR,
                UserRoles.SCHEDULING_ADMINISTRATOR,
              ]}
              fallback={<EmptyState />}
            >
              <AppointmentEdit />
            </PermissionRequiredBoundary>
          }
        />
      </Route>
    </Routes>
  )
}

export default Schedule
