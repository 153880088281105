import { Flex, Label, Text } from '@weareredlight/design-system'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import type { OptionType } from 'types/types'

import { selectStyles } from './autocomplete.styles'

type ControlledSelectAutocompleteProps = {
  name: string
  options: OptionType[]
  label?: string
  description?: string
  placeholder?: string
  state?: 'disabled' | 'error' | 'null'
  errorMsg?: string
  fullWidth?: boolean
  customStyles?: React.CSSProperties
}

export const ControlledSelectAutocomplete = ({
  name,
  options,
  label,
  description,
  placeholder,
  state,
  errorMsg,
  fullWidth = false,
}: ControlledSelectAutocompleteProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  const isDisabled = state === 'disabled'
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Flex
          direction="column"
          align="start"
          gap="xxxsm"
          css={{ width: fullWidth ? '100%' : 'fit-content' }}
        >
          {label && <Label id={name} label={label} />}
          {description && <Text>{description}</Text>}
          <Select
            id={name}
            value={options.find(option => option.value === value)}
            onChange={selectedOption =>
              onChange(selectedOption ? selectedOption.value : '')
            }
            options={options}
            placeholder={placeholder || ''}
            isDisabled={isDisabled}
            styles={selectStyles}
            noOptionsMessage={() => t('No options')}
          />
          {state === 'error' && errorMsg && (
            <Text color="danger" variant="microCopy">
              {errorMsg}
            </Text>
          )}
        </Flex>
      )}
    />
  )
}
