import type { TreatmentType } from 'types/treatments'

export enum TreatmentFields {
  YEAR = 'year',
  WEEK = 'week',
  COMMENTS = 'comments',
  STATUS = 'status',
  ORGANIZATION_ID = 'organizationId',
  PATIENT_ID = 'patientId',
  NAME = 'name',
  SALESFORCE_ID = 'salesforceId',
  INVOICE_NUMBER = 'invoiceNumber',
  PLAN_IN_DAYS = 'planInDays',
  IS_INTRATHICAL_APPLICATION = 'isIntrathicalApplication',
  SYSTEM_MOD_STAMP = 'systemModStamp',
  VISIT = 'visit',
}

export enum TreatmentProceduresFields {
  PROCEDURE_IDS_TO_ADD = 'procedureIds',
}

export enum TreatmentCancellationFields {
  ORDER = 'order',
  REASON = 'reason',
  TIME = 'time',
  HAS_FEE = 'hasFee',
  IS_RESCHEDULED = 'isRescheduled',
}

export const mapApi2Form = (data: TreatmentType) => ({
  [TreatmentFields.YEAR]: data.year,
  [TreatmentFields.WEEK]: data.week,
  [TreatmentFields.COMMENTS]: data.comments,
  [TreatmentFields.STATUS]: data.status,
  [TreatmentFields.ORGANIZATION_ID]: data.organization.id,
  [TreatmentFields.PATIENT_ID]: data.patient.id,
  [TreatmentFields.NAME]: data.name,
  [TreatmentFields.SALESFORCE_ID]: data.salesforceId,
  [TreatmentFields.INVOICE_NUMBER]: data.invoiceNumber,
  [TreatmentFields.PLAN_IN_DAYS]: data.planInDays,
  [TreatmentFields.IS_INTRATHICAL_APPLICATION]: data.isIntrathicalApplication,
  [TreatmentFields.SYSTEM_MOD_STAMP]: data.systemModStamp,
  [TreatmentFields.VISIT]: data.visit,
})
