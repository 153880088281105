import { Toggle } from '@weareredlight/design-system'
import { Controller, useFormContext } from 'react-hook-form'

type ToggleType = { name: string, label?: string, description?: string };

export const ControlledToggle = ({ name, label, description }: ToggleType) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Toggle
          id={name}
          label={label}
          description={description}
          value={value}
          onChange={newValue => {
            onChange(newValue.target.value)
          }}
        />
      )}
    />
  )
}
