import { styled } from '@weareredlight/design-system'

export const AuthenticatorWrapper = styled('div', {

  '[data-amplify-authenticator]': {
    background: '$primary900',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',

    '&::after': {
      content: '',
      width: 'calc(100% - 640px)',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      backgroundImage: 'url(login-bg.jpeg)',
      backgroundSize: 'cover',
      opacity: 0.4
    },

    '[data-amplify-container]': {
      width: '640px',
      maxWidth: '720px',
      height: '100%',
      padding: '$xxlg',
      background: '$neutral100',

      '@sm': {
        padding: '$lg',
      },

      '[data-amplify-router]': {
        width: '100%',
        height: '100%',
        display: 'flex',
        felxDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 calc($xxxlg * 3)',
        borderRadius: '$sm',
        background: '$white',

        '@sm': {
          padding: '$xxxlg',
        },

        '>img': {
          marginBottom: '$xxxlg',
          maxWidth: '100%'
        },

        '[data-amplify-router-content]': {
          width: '100%',
          '>div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '$xxlg',
          },
        },

        form: {
          width: '100%',
          paddingTop: '$xxxlg',

          '.amplify-flex': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '$xxlg',
            border: 'none',

            legend: {
              display: 'none',
            },

            '.amplify-textfield': {
              width: '100%',
              display: 'inline-block',
              position: 'relative',

              label: {
                include: 'heading7',
                userSelect: 'none',
              },

              input: {
                width: '100%',
                height: '32px',
                marginTop: '$xxxsm',
                position: 'relative',
                padding: '$xxsm',
                marginBottom: '$xxxsm',
                borderRadius: '$xsm',

                appearance: 'none',
                border: 'none',
                backgroundColor: 'transparent',
                include: 'microCopy',
                outline: '1px solid $neutral400',
                color: '$neutral800',

                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',

                '&:focus': {
                  outlineColor: '$primary',
                },

                '&::placeholder': {
                  color: '$neutral',
                },
              }
            },

            '.amplify-field-group__outer-end': {
              position: 'absolute',
              right: '$xxsm',
              bottom: '$xxxsm',

              '.amplify-field__show-password': {
                all: 'unset',
                cursor: 'pointer',

                'span:not(.amplify-icon)': {
                  display: 'none',
                },

                svg: {
                  width: '16px'
                }
              },
            },

            '.amplify-alert--error': {
              marginTop: '-$xxlg',
              '.amplify-alert__icon': {
                display: 'none',
              },
              button: {
                display: 'none',
              },
              '.amplify-alert__body': {
                marginTop: '$xxxsm',
                include: 'microCopy',
                color: '$danger',
              }
            }
          },
        }
      }
    },
  },

  '.amplify-button--primary': {
    appearance: 'none',
    border: 'none',
    outline: 'none',
    borderRadius: '$xsm',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$xxsm',
    padding: '$xxsm $lg',
    include: 'heading7',
    backgroundColor: '$primary',
    color: '$white',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '$primary600',
    },
    '&:active': {
      backgroundColor: '$primary700',
    },
    '&:focus': {
      $$shadowColor: '$colors$primary300',
      boxShadow: '0 0 0 2px $$shadowColor',
    },

    svg: {
      display: 'none',
    }
  },
  '.amplify-button--loading': {
    pointerEvents: 'none',
    backgroundColor: '$primary200',
  },
  '.amplify-button--link': {
    all: 'unset',
    width: 'fit-content',
    include: 'textBlock',
    color: '$primary',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '$xxxsm',
    textAlign: 'center',
    margin: '-$xsm auto 0 auto',

    '&:hover': {
      color: '$primary600',
      textDecoration: 'none'
    },
    '&:active': {
      color: '$primary700',
      textDecoration: 'underline'
    },
    '&:focus': {
      color: '$primary600',
      textDecoration: 'none',
      borderRadius: '$xsm',
      outline: '1px solid $primary200'
    },
  },

  '[data-amplify-footer]': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '$xxlg',
  },

  '.sso-footer': {
    width: '100%',
    marginTop: '$xxsm',

    '.forgot-password-wrap': {
      button: {
        all: 'unset',
        width: 'fit-content',
        include: 'textBlock',
        color: '$primary',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '$xxxsm',
        textAlign: 'center',
        marginTop: '$xsm',

        '&:hover': {
          color: '$primary600',
          textDecoration: 'none'
        },
        '&:active': {
          color: '$primary700',
          textDecoration: 'underline'
        },
        '&:focus': {
          color: '$primary600',
          textDecoration: 'none',
          borderRadius: '$xsm',
          outline: '1px solid $primary200'
        },
      }
    }
  }
})
