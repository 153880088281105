import { alert, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { AppointmentInputType } from 'types/appointments'

import AppointmentForm from './AppointmentForm'

import api from 'api/api'
import { ApptFields } from 'components/Appointments/utils'
import EntityDetail from 'components/EntityDetail'
import { useFormManager } from 'hooks/useFormManager'
import { useSchedule } from 'pages/Schedule/ScheduleAppointment'
import { AppointmentInputSchema } from 'schemas/appointment'
import { getName } from 'utils/text'

const NewAppointment = () => {
  const { t } = useTranslation()
  const {
    draggedEvent,
    activeProcedure,
    resetActiveProcedure,
    resources,
    isLoadingResources,
    updateTreatment,
    setResourcesSelected,
    treatment,
  } = useSchedule()

  const form = useFormManager<AppointmentInputType>({
    defaultValues: {
      [ApptFields.START_TIME]: '',
      [ApptFields.END_TIME]: '',
      [ApptFields.DURATION_BEFORE]:
        activeProcedure?.procedure?.durationData.beforeTime,
      [ApptFields.TREATMENT_PROCEDURE_ID]: activeProcedure?.id,
      [ApptFields.PROVIDER_IDS]: [],
    },
    enableReinitialize: true,
    schema: AppointmentInputSchema,
    onCancel: () => resetActiveProcedure(),
    onSubmit: async data => {
      await api.addAppointment(data)
      resetActiveProcedure()
      updateTreatment()
      alert.success(
        `${t('Success')}!`,
        t('Appointment was added successfully.'),
        {
          position: 'bottom-right',
        },
      )
    },
  })

  if (!activeProcedure) return null

  return (
    <>
      <Text variant="h2">
        {t('New appointment')}
        <Text color="neutral800">
          {' '}
          - {t('for')} <b>{activeProcedure.procedure?.name}</b>
        </Text>
      </Text>
      <EntityDetail label={t('Patient')}>
        {getName(treatment?.patient)}
      </EntityDetail>
      <AppointmentForm
        {...form}
        procedure={activeProcedure.procedure}
        event={draggedEvent}
        resources={resources}
        isLoading={isLoadingResources}
        setResourcesSelected={setResourcesSelected}
      />
    </>
  )
}

export default NewAppointment
