import { alert } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProviderInputType } from 'types/providers'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import ProvidersForm, {
  ProviderFields,
} from 'components/Providers/ProviderForm'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { ProviderInputSchema } from 'schemas/provider'
import { Certification } from 'utils/enums'
import { getName } from 'utils/text'

const NewProvider = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const goBackToUrl = React.useMemo(
    () => `${RootPath.ORGANIZATIONS}/${id}`,
    [id],
  )

  const form = useFormManager<ProviderInputType>({
    defaultValues: {
      [ProviderFields.FIRSTNAME]: '',
      [ProviderFields.LASTNAME]: '',
      [ProviderFields.CERTIFICATION]: Certification.DOCTOR,
      [ProviderFields.ORGANIZATION_ID]: id,
    },
    enableReinitialize: true,
    schema: ProviderInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: ProviderInputType) => {
      await api.addProvider(input)
      navigate(goBackToUrl)
      alert.success(
        `${t('Success')}!`,
        `${getName(input)} ${t('was created successfully')}.`,
        { position: 'bottom-right' },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Provider')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <ProvidersForm {...form} />
    </PageTemplate>
  )
}

export default NewProvider
