import React from 'react'
import ReactLoading from 'react-loading'

import {
  StyledCard,
  StyledCardHeader,
  StyledCardBody,
  StyledLoadingWrapper,
} from './card.styles'

interface CardProps {
  children: React.ReactNode
  headerContent?: React.ReactNode
  extraClasses?: string
  width?: string
  isLoading?: boolean
}

const Card = ({
  children,
  headerContent,
  extraClasses,
  width,
  isLoading,
}: CardProps) => {
  return (
    <StyledCard className={extraClasses || ''} css={{ width: width || 'auto' }}>
      <>
        {headerContent && <StyledCardHeader>{headerContent}</StyledCardHeader>}
        <StyledCardBody>{children}</StyledCardBody>
      </>
      {isLoading && (
        <StyledLoadingWrapper>
          <ReactLoading type="spin" />
        </StyledLoadingWrapper>
      )}
    </StyledCard>
  )
}

export default Card
