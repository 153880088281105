import { styled } from '@weareredlight/design-system'

export const StyledCardHeader = styled('div', {
  width: '100%',
})

export const StyledCardBody = styled('div', {
  padding: '$xxxlg',
  '@sm': {
    padding: '$xlg',
  },
})

export const StyledCard = styled('div', {
  position: 'relative',
  maxWidth: '100%',
  borderRadius: '$sm',
  background: '$white',
  boxShadow: '$cardShadow',

  '@sm': {
    minWidth: '100%',
  },

  '&.is-table': {
    overflow: 'auto',
  },

  '&.no-padding': {
    [`& ${StyledCardBody}`]: {
      padding: 0,

      table: {
        margin: 0,
      },
    },
  },
  '&.small-card': {
    [`& ${StyledCardBody}`]: {
      padding: '$lg',
    },
  },
  '&.full-width': {
    width: '100%',
    [`& ${StyledCardBody}`]: {
      width: '100%',
    },
  },

  '&.wrapper': {
    borderRadius: '0',
    boxShadow: 'none',
    [`& ${StyledCardBody}`]: {
      padding: 0,
      table: {
        margin: 0,
      },
    },
  },
})

export const StyledLoadingWrapper = styled('div', {
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(5px)',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 10,

  svg: {
    fill: '$primary',
  },
})
