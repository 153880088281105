import * as yup from 'yup'

import type { AppointmentInputType } from 'types/appointments'

import { ApptFields } from 'components/Appointments/utils'
import i18next from 'i18n'

export const AppointmentInputSchema: yup.Schema<AppointmentInputType> = yup
  .object()
  .shape({
    [ApptFields.TREATMENT_PROCEDURE_ID]: yup.string().required(),
    [ApptFields.START_TIME]: yup
      .string()
      .required(i18next.t('Select a timeslot on the calendar to set a date.')),
    [ApptFields.END_TIME]: yup.string().required(),
    [ApptFields.DURATION_BEFORE]: yup.string().required(),
    [ApptFields.ROOM_ID]: yup
      .string()
      .required(i18next.t('Please select a room.')),
    [ApptFields.PROVIDER_IDS]: yup
      .array(yup.string().required())
      .min(1, i18next.t('At least one provider is required.'))
      .required(i18next.t('At least one provider is required.')),
  })
