import { PlusIcon } from '@radix-ui/react-icons'
import { Input, Flex, Pill, Text } from '@weareredlight/design-system'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { ChangeEvent } from 'react'
import type { OrganizationInputType } from 'types/organizations'
import type { FormType } from 'types/types'

import Card from 'components/Card'
import {
  ControlledCheckboxes,
  ControlledSelect,
  ControlledTimePicker,
  Form,
  PickerTypes,
} from 'components/Form'
import { FrequencyDigest, WeekDays } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { TimezoneOptions } from 'utils/time'

export enum OrgFields {
  PREFIX = 'digestData',
  NAME = 'name',
  TIME_ZONE = 'timezone',
  FREQUENCY = 'frequency',
  WEEK_DAYS = 'weekDays',
  SENDING_TIME = 'sendingTime',
  EMAILS = 'emails',
  WORKING_HOURS = 'workingHours',
  CELL_BANK_WORKING_HOURS = 'cellBankWorkingHours',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

const OrganizationForm = ({
  form,
  isLoading,
  ...props
}: FormType<OrganizationInputType>) => {
  const { t } = useTranslation()
  const [emailInput, setEmailInput] = useState<string>('')

  const emails = form.watch(`${OrgFields.PREFIX}.${OrgFields.EMAILS}`)
  const frequency = form.watch(`${OrgFields.PREFIX}.${OrgFields.FREQUENCY}`)

  const isWeekly = useMemo(
    () => frequency === FrequencyDigest.WEEKLY,
    [frequency],
  )

  const isNever = useMemo(
    () => frequency === FrequencyDigest.NEVER,
    [frequency],
  )

  const handleEmailsChange = () => {
    form.setValue(`${OrgFields.PREFIX}.${OrgFields.EMAILS}`, [
      ...(emails || []),
      emailInput,
    ])
    setEmailInput('')
  }

  const handleRemoveEmail = (newIndex: number) => {
    const newArray = emails?.filter((_, index) => index !== newIndex)
    form.setValue(`${OrgFields.PREFIX}.${OrgFields.EMAILS}`, newArray)
  }

  useEffect(() => {
    if (frequency !== FrequencyDigest.WEEKLY) {
      form.setValue(`${OrgFields.PREFIX}.${OrgFields.WEEK_DAYS}`, [])
    }
  }, [form, frequency])

  return (
    <Card isLoading={isLoading}>
      <Form form={form} {...props}>
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          <Text variant="h2">{t('Organization Details')}</Text>
          <Input
            {...form.register(OrgFields.NAME)}
            label={t('Name')}
            placeholder={t('Enter name of organization')}
            state={form.formState.errors[OrgFields.NAME] ? 'error' : 'null'}
            errorMsg={form.formState.errors[OrgFields.NAME]?.message}
            fullWidth
          />
          <Flex align="start" gap="sm" className="fields-wrapper">
            <ControlledSelect
              name={OrgFields.TIME_ZONE}
              options={TimezoneOptions}
              label={t('Timezone')}
              placeholder={t('Select timezone')}
              state={
                form.formState.errors[OrgFields.TIME_ZONE] ? 'error' : 'null'
              }
              fullWidth
            />
            <ControlledSelect
              name={`${OrgFields.PREFIX}.${OrgFields.FREQUENCY}`}
              options={enum2SelectOptions(FrequencyDigest)}
              label={t('Digest Frequency')}
              placeholder={t('Select digest frequency')}
              state={
                form.formState.errors[OrgFields.PREFIX]?.[OrgFields.FREQUENCY]
                  ? 'error'
                  : 'null'
              }
              fullWidth
            />
            {!isNever && (
              <ControlledTimePicker
                type={PickerTypes.TIME}
                name={`${OrgFields.PREFIX}.${OrgFields.SENDING_TIME}`}
                label={t('Digest Time')}
                error={
                  form.formState.errors[OrgFields.PREFIX]?.[
                    OrgFields.SENDING_TIME
                  ]?.message
                }
              />
            )}
          </Flex>
          {isWeekly && (
            <ControlledCheckboxes
              name={`${OrgFields.PREFIX}.${OrgFields.WEEK_DAYS}`}
              options={enum2SelectOptions(WeekDays)}
            />
          )}
          {!isNever && (
            <Flex
              direction="column"
              align="start"
              gap="xxxsm"
              css={{ width: '100%' }}
            >
              <Input
                value={emailInput}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmailInput(e.target.value)
                }
                label={t('Emails To Send To')}
                placeholder={t('Enter emails to send to')}
                iconComponent={() => <PlusIcon />}
                onClickIcon={() => handleEmailsChange()}
                state={
                  emails?.length === 0 &&
                  form.formState.errors[OrgFields.PREFIX]?.[OrgFields.EMAILS]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  emails?.length === 0
                    ? form.formState.errors[OrgFields.PREFIX]?.[
                        OrgFields.EMAILS
                      ]?.message
                    : undefined
                }
                fullWidth
              />
              <Flex
                align="start"
                justify="start"
                gap="xxxsm"
                css={{ width: '370px', flexWrap: 'wrap' }}
              >
                {emails?.map((email, index) => (
                  <Pill
                    key={`${email}-${index}`}
                    onClose={() => handleRemoveEmail(index)}
                  >
                    {email}
                  </Pill>
                ))}
              </Flex>
            </Flex>
          )}
          <Flex align="start" direction="column" gap="xxsm">
            <Text variant="subHeading" color="accent">
              {t('Facility')}
            </Text>
            <Flex align="start" gap="sm">
              <ControlledTimePicker
                type={PickerTypes.TIME}
                name={`${OrgFields.WORKING_HOURS}.${OrgFields.START_TIME}`}
                label={t('Open Hours')}
                error={
                  form.formState.errors[OrgFields.WORKING_HOURS]?.[
                    OrgFields.START_TIME
                  ]?.message
                }
              />
              <ControlledTimePicker
                type={PickerTypes.TIME}
                name={`${OrgFields.WORKING_HOURS}.${OrgFields.END_TIME}`}
                label={t('Close Hours')}
                error={
                  form.formState.errors[OrgFields.WORKING_HOURS]?.[
                    OrgFields.END_TIME
                  ]?.message
                }
              />
            </Flex>
          </Flex>
          <Flex align="start" direction="column" gap="xxsm">
            <Text variant="subHeading" color="accent">
              {t('Cell Bank')}
            </Text>
            <Flex align="start" gap="sm">
              <ControlledTimePicker
                type={PickerTypes.TIME}
                name={`${OrgFields.CELL_BANK_WORKING_HOURS}.${OrgFields.START_TIME}`}
                label={t('Open Hours')}
                error={
                  form.formState.errors[OrgFields.CELL_BANK_WORKING_HOURS]?.[
                    OrgFields.START_TIME
                  ]?.message
                }
              />
              <ControlledTimePicker
                type={PickerTypes.TIME}
                name={`${OrgFields.CELL_BANK_WORKING_HOURS}.${OrgFields.END_TIME}`}
                label={t('Close Hours')}
                error={
                  form.formState.errors[OrgFields.CELL_BANK_WORKING_HOURS]?.[
                    OrgFields.END_TIME
                  ]?.message
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </Form>
    </Card>
  )
}

export default OrganizationForm
