import { Button, Text, Flex, Pill, Tabs } from '@weareredlight/design-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { OrganizationType, WorkingHours } from 'types/organizations'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import { OrgFields } from 'components/Organizations/OrganizationForm'
import PageTemplate from 'components/PageTemplate'
import OrganizationProvidersList from 'components/Providers/OrganizationProvidersList'
import RoomsList from 'components/Rooms/RoomsList'
import { useRequest } from 'hooks/useRequest'
import { GenericPath, RootPath } from 'router/enums'
import { FrequencyDigest } from 'utils/enums'
import { timeStringToHoursAndMinutes } from 'utils/time'

const getWorkingHours = (workingHours?: WorkingHours) => {
  const start = timeStringToHoursAndMinutes(
    workingHours?.[OrgFields.START_TIME],
  )
  const end = timeStringToHoursAndMinutes(workingHours?.[OrgFields.END_TIME])
  return start + ' - ' + end
}

const OrganizationView = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const {
    data: organization,
    doRequest: getOrganization,
    isLoading,
  } = useRequest<OrganizationType, { id: string }>(api.getOrganization)

  const isWeekly = useMemo(
    () =>
      organization?.[OrgFields.PREFIX]?.[OrgFields.FREQUENCY] ===
      FrequencyDigest.WEEKLY,
    [organization],
  )

  const isNever = useMemo(
    () =>
      organization?.[OrgFields.PREFIX]?.[OrgFields.FREQUENCY] ===
      FrequencyDigest.NEVER,
    [organization],
  )

  useEffect(() => {
    if (id) getOrganization({ id })
  }, [getOrganization, id])

  return (
    <PageTemplate
      title={t('Organization Details')}
      goBackTo={RootPath.ORGANIZATIONS}
      numberOfElements={2}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card isLoading={isLoading}>
        <Flex gap="xxxlg" direction="column" align="start">
          <Text variant="h2" color="primary800">
            {organization?.[OrgFields.NAME]}
          </Text>
          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Timezone')}>
              {organization?.[OrgFields.TIME_ZONE]
                ? t(organization[OrgFields.TIME_ZONE])
                : '--'}
            </EntityDetail>
            <Flex gap="xsm">
              <EntityDetail label={t('Frequency')}>
                {organization?.[OrgFields.PREFIX]?.[OrgFields.FREQUENCY]
                  ? t(organization[OrgFields.PREFIX][OrgFields.FREQUENCY])
                  : '--'}
              </EntityDetail>
              {!isNever && (
                <EntityDetail label={t('Sending Time')}>
                  {timeStringToHoursAndMinutes(
                    organization?.[OrgFields.PREFIX]?.[OrgFields.SENDING_TIME],
                  ) || '--'}
                </EntityDetail>
              )}
            </Flex>
            {isWeekly && (
              <EntityDetail label={t('Week Days')}>
                <Flex gap="xxxsm" style={{ marginTop: 'var(--space-xxxsm)' }}>
                  {organization?.[OrgFields.PREFIX]?.[OrgFields.WEEK_DAYS]?.map(
                    (day, index) => (
                      <Pill key={`${day}-${index}`}>{t(day)}</Pill>
                    ),
                  )}
                </Flex>
              </EntityDetail>
            )}
            {!isNever && (
              <EntityDetail label={t('Emails to send digest')}>
                {organization?.[OrgFields.PREFIX]?.[OrgFields.EMAILS]
                  ?.length ? (
                  <Flex gap="xxxsm" style={{ marginTop: 'var(--space-xxxsm)' }}>
                    {organization?.[OrgFields.PREFIX]?.[OrgFields.EMAILS]?.map(
                      (email, index) => (
                        <Pill key={`${email}-${index}`}>{email}</Pill>
                      ),
                    )}
                  </Flex>
                ) : (
                  <Text variant="paragraph" color="neutral800">
                    --
                  </Text>
                )}
              </EntityDetail>
            )}
            {organization?.[OrgFields.WORKING_HOURS][OrgFields.START_TIME] && (
              <Flex gap="xsm">
                <EntityDetail label={t('Facility - Working Hours')}>
                  {getWorkingHours(organization?.[OrgFields.WORKING_HOURS])}
                </EntityDetail>
              </Flex>
            )}
            {organization?.[OrgFields.CELL_BANK_WORKING_HOURS][
              OrgFields.START_TIME
            ] && (
              <Flex gap="xsm">
                <EntityDetail label={t('Cell Bank - Working Hours')}>
                  {getWorkingHours(
                    organization?.[OrgFields.CELL_BANK_WORKING_HOURS],
                  )}
                </EntityDetail>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Card>
      <Card extraClasses="no-padding">
        <Tabs tabs={[{ label: t('Providers') }, { label: t('Rooms') }]}>
          <OrganizationProvidersList />
          <RoomsList />
        </Tabs>
      </Card>
    </PageTemplate>
  )
}

export default OrganizationView
