import { Button, Flex } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { OrganizationAppointmentsParams } from 'types/appointments'
import type { ProviderParamsType, ProviderType } from 'types/providers'
import type { RoomParamsType, RoomType } from 'types/rooms'
import type { PaginatedRequest } from 'types/types'

import { filtersForm } from './filters.styles'

import api from 'api/api'
import { ControlledMultiSelect, ControlledSelect } from 'components/Form'
import { ScheduleFields, convertToOptions } from 'components/Schedule/utils'
import { useRequest } from 'hooks/useRequest'
import { ProcedureTypeEnum } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { procedureTypeLabels } from 'utils/labels'

type ScheduleFiltersType = {
  organizationId: string
  getAppointmentFilters: (data: OrganizationAppointmentsParams) => void
}

const stemCellsOptions = [
  { value: '', label: 'All' },
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

const ScheduleFilters = ({
  organizationId,
  getAppointmentFilters,
}: ScheduleFiltersType) => {
  const { t } = useTranslation()

  const { data: rooms, doRequest: getRooms } = useRequest<
    PaginatedRequest<RoomType>,
    RoomParamsType
  >(api.getRooms)

  const { data: providers, doRequest: getProviders } = useRequest<
    PaginatedRequest<ProviderType>,
    ProviderParamsType
  >(api.getProviders)

  const form = useForm({
    defaultValues: {
      organizationId: organizationId,
      [ScheduleFields.PROCEDURE_TYPE]: [],
      [ScheduleFields.ROOM_IDS]: [],
      [ScheduleFields.PROVIDER_IDS]: [],
      [ScheduleFields.HAS_STEM_CELLS]: false,
    },
  })
  const onFilterSubmit = (data: OrganizationAppointmentsParams) =>
    getAppointmentFilters(data)

  useEffect(() => {
    getRooms({ organizationId, sort: 'Name', sortDirection: 'ASC' })
    getProviders({ organizationId, sort: 'Name', sortDirection: 'ASC' })
  }, [getProviders, getRooms, organizationId])

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onFilterSubmit)}
        className={filtersForm()}
      >
        <Flex
          gap="sm"
          align="end"
          justify="spaceBetween"
          css={{ width: '100%' }}
        >
          <Flex gap="xxsm">
            <ControlledMultiSelect
              name={`${ScheduleFields.PROCEDURE_TYPE}`}
              options={enum2SelectOptions(
                ProcedureTypeEnum,
                procedureTypeLabels,
              )}
              label={t('Procedure Type')}
              placeholder={t('Select a procedure type')}
              hasPills={false}
            />
            <ControlledMultiSelect
              name={`${ScheduleFields.ROOM_IDS}`}
              options={convertToOptions(rooms?.data || [])}
              label={t('Rooms')}
              placeholder={t('Select a room')}
              hasPills={false}
            />
            <ControlledMultiSelect
              name={`${ScheduleFields.PROVIDER_IDS}`}
              options={convertToOptions(providers?.data || [])}
              label={t('Providers')}
              placeholder={t('Select a provider')}
              hasPills={false}
            />
            <ControlledSelect
              name={`${ScheduleFields.HAS_STEM_CELLS}`}
              options={stemCellsOptions}
              label={t('Requires Cell Bank')}
              placeholder={t('All')}
            />
          </Flex>
          <Flex gap="xxxsm">
            <Button
              type="submit"
              variant="success"
              css={{ height: 32 }}
              disabled={!form.formState.isDirty}
            >
              {t('Filter')}
            </Button>
            <Button
              variant="neutral"
              css={{ height: 32 }}
              onClick={() => {
                form.reset()
                getAppointmentFilters({ organizationId })
              }}
            >
              {t('Clear')}
            </Button>
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  )
}

export default ScheduleFilters
