import { Button, Text, Flex, Pill, Avatar } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { UserType } from 'types/users'

import api from 'api/api'
import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { UserFields } from 'components/Users/UserForm'
import { useRequest } from 'hooks/useRequest'
import { RootPath, GenericPath } from 'router/enums'
import { LanguageDetails } from 'utils/enums'
import { getName, splitCamelCase } from 'utils/text'

const UserView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const {
    data: user,
    doRequest: getUser,
    isLoading,
  } = useRequest<UserType, { id: string }>(api.getUser)

  useEffect(() => {
    if (id) getUser({ id })
  }, [getUser, id])

  return (
    <PageTemplate
      title={t('User Details')}
      goBackTo={RootPath.USERS}
      fullWidth={false}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card width="464px" isLoading={isLoading}>
        <Flex direction="column" align="start" gap="xxxlg">
          <Flex align="start" justify="start" gap="xsm">
            <Avatar name={getName(user)} width="64px" />
            <Flex direction="column" align="start" gap="xxsm">
              {user?.[UserFields.ACTIVE] ? (
                <Pill variant="success">{t('Active')}</Pill>
              ) : (
                <Pill variant="error">{t('Inactive')}</Pill>
              )}
              <Text variant="h2" color="primary800">
                {getName(user)}
              </Text>
            </Flex>
          </Flex>

          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Email')}>
              {user?.[UserFields.EMAIL]}
            </EntityDetail>
            <EntityDetail label={t('Role')}>
              {user?.[UserFields.ROLE] &&
                t(splitCamelCase(user?.[UserFields.ROLE]))}
            </EntityDetail>
            <EntityDetail label={t('Language')}>
              {user?.[UserFields.LANGUAGE_CODE]
                ? t(
                    LanguageDetails[
                      user?.[
                        UserFields.LANGUAGE_CODE
                      ] as keyof typeof LanguageDetails
                    ],
                  )
                : '--'}
            </EntityDetail>
          </Flex>
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default UserView
