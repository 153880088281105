import { EyeOpenIcon } from '@radix-ui/react-icons'
import { Button, Flex, Text } from '@weareredlight/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'
import { Link, useNavigate } from 'react-router-dom'

import type {
  PatientTreatmentParamsType,
  TreatmentsType,
} from 'types/treatments'
import type { PaginatedRequest } from 'types/types'

import api from 'api/api'
import { StyledCard, StyledLoadingWrapper } from 'components/Card/card.styles'
import StatusTag from 'components/Treatments/StatusTag'
import { useRequest } from 'hooks/useRequest'
import { RootPath, TreatmentsPath } from 'router/enums'

const PatientTreatmentsList = ({ patientId }: PatientTreatmentParamsType) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    data: treatments,
    doRequest: getTreatments,
    isLoading,
  } = useRequest<PaginatedRequest<TreatmentsType>, PatientTreatmentParamsType>(
    api.getTreatments,
  )

  useEffect(() => {
    getTreatments({ patientId })
  }, [getTreatments, patientId])

  if (isLoading) {
    return (
      <StyledLoadingWrapper>
        <ReactLoading type="spin" />
      </StyledLoadingWrapper>
    )
  }

  return (
    <Flex direction="column" gap="sm" css={{ width: '100%' }}>
      {treatments?.data?.map((item, index) => (
        <StyledCard key={index} css={{ width: '100%' }}>
          <Flex justify="spaceBetween" css={{ padding: '$xlg' }}>
            <Flex gap="lg">
              <Link
                to={`${RootPath.SCHEDULE}/${item.id}`}
                style={{ color: 'var(--colors-primary)' }}
              >
                {item.organizationName + ' ' + (item.name ?? '')}
              </Link>
              <StatusTag status={item.status} />
              <Text variant="paragraph" color="neutral400">
                {(item.year ?? '--') +
                  ' - ' +
                  t('Week') +
                  ' ' +
                  (item.week ?? '--')}
              </Text>
            </Flex>
            <Button
              variant="textOnly"
              iconComponent={() => <EyeOpenIcon />}
              onClick={() =>
                navigate(`${TreatmentsPath.TREATMENTS}/${item.id}`)
              }
            ></Button>
          </Flex>
        </StyledCard>
      ))}
    </Flex>
  )
}

export default PatientTreatmentsList
