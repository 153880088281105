import { useAuthenticator } from '@aws-amplify/ui-react'
import { Avatar, Button, Flex, Pill, Text } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Card from 'components/Card'
import EntityDetail from 'components/EntityDetail'
import PageTemplate from 'components/PageTemplate'
import { UserFields } from 'components/Users/UserForm'
import { RootPath } from 'router/enums'
import { useCurrentUser } from 'userContext'
import { LanguageDetails } from 'utils/enums'
import { getName, splitCamelCase } from 'utils/text'

const ProfileView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentUserData, isLoading } = useCurrentUser()
  const { signOut } = useAuthenticator()

  const handleEditProfile = () => navigate(RootPath.PROFILE_EDIT)

  return (
    <PageTemplate
      title="User Details"
      fullWidth={false}
      rightActions={
        <Button variant="secondary" onClick={handleEditProfile}>
          {t('Edit')}
        </Button>
      }
    >
      <Card width="464px" isLoading={isLoading}>
        <Flex direction="column" align="start" gap="xxxlg">
          <Flex align="start" justify="start" gap="xsm">
            <Avatar name={getName(currentUserData)} width="64px" />
            <Flex direction="column" align="start" gap="xxsm">
              {currentUserData?.[UserFields.ACTIVE] ? (
                <Pill variant="success">{t('Active')}</Pill>
              ) : (
                <Pill variant="error">{t('Inactive')}</Pill>
              )}
              <Text variant="h2" color="primary800">
                {getName(currentUserData)}
              </Text>
            </Flex>
          </Flex>

          <Flex direction="column" align="start" gap="xsm">
            <EntityDetail label={t('Email')}>
              {currentUserData?.[UserFields.EMAIL]}
            </EntityDetail>
            <EntityDetail label={t('Role')}>
              {currentUserData?.[UserFields.ROLE] &&
                t(splitCamelCase(currentUserData[UserFields.ROLE]))}
            </EntityDetail>
            <EntityDetail label={t('Language')}>
              {currentUserData?.[UserFields.LANGUAGE_CODE]
                ? t(
                    LanguageDetails[
                      currentUserData?.[
                        UserFields.LANGUAGE_CODE
                      ] as keyof typeof LanguageDetails
                    ],
                  )
                : '--'}
            </EntityDetail>
          </Flex>
          <Button variant="danger" onClick={signOut}>
            {t('Log Out')}
          </Button>
        </Flex>
      </Card>
    </PageTemplate>
  )
}

export default ProfileView
