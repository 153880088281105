import { alert, Dialog } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import api from 'api/api'

type RestoreTreatmentProceduresProps = {
  treatmentId: string
  procedureId: string | null
  onClose: (procedure: string | null) => void
  refreshData: () => void
}

const RestoreTreatmentProcedures = ({
  treatmentId,
  procedureId,
  onClose,
  refreshData,
}: RestoreTreatmentProceduresProps) => {
  const { t } = useTranslation()

  const handleRestoreProcedure = async () => {
    if (procedureId) {
      await api.restoreTreatmentProcedure(treatmentId, procedureId)
      refreshData()
      alert.success(`${t('Success')}!`, t('Procedure has been restored.'), {
        position: 'bottom-right',
      })
    }
  }

  return (
    <Dialog
      open={!!procedureId}
      onConfirm={handleRestoreProcedure}
      closeFn={() => onClose(null)}
      title={t('Are you sure you want to schedule this Procedure?')}
      description={t('This procedure had previously been marked as cancelled.')}
      variant="danger"
      confirmButtonText={t('Confirm')}
      cancelButtonText={t('Cancel')}
    />
  )
}

export default RestoreTreatmentProcedures
