import { Flex } from '@weareredlight/design-system'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import api from 'api/api'
import Card from 'components/Card'
import PageTemplate from 'components/PageTemplate'
import { useRequest } from 'hooks/useRequest'
const option = { locale: localStorage.getItem('appLanguage') ?? 'en-US' }
const Dashboard = () => {
  const { t } = useTranslation()
  const dashboardRef = useRef<HTMLDivElement>(null)
  const { doRequest: getDashboard, isLoading } = useRequest<{
    embedUrl: string
  }>(api.getDashboard, {
    onSuccess: async ({ embedUrl }) => {
      if (!dashboardRef.current || dashboardRef.current.hasChildNodes()) return
      const { embedDashboard } = await createEmbeddingContext()
      await embedDashboard(
        {
          url: embedUrl,
          container: dashboardRef.current,
          width: '100%',
          height: '650px',
          resizeHeightOnSizeChangedEvent: true,
        },
        option,
      )
    },
  })
  useEffect(() => {
    getDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageTemplate title={t('Reports')} fullWidth={true}>
      <Card isLoading={isLoading} extraClasses="no-padding">
        <Flex ref={dashboardRef}></Flex>
      </Card>
    </PageTemplate>
  )
}

export default Dashboard
