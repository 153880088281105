import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom'

import { PermissionRequiredBoundary, RootPathPerms, routes } from './routes'

import Layout from 'components/Layout'
import { RootPath } from 'router/enums'

export const AppRouter = () => {
  const makeRoutes = () =>
    Object.entries(routes).map(([path, element]) => (
      <Route
        key={path}
        path={`${path}/*`}
        element={
          <PermissionRequiredBoundary allowed={RootPathPerms[path as RootPath]}>
            {element}
          </PermissionRequiredBoundary>
        }
      />
    ))
  return (
    <Router>
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          {makeRoutes()}
          <Route
            path="/"
            element={<Navigate to={RootPath.SCHEDULE} replace={true} />}
          />
        </Route>
      </Routes>
    </Router>
  )
}
