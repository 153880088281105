import { Button, Flex } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type FormControlButtonsProps = {
  isDisabled?: boolean
  isSubmitting: boolean
  cancel: () => void
  submit: () => void
  submitTxt?: string
} & React.ComponentProps<typeof Button>

export const FormControls = ({
  isDisabled,
  isSubmitting,
  cancel,
  submit,
  submitTxt = 'Save',
}: FormControlButtonsProps) => {
  const { t } = useTranslation()
  return (
    <Flex gap="xxsm" justify="end" style={{ width: '100%' }}>
      <Button variant="neutral" disabled={isSubmitting} onClick={cancel}>
        {t('Cancel')}
      </Button>
      <Button
        variant="success"
        disabled={isDisabled}
        isLoading={isSubmitting}
        onClick={submit}
      >
        {t(submitTxt)}
      </Button>
    </Flex>
  )
}
