import { createColumnHelper } from '@tanstack/react-table'
import { Pill } from '@weareredlight/design-system'
import i18next from 'i18next'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { ProcedureType } from 'types/procedures'
import type { RoomProcedureType } from 'types/rooms'

import { RootPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'
import { timeStringToHoursAndMinutes } from 'utils/time'

type ProcedureColumnsType = ProcedureType & { hasStemCells?: boolean }

const columnHelper = createColumnHelper<ProcedureColumnsType>()
const roomColumnHelper = createColumnHelper<RoomProcedureType>()

export const proceduresDefaultSortees: ColumnSort[] = [
  { id: 'name', desc: false },
]

export const proceduresColumns = [
  columnHelper.accessor('name', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: 'Name',
    enableSorting: true,
    meta: { width: '30%' },
    cell: ({ row: { original } }) => (
      <Link
        to={`${RootPath.PROCEDURES}/${original.id}`}
        style={{ color: 'var(--colors-primary)' }}
      >
        {original.name}
      </Link>
    ),
  }),
  columnHelper.accessor('durationData.mainTime', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: 'Duration',
    enableSorting: false,
    enableColumnFilter: false,
    meta: { width: '15%' },
    cell: ({ getValue }) => timeStringToHoursAndMinutes(getValue()),
  }),
  columnHelper.accessor('durationData.beforeTime', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: 'Duration Before',
    enableSorting: false,
    enableColumnFilter: false,
    meta: { width: '15%' },
    cell: ({ getValue }) => timeStringToHoursAndMinutes(getValue()),
  }),
  columnHelper.accessor('crmCode', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: 'CRM Code',
    enableSorting: false,
    enableColumnFilter: false,
    meta: { width: '20%' },
  }),
  columnHelper.accessor('hasStemCells', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: 'Cell Bank',
    enableSorting: false,
    enableColumnFilter: false,
    meta: { width: '10%' },
    cell: info => {
      const hasStemCells = Boolean(info.row.original.numberOfStemCells)
      return hasStemCells ? (
        <Pill variant="success">{i18next.t('Yes')}</Pill>
      ) : (
        <Pill variant="error">{i18next.t('No')}</Pill>
      )
    },
  }),
  columnHelper.accessor('numberOfStemCells', {
    ...defaultColumnOptions<ProcedureColumnsType>(),
    header: `# ${i18next.t('Stem Cells')}`,
    enableSorting: false,
    enableColumnFilter: false,
    meta: { width: '10%' },
    cell: info => {
      const numberOfStemCells = info.getValue()
      return numberOfStemCells ?? '-'
    },
  }),
]

export const roomProceduresColumns = [
  roomColumnHelper.accessor('procedure.name', {
    ...defaultColumnOptions<RoomProcedureType>(),
    header: 'Procedure',
    enableSorting: true,
    enableColumnFilter: false,
  }),
  roomColumnHelper.accessor('capacity', {
    ...defaultColumnOptions<RoomProcedureType>(),
    header: 'Capacity',
    enableSorting: true,
    enableColumnFilter: false,
  }),
]
