import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { PatientType } from 'types/patients'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath } from 'router/enums'
import { patientsColumns, patientsDefaultSortees } from 'utils/tables/patients'

const PatientsList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tableData, ...rest } = useTableRequestState<PatientType>({
    requestFunc: api.getPatients,
    sortees: patientsDefaultSortees,
  })

  return (
    <PageTemplate
      title={t('Patients List')}
      rightActions={
        <Button
          onClick={() => navigate(GenericPath.NEW)}
          iconComponent={() => <PlusIcon />}
        >
          {t('Add Patient')}
        </Button>
      }
    >
      <Table<PatientType>
        columns={patientsColumns}
        data={tableData?.data || []}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </PageTemplate>
  )
}

export default PatientsList
