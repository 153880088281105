import type { RoomType } from 'types/rooms'

export enum RoomFields {
  NAME = 'name',
  CAPACITY = 'capacity',
  PROCEDURES = 'procedures',
  PROCEDURE_ID = 'procedureId',
  ORGANIZATION_ID = 'organizationId',
}

export const mapApi2Form = (data: RoomType, id: string) => ({
  [RoomFields.NAME]: data.name,
  [RoomFields.CAPACITY]: data.capacity,
  [RoomFields.ORGANIZATION_ID]: id,
  [RoomFields.PROCEDURES]: data.roomProcedures?.map(procedure => ({
    [RoomFields.PROCEDURE_ID]: procedure.procedure.id,
    [RoomFields.CAPACITY]: procedure.capacity,
  })),
})
