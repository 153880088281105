/* eslint-disable no-console */
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Button, Flex, Text } from '@weareredlight/design-system'
import { Amplify, Auth, Hub } from 'aws-amplify'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AuthenticatorWrapper } from './login.styles'

import logo from 'assets/img/logo.svg'
import config from 'config'

Amplify.configure(config)

interface LoginProps {
  children: React.ReactNode
}

let federatedSignInError = false
Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn':
    case 'cognitoHostedUI':
      console.log('Authenticated...')
      break
    case 'signIn_failure':
    case 'cognitoHostedUI_failure':
      console.log('Error', data)
      federatedSignInError = true
      break
  }
})

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType:
      process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['openid'],
      redirectSignIn: process.env.REACT_APP_OAUTH_CALLBACK_URL,
      redirectSignOut: `${process.env.REACT_APP_OAUTH_CALLBACK_URL}/login`,
      responseType: 'code',
    },
  },
})

const Login = ({ children }: LoginProps) => {
  const { t } = useTranslation()

  const formFields = {
    signIn: {
      username: {
        label: t('Email'),
        placeholder: t('Enter your email'),
      },
      password: {
        label: t('Password'),
        placeholder: t('Enter your password'),
      },
    },
    resetPassword: {
      username: {
        label: t('Email'),
        placeholder: t('Enter your email'),
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: t('Verification Code'),
        placeholder: t('Enter your confirmation code'),
      },
      password: {
        label: t('New password'),
        placeholder: t('Choose a new password'),
      },
      confirm_password: {
        placeholder: t('Repeat new password'),
      },
    },
  }

  const components = {
    SignIn: {
      Header() {
        return <img src={logo} alt="BioXcellerator logo" />
      },
      Footer() {
        const { toResetPassword } = useAuthenticator()

        return (
          <>
            <Flex direction="column" gap="xxsm" className="sso-footer">
              <Text variant="textBlock" color="neutral900">
                Or
              </Text>
              <Button
                variant="secondary"
                fullWidth
                onClick={() =>
                  Auth.federatedSignIn({ customProvider: 'Azure' })
                }
              >
                {t('Sign in with Azure')}
              </Button>
              {federatedSignInError && (
                <Text variant="microCopy" color="danger">
                  {t('Unknown login. Please contact BioXcellerator support.')}
                </Text>
              )}
              <div className="forgot-password-wrap">
                <Button variant="textOnly" fullWidth onClick={toResetPassword}>
                  {t('Forgot password?')}
                </Button>
              </div>
            </Flex>
          </>
        )
      },
    },
    ResetPassword: {
      Header() {
        return (
          <Flex direction="column" align="start" gap="xsm">
            <Flex direction="column" align="start" gap="xxxsm">
              <Text variant="subHeading" color="primary">
                1/2
              </Text>
              <Text variant="h1" color="neutral800">
                {t('Reset Password')}
              </Text>
            </Flex>
            <Text variant="textBlock" color="neutral800">
              {t(
                'To set a new password, please give us your email address so we can send you a verification code.',
              )}
            </Text>
          </Flex>
        )
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <Flex direction="column" align="start" gap="xsm">
            <Flex direction="column" align="start" gap="xxxsm">
              <Text variant="subHeading" color="primary">
                2/2
              </Text>
              <Text variant="h1" color="neutral800">
                {t('Reset Password')}
              </Text>
            </Flex>
            <Text variant="textBlock" color="neutral800">
              {t('Now enter the verification code sent to your email.')}
            </Text>
          </Flex>
        )
      },
    },
  }

  return (
    <AuthenticatorWrapper>
      <Authenticator hideSignUp formFields={formFields} components={components}>
        {children}
      </Authenticator>
    </AuthenticatorWrapper>
  )
}

export default Login
