import qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import type {
  SortingState,
  OnChangeFn,
  ColumnSort,
  ColumnFiltersState,
  ColumnFilter,
} from '@tanstack/react-table'
import type { PaginatedRequest, RequestParams } from 'types/types'

import { useRequest } from 'hooks/useRequest'

interface TableData<TData> {
  data: TData[]
  count: number
  page: number
  pageSize: number
  pageCount: number
}

interface FilterParams {
  [key: string]: string
}
interface TableRequestState<TData> {
  currentPage: number
  tableData: TableData<TData> | null
  handlePageChange: (page: number) => void
  refreshTable: () => void
  sorting: SortingState
  setSorting: OnChangeFn<SortingState>
  columnFilters: ColumnFiltersState
  setColumnFilters: OnChangeFn<ColumnFiltersState>
  pageSize: number
  isLoading: boolean
}

type UseTableRequestStateType<TData, TParams> = {
  requestFunc: (
    params: RequestParams & TParams,
  ) => Promise<{ data: TableData<TData> }>
  pageSize?: number
  sortees?: ColumnSort[]
  columnFilter?: ColumnFilter[]
  params?: TParams
  requiredParams?: string[]
}

const useTableRequestState = <TData, TParams = object>({
  requestFunc,
  pageSize = 20,
  sortees,
  columnFilter,
  params,
  requiredParams,
}: UseTableRequestStateType<TData, TParams>): TableRequestState<TData> => {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const currentPageParam = Number(queryParams.page) || 1

  const [currentPage, setCurrentPage] = useState(currentPageParam)
  const [sorting, setSorting] = useState<SortingState>(sortees || [])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    columnFilter || [],
  )

  const {
    data: tableData,
    doRequest: getTableData,
    isLoading,
  } = useRequest<PaginatedRequest<TData>, RequestParams & TParams>(requestFunc)

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page)
      navigate(`?page=${page}`)
    },
    [navigate],
  )

  const filteredColumnFilters = useMemo(
    () => columnFilters.filter(filter => filter.value !== null),
    [columnFilters],
  )

  const filterParams: FilterParams = useMemo(() => {
    if (!filteredColumnFilters.length) return {}

    const filtersObject = filteredColumnFilters.reduce((acc, filter) => {
      acc[filter.id] = filter.value as string
      return acc
    }, {} as FilterParams)

    return filtersObject
  }, [filteredColumnFilters])

  const sortParams = useMemo(() => {
    if (!sorting.length) return null
    return {
      sort: sorting[0]?.id,
      sortDirection: sorting[0]?.desc ? 'Desc' : 'Asc',
    }
  }, [sorting])

  const tableParams = useMemo(
    () => ({
      page: currentPage - 1,
      pageSize,
      ...sortParams,
      ...filterParams,
      ...(params as TParams),
    }),
    [currentPage, pageSize, sortParams, filterParams, params],
  )

  const refreshTable = useCallback(() => {
    const loadTable =
      Object.keys(params || {}).every(
        key => params?.[key as keyof TParams] && requiredParams?.includes(key),
      ) || !requiredParams?.length

    if (loadTable) getTableData(tableParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTableData, params, tableParams])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  return {
    currentPage,
    tableData,
    handlePageChange,
    refreshTable,
    sorting,
    setSorting,
    columnFilters: filteredColumnFilters,
    setColumnFilters,
    pageSize,
    isLoading,
  }
}
export default useTableRequestState
