import { css, styled } from '@weareredlight/design-system'
import { StyledButton } from '@weareredlight/design-system'

export const AppointmentDetailsWrapper = styled('div', {
  [`& ${StyledButton}`]: {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$neutral',
    position: 'absolute',
    top: 5,
    right: 30,
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$neutral200' },
    '&:focus': { boxShadow: 'none' },
  },

  '.details-inner-container': {
    padding: '0 $xxxsm',

    '.details-title': {
      paddingTop: '$xxlg',
    },
  },
})

export const TreatmentCancellationInfo = css({
  width: '100% !important',
  padding: '$lg',

  '.cancellation-details': {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '$xlg',

    '> div ': {
      width: 'calc(100% / 3)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      '@md': {
        width: '100%',
      },
    },
  },
})
