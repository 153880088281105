import { Route, Routes } from 'react-router-dom'

import NewRoom from 'pages/Rooms/NewRoom'
import RoomEdit from 'pages/Rooms/RoomEdit'
import RoomView from 'pages/Rooms/RoomView'
import { GenericPath, RoomsPath } from 'router/enums'

const Rooms = () => (
    <Routes>
        <Route path={GenericPath.NEW} element={<NewRoom />} />
        <Route path={RoomsPath.ROOM} element={<RoomView />} />
        <Route path={`${RoomsPath.ROOM}/${GenericPath.EDIT}`} element={<RoomEdit />} />
    </Routes>
)

export default Rooms
