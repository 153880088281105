import { alert } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { TreatmentInputType } from 'types/treatments'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import TreatmentForm from 'components/Treatments/TreatmentForm'
import { TreatmentFields } from 'components/Treatments/utils'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { PatientTreatmentInputSchema } from 'schemas/patient'
import { TreatmentStatuses } from 'utils/enums'

const NewTreatment = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const goBackToUrl = React.useMemo(() => `${RootPath.PATIENTS}/${id}`, [id])

  const form = useFormManager<TreatmentInputType>({
    defaultValues: {
      [TreatmentFields.WEEK]: undefined,
      [TreatmentFields.YEAR]: undefined,
      [TreatmentFields.COMMENTS]: null,
      [TreatmentFields.STATUS]: TreatmentStatuses.NEW,
      [TreatmentFields.ORGANIZATION_ID]: undefined,
      [TreatmentFields.PATIENT_ID]: id,
      [TreatmentFields.IS_INTRATHICAL_APPLICATION]: false,
    },
    enableReinitialize: true,
    schema: PatientTreatmentInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: TreatmentInputType) => {
      await api.addTreatment(input)
      navigate(goBackToUrl)
      alert.success(
        `${t('Success')}!`,
        `${t('Treatment was created successfully.')}.`,
        {
          position: 'bottom-right',
        },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New Treatment')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <TreatmentForm {...form} />
    </PageTemplate>
  )
}

export default NewTreatment
