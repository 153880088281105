import { createContext, useContext, useEffect, useMemo } from 'react'

import type { UserInputType, UserType } from 'types/users'

import api from 'api/api'
import { useRequest } from 'hooks/useRequest'
import { UserRoles } from 'utils/enums'

interface CurrentUserContextType {
  currentUserData: UserType | null
  updateCurrentUser: ({ input }: { input: UserInputType }) => void
  isLoading?: boolean
  isAdmin?: boolean
  isSystemAdmin?: boolean
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)

export const useCurrentUser = (): CurrentUserContextType => {
  const context = useContext(CurrentUserContext)
  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }
  return context
}

interface CurrentUserProviderProps {
  children: React.ReactNode
}

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const {
    data: currentUserData,
    doRequest: getCurrentUser,
    isLoading: isLoadingCurrentUser,
    setData,
  } = useRequest<UserType>(api.getCurrentUser)

  const { doRequest: updateCurrentUser, isLoading: isUpdatingCurrentUser } =
    useRequest<UserType, { input: UserInputType }>(api.updateCurrentUser, {
      onSuccess: data => setData(data),
    })

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  const { isAdmin, isSystemAdmin } = useMemo(() => {
    const isScheduleAdmin =
      currentUserData?.role === UserRoles.SCHEDULING_ADMINISTRATOR
    const isSystemAdmin =
      currentUserData?.role === UserRoles.SYSTEM_ADMINISTRATOR
    const isAdmin = isScheduleAdmin || isSystemAdmin
    return { isAdmin, isSystemAdmin }
  }, [currentUserData?.role])

  const isLoading = useMemo(
    () => isLoadingCurrentUser || isUpdatingCurrentUser,
    [isLoadingCurrentUser, isUpdatingCurrentUser],
  )

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserData,
        updateCurrentUser,
        isLoading,
        isAdmin,
        isSystemAdmin,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
