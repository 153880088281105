import { alert } from '@weareredlight/design-system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { RoomInputType, RoomType } from 'types/rooms'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import RoomForm from 'components/Rooms/RoomForm'
import { mapApi2Form } from 'components/Rooms/utils'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { RoomsPath, RootPath } from 'router/enums'
import { RoomInputSchema } from 'schemas/Room'

const RoomEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id, roomId } = useParams<{ id: string; roomId: string }>()

  const {
    data: room,
    doRequest: getRoom,
    isLoading,
  } = useRequest<RoomType, { id: string }>(api.getRoom)

  const goBackToUrl = React.useMemo(
    () => `${RootPath.ORGANIZATIONS}/${id}/${RoomsPath.ROOMS}/${roomId}`,
    [id, roomId],
  )

  const form = useFormManager<RoomInputType>({
    defaultValues: room && id ? mapApi2Form(room, id) : {},
    enableReinitialize: true,
    schema: RoomInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: RoomInputType) => {
      if (roomId) {
        await api.updateRoom({ id: roomId, input })
        navigate(goBackToUrl)
        alert.success(
          `${t('Success')}!`,
          `${input.name} ${t('was edited successfully')}.`,
          {
            position: 'bottom-right',
          },
        )
      }
    },
  })

  useEffect(() => {
    if (roomId) getRoom({ id: roomId })
  }, [roomId, getRoom])

  return (
    <PageTemplate
      title={t('Edit Room')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <RoomForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default RoomEdit
