import { ChevronLeftIcon } from '@radix-ui/react-icons'
import { Flex, Text } from '@weareredlight/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  StyledPageTemplate,
  StyledPageHeader,
  StyledPageBody,
} from './page-template.styles'

interface PageTemplateProps {
  title?: string
  goBackTo?: string
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  children: React.ReactNode
  fullWidth?: boolean
  numberOfElements?: number
}

const PageTemplate = ({
  title,
  goBackTo,
  leftActions,
  rightActions,
  children,
  fullWidth,
  numberOfElements = 1,
}: PageTemplateProps) => {
  const { t } = useTranslation()
  return (
    <StyledPageTemplate>
      {title && (
        <StyledPageHeader>
          <Flex direction="column" align="start" gap="xxxsm">
            {goBackTo && (
              <Link to={goBackTo}>
                <ChevronLeftIcon width={16} height={16} />
                {t('Go Back')}
              </Link>
            )}
            <Flex gap="xxsm" align="end">
              <Text variant="h1" color="primary800">
                {t(title)}
              </Text>
              {leftActions}
            </Flex>
          </Flex>
          <Flex gap="xxsm">{rightActions}</Flex>
        </StyledPageHeader>
      )}
      <StyledPageBody
        fullWidth={fullWidth}
        style={{ gridTemplateColumns: `repeat(${numberOfElements}, 1fr)` }}
      >
        {children}
      </StyledPageBody>
    </StyledPageTemplate>
  )
}

export default PageTemplate
