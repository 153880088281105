import { Button, Text, Flex, Pill } from '@weareredlight/design-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProviderType } from 'types/providers'

import ProviderShifts from '../../components/Providers/ProviderShifts'

import api from 'api/api'
import Card from 'components/Card'
import PageTemplate from 'components/PageTemplate'
import { ProviderFields } from 'components/Providers/ProviderForm'
import { useRequest } from 'hooks/useRequest'
import { GenericPath, RootPath } from 'router/enums'
import { Certification } from 'utils/enums'
import { getName, splitCamelCase } from 'utils/text'

const ProviderView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id, providerId } = useParams<{ id: string; providerId: string }>()

  const {
    data: provider,
    doRequest: getProvider,
    isLoading,
  } = useRequest<ProviderType, { id: string }>(api.getProvider)

  const isDoctor = useMemo(
    () => provider?.[ProviderFields.CERTIFICATION] === Certification.DOCTOR,
    [provider],
  )

  useEffect(() => {
    if (providerId) getProvider({ id: providerId })
  }, [getProvider, providerId])

  return (
    <PageTemplate
      title={t('Provider Details')}
      goBackTo={`${RootPath.ORGANIZATIONS}/${id}`}
      numberOfElements={2}
      fullWidth={false}
      rightActions={
        <Button variant="secondary" onClick={() => navigate(GenericPath.EDIT)}>
          {t('Edit')}
        </Button>
      }
    >
      <Card isLoading={isLoading}>
        <Flex gap="xxxlg" direction="column" align="start">
          <Text variant="h2" color="primary800">
            {getName(provider)}
          </Text>
          <Flex direction="column" align="start" gap="xsm">
            <Flex gap="xsm">
              <Flex direction="column" align="start">
                <Text variant="subHeading" color="accent">
                  {t('Certification')}
                </Text>
                <Text variant="paragraph" color="neutral800">
                  {splitCamelCase(
                    t(provider?.[ProviderFields.CERTIFICATION] || '--'),
                  )}
                </Text>
              </Flex>
            </Flex>
            {isDoctor && (
              <Flex direction="column" align="start" gap="xxxsm">
                <Text variant="subHeading" color="accent">
                  {t('Specialities')}
                </Text>
                {provider?.[ProviderFields.SPECIALITIES]?.length ? (
                  <Flex gap="xxxsm">
                    {provider?.[ProviderFields.SPECIALITIES]?.map(
                      (speciality, index) => (
                        <Pill key={`${speciality}-${index}`}>
                          {t(splitCamelCase(speciality))}
                        </Pill>
                      ),
                    )}
                  </Flex>
                ) : (
                  <Text variant="paragraph" color="neutral800">
                    --
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Card>
      <Card isLoading={isLoading}>
        <ProviderShifts providerId={provider?.id || ''} />
      </Card>
    </PageTemplate>
  )
}

export default ProviderView
