import { alert, Dialog } from '@weareredlight/design-system'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import type { ScheduleAppointmentsType } from 'types/appointments'

import api from 'api/api'
import { useRequest } from 'hooks/useRequest'
type ScheduleAssistTreatmentProps = {
  isOpen: boolean
  handleOpen: (isOpen: boolean) => void
  treatmentId: string
  refreshData: () => void
}

const ScheduleAssistTreatment = ({
  treatmentId,
  isOpen,
  handleOpen,
  refreshData,
}: ScheduleAssistTreatmentProps) => {
  const { t } = useTranslation()
  const { doRequest: updateScheduleAssistTreatment } = useRequest<
    ScheduleAppointmentsType,
    string
  >(api.scheduleAssistTreatment, {
    onSuccess: data => {
      if (data) {
        alert.success(
          `${t('Success')}!`,
          t('Schedule assist action has been performed'),
          {
            position: 'bottom-right',
          },
        )
      }
      refreshData()
    },
    onError: error => {
      if (error instanceof AxiosError) {
        const title = error?.response?.data?.title ?? `${t('Error')}!`
        const description =
          error.response?.data?.detail ?? `${t('Schedule assist error')}!`
        alert.error(title, description, {
          position: 'bottom-right',
        })
      }
    },
  })
  return (
    <Dialog
      open={isOpen && !!treatmentId}
      onConfirm={() => updateScheduleAssistTreatment(treatmentId)}
      closeFn={() => handleOpen(false)}
      title={t('Schedule Assist')}
      description={t(
        'Any existing appointments for this patient\'s treatment will be replaced with new appointments',
      )}
      variant="success"
      confirmButtonText={t('Confirm')}
      cancelButtonText={t('Cancel')}
    />
  )
}
export default ScheduleAssistTreatment
