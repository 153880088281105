import { styled } from '@weareredlight/design-system'

export const StyledAppointmentForm = styled('div', {
  width: '100%',

  form: {
    width: '100%',

    '.appointment-form-card': {
      padding: '$lg',
    }
  }
})
