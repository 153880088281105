import { alert, Modal } from '@weareredlight/design-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { ProcedureType } from 'types/procedures'
import type { TreatmentProcedureInputType } from 'types/treatments'
import type { PaginatedRequest } from 'types/types'

import { TreatmentProceduresFields } from './utils'

import api from 'api/api'
import Card from 'components/Card'
import { Form, ControlledMultiSelect, DialogFormWrapper } from 'components/Form'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'

type NewTreatmentProceduresProps = {
  isOpen: boolean
  handleOpen: (isOpen: boolean) => void
  treatmentId: string
  refreshData: () => void
}

const NewTreatmentProcedures = ({
  isOpen,
  handleOpen,
  treatmentId,
  refreshData,
}: NewTreatmentProceduresProps) => {
  const { t } = useTranslation()

  const {
    data: procedures,
    doRequest: getProcedures,
    isLoading,
  } = useRequest<PaginatedRequest<ProcedureType>>(api.getProcedures)

  //Convert procedures into options
  const proceduresOptions = useMemo(() => {
    if (!procedures?.data) return []
    return procedures?.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }, [procedures?.data])

  const form = useFormManager<TreatmentProcedureInputType>({
    defaultValues: {
      [TreatmentProceduresFields.PROCEDURE_IDS_TO_ADD]: [],
    },
    onCancel: () => {
      form.form.reset()
      handleOpen(false)
    },
    enableReinitialize: true,
    onSubmit: async input => {
      await api.updateTreatmentProcedures({ id: treatmentId, input })
      handleOpen(false)
      refreshData()
      form.form.reset()
      alert.success(`${t('Success')}!`, t('Procedures added successfully.'), {
        position: 'bottom-right',
      })
    },
  })

  useEffect(() => {
    if (isOpen) getProcedures()
  }, [getProcedures, isOpen])

  return (
    <Modal
      open={isOpen}
      title={t('New Procedures')}
      closeFn={() => {
        form.form.reset()
        handleOpen(false)
      }}
      extraClasses={DialogFormWrapper()}
    >
      <Card extraClasses="wrapper" isLoading={isLoading}>
        <Form<TreatmentProcedureInputType> {...form}>
          <ControlledMultiSelect
            name={`${TreatmentProceduresFields.PROCEDURE_IDS_TO_ADD}`}
            options={proceduresOptions}
            label={t('Available Procedures')}
            placeholder={t('Search for an existing procedure')}
            hasPills
            fullWidth
          />
        </Form>
      </Card>
    </Modal>
  )
}

export default NewTreatmentProcedures
