import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import type { ElementType } from 'react'
import type { Messages, Event } from 'react-big-calendar'
import type { AppointmentType } from 'types/appointments'
import type { ProcedureType } from 'types/procedures'
import type { AvailableViews } from 'utils/entries'

import { BigCalendarStylesOverride } from './big-calendar.styles'

import i18next from 'i18n'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const DnDCalendar = withDragAndDrop(Calendar) as ElementType
dayjs.locale(i18next.t('lang-code'))
const localizer = dayjsLocalizer(dayjs)

export type CustomEventType = Event & {
  id: string
  procedure: ProcedureType
  appointment: AppointmentType | null
}

const messages: Messages = {
  week: i18next.t('Week'),
  day: i18next.t('Day'),
  month: i18next.t('Month'),
  previous: i18next.t('Previous'),
  next: i18next.t('Next'),
  today: i18next.t('Today'),
  showMore: (count: number) => `+${count} more`,
}

const defaultBigCalendarOptions = {
  culture: i18next.t('lang-code'),
  localizer,
  resizable: true,
  selectable: true,
  popup: false,
  toolbar: true,
  showMultiDayTimes: true,
  allDay: false,
  step: 5,
  timeslots: 3,
  messages,
  formats: {
    timeGutterFormat: 'HH:mm',
  },
  style: { width: '100%', height: '100%' },
  views: [Views.MONTH, Views.WORK_WEEK, Views.DAY],
  defaultView: Views.WORK_WEEK,
}

const BigCalendar = (props: Record<string, unknown>) => (
  <BigCalendarStylesOverride>
    <DnDCalendar {...defaultBigCalendarOptions} {...props} />
  </BigCalendarStylesOverride>
)

export const getRangeFromDate = (
  date: Date,
  view: AvailableViews,
): [Date, Date] => {
  const newDate = dayjs(date)
  switch (view) {
    case 'day':
      return [newDate.startOf('day').toDate(), newDate.endOf('day').toDate()]
    case 'month':
      return [
        newDate.startOf('month').toDate(),
        newDate.endOf('month').toDate(),
      ]
    case 'work_week':
    default:
      return [newDate.startOf('week').toDate(), newDate.endOf('week').toDate()]
  }
}

export default BigCalendar
