import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { OrganizationType } from 'types/organizations'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import Table from 'components/Table'
import useTableRequestState from 'hooks/useTableRequestState'
import { GenericPath } from 'router/enums'
import {
  organizationsColumns,
  organizationsDefaultSortees,
} from 'utils/tables/organizations'

const OrganizationsList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tableData, ...rest } = useTableRequestState<OrganizationType>({
    requestFunc: api.getOrganizations,
    sortees: organizationsDefaultSortees,
  })

  return (
    <PageTemplate
      title={t('Organizations List')}
      rightActions={
        <Button
          onClick={() => navigate(GenericPath.NEW)}
          iconComponent={() => <PlusIcon />}
        >
          {t('Add Organization')}
        </Button>
      }
    >
      <Table<OrganizationType>
        data={tableData?.data || []}
        columns={organizationsColumns}
        totalCount={tableData?.count || 0}
        totalPages={tableData?.pageCount || 0}
        {...rest}
      />
    </PageTemplate>
  )
}

export default OrganizationsList
