import { alert } from '@weareredlight/design-system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { ProviderInputType, ProviderType } from 'types/providers'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import ProvidersForm from 'components/Providers/ProviderForm'
import { useFormManager } from 'hooks/useFormManager'
import { useRequest } from 'hooks/useRequest'
import { ProvidersPath, RootPath } from 'router/enums'
import { ProviderInputSchema } from 'schemas/provider'
import { Certification } from 'utils/enums'
import { getName } from 'utils/text'

const ProviderEdit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id, providerId } = useParams<{ id: string; providerId: string }>()

  const {
    data: provider,
    doRequest: getProvider,
    isLoading,
  } = useRequest<ProviderType, { id: string }>(api.getProvider)

  const goBackToUrl = React.useMemo(
    () =>
      `${RootPath.ORGANIZATIONS}/${id}/${ProvidersPath.PROVIDERS}/${providerId}`,
    [id, providerId],
  )

  const form = useFormManager<ProviderInputType>({
    defaultValues: { ...provider, organizationId: id },
    enableReinitialize: true,
    schema: ProviderInputSchema,
    onCancel: () => navigate(goBackToUrl),
    onSubmit: async (input: ProviderInputType) => {
      if (providerId) {
        if (input.certification !== Certification.DOCTOR) {
          input.specialities = []
        }
        await api.updateProvider({ id: providerId, input })
        navigate(goBackToUrl)
        alert.success(
          `${t('Success')}!`,
          `${getName(input)} ${t('was edited successfully')}.`,
          { position: 'bottom-right' },
        )
      }
    },
  })

  useEffect(() => {
    if (providerId) getProvider({ id: providerId })
  }, [providerId, getProvider])

  return (
    <PageTemplate
      title={t('Edit Provider')}
      goBackTo={goBackToUrl}
      fullWidth={false}
      numberOfElements={1}
    >
      <ProvidersForm {...form} isLoading={isLoading} />
    </PageTemplate>
  )
}

export default ProviderEdit
