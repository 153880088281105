import { MultiSelect } from '@weareredlight/design-system'
import { useFormContext, Controller } from 'react-hook-form'

import type { OptionType } from 'types/types'

type ControlledMultiSelectType = {
  name: string
  label?: string
  placeholder?: string
  options: OptionType[]
  hasPills?: boolean
  fullWidth?: boolean
}

export const ControlledMultiSelect = ({
  name,
  label,
  placeholder,
  options,
  hasPills = true,
  fullWidth = false,
}: ControlledMultiSelectType) => {
  const { control, formState } = useFormContext()

  const getLabel = (value: string) => {
    return options.find(item => item.value === value)?.label || '-'
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <MultiSelect
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            hasPills={hasPills}
            getLabel={getLabel}
            errorMsg={
              formState.errors[name] && String(formState.errors[name]?.message)
            }
            fullWidth={fullWidth}
          />
        )
      }}
    />
  )
}
