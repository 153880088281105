import { alert } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { UserInputType } from 'types/users'

import api from 'api/api'
import PageTemplate from 'components/PageTemplate'
import UserForm, { UserFields } from 'components/Users/UserForm'
import { useFormManager } from 'hooks/useFormManager'
import { RootPath } from 'router/enums'
import { UserInputSchema } from 'schemas/user'
import { getName } from 'utils/text'

const NewUser = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const form = useFormManager<UserInputType>({
    defaultValues: {
      [UserFields.ACTIVE]: true,
    },
    enableReinitialize: true,
    schema: UserInputSchema,
    onCancel: () => navigate(RootPath.USERS),
    onSubmit: async input => {
      await api.addUser(input)
      navigate(RootPath.USERS)
      alert.success(
        `${t('Success')}!`,
        `${getName(input)} ${t('was created successfully')}.`,
        { position: 'bottom-right' },
      )
    },
  })

  return (
    <PageTemplate
      title={t('Add New User')}
      goBackTo={RootPath.USERS}
      fullWidth={false}
    >
      <UserForm {...form} />
    </PageTemplate>
  )
}

export default NewUser
