import { styled } from '@weareredlight/design-system'

export const StyledNavbar = styled('nav', {
  width: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 100,
  background: '$primary',

  '>div': {
    height: '73px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '$xlg $xxlg',

    '@sm': {
      height: '65px',
      padding: '$lg',
    },
    '@xlg': {
      maxWidth: '1600px',
      margin: '0 auto'
    },

    '>img': {
      height: '28px'
    },

    ul: {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '$xxlg',

      '@md': {
        display: 'none'
      },

      'a:not(.profileLink)': {
        color: '$white',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '$xxxsm',

        '&:hover': {
          color: '$primary200',
          textDecoration: 'none'
        },
        '&:active, &.activeLink': {
          color: '$primary200',
          textDecoration: 'none'
        },
        '&:focus': {
          color: '$primary200',
          textDecoration: 'none',
          borderRadius: '$xsm',
          outline: '1px solid $primary600'
        },
      },

      '.profileLink': {
        textDecoration: 'none',
        cursor: 'pointer'
      }
    },

    label: {
      color: '$white'
    }
  }
})

