import React from 'react'

import Navbar from 'components/Navbar'

interface LayoutTypes {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutTypes) => {
  return (
    <>
      <Navbar />
      {children}
    </>
  )
}

export default Layout
